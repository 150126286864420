import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { livingAction } from "../../actions";
import { SpinnerContext } from "../../components/spinner/spinner.component";

class LaunchAdConfirmation extends Component {
  static contextType = SpinnerContext;

  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  handleClose = () => {
    this.props.onHide();
  };

  handleYes = () => {
    const { data } = this.props;
    this.setState({ showModal: false });
if(Boolean(data?.advertisementID)){
     this.props
      .updatePromoteLiving(data.livingId, {
        cardId: data.selectedCard?.id,
        budget: data.selectedBudget,
        advertisementID:data.advertisementID
      })
      .then(() => {
        this.props.onHide(true, 'Sober living promoted successfully!');
      })
      .catch((err) => {
        this.props.onHide(true, 'Sober living already promoting.');
      });
    }else{
      this.props
      .promoteLiving(data.livingId, {
        cardId: data.selectedCard?.id,
        budget: data.selectedBudget,
      })
      .then(() => {
        this.props.onHide(true, 'Sober living promoted successfully!');
      })
      .catch((err) => {
        this.props.onHide(true, 'Sober living already promoting.');
      });
    }

     
  };

  render() {
    const { data } = this.props;
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you confirm promotion of "{data.livingName}" with the monthly budget
          of ${data.selectedBudget} and card {data.selectedCard?.last4}?
        </Modal.Body>
        {/* <Modal.Body>
          Do you confirm promotion of "{data.livingName}" with the monthly budget
          of ${data.selectedBudget} and card {data.selectedCard?.last4}?
        </Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              this.handleYes();
            }}
          >
            Launch Ads
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

export default connect(
  ({ living }) => ({}),
  livingAction
)(LaunchAdConfirmation);
