import React, { Component } from "react";
import { Field } from "formik";

class CustomCheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {
      className,
      label,
      subLabel,
      mandatory,
      handleChange = false,
      onChangeHandler = () => {
        // console.log("checkbox handler");
      },
      id,
      value = false,
      name = "",
    } = this.props;

    return (
      <div className={className}>
        <div>
          <label>
            {label}
            {mandatory && <span className="mandatory">*</span>}
          </label>
          {subLabel && <p className="checkbox-sub-label">{subLabel}</p>}
        </div>
        <div className="custom-control custom-checkbox">
          <Field
            name={name}
            checked={value}
            type="checkbox"
            className="custom-control-input"
            id={id}
            {...(handleChange ? { onChange: onChangeHandler } : {})}
            // onChange={onChangeHandler}
          />
          <label className="custom-control-label" htmlFor={id}></label>
        </div>
      </div>
    );
  }
}

export default CustomCheckBox;
