import firebase from "firebase";

const config = {
  /* apiKey: "AIzaSyALEDp2cgjBVoxkxJslwNp0y8SN3lxIeZ4",
  authDomain: "taste-recovery-27b88.firebaseapp.com",
  databaseURL: "https://taste-recovery-27b88.firebaseio.com",
  projectId: "taste-recovery-27b88",
  storageBucket: "taste-recovery-27b88.appspot.com",
  messagingSenderId: "894164021733",
  appId: "1:894164021733:web:348b7b10894c81905cff0c",
  measurementId: "G-0B5P7WRD3J", */
  apiKey: "AIzaSyDaLGb4CHQL0IiJCxUab8dh2VSnq2HNfiY",
  authDomain: "taste-recovery-cc8e7.firebaseapp.com",
  databaseURL: "https://taste-recovery-cc8e7.firebaseio.com",
  projectId: "taste-recovery-cc8e7",
  storageBucket: "taste-recovery-cc8e7.appspot.com",
  messagingSenderId: "727961701538",
  appId: "1:727961701538:web:b0b8f27c9a34a5e7dc5bfb",
};

firebase.initializeApp(config);
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if (messaging) {
      messaging
        .getToken({
          vapidKey:
            // "BJI1V2azPy9eseVKWSMIfHs2Na4-YDFgSoWnNUKc4g0_1Q8B_yaKt6na7QNHoEYH-ICDDTsULSC_jTr1xKiSd1w",
            "BCDT3TMEjDzACIEEJCIU5EnfqvTsaURvDQCLW_-P-uKKy-eYEDS2C5-wCNIoZv0FA-me8Zplo9SfmkG6ApTJH8c",
        })
        .then((currentToken) => {
          if (currentToken) {
            resolve(currentToken);
          } else {
            // console.log(
            //   "No registration token available. Request permission to generate one."
            // );
          }
        })
        .catch((err) => {
         
          reject(err);
        });
    } else {
      reject("not supported browser");
    }
  });

const data = {
  "alert/newSoberLiving": {
    title: "Alert - New sober living",
    body: "",
    link: (data) => `living/${data.soberLivingId}`,
  },
  "alert/updateSoberLiving": {
    title: "Alert - Sober living price changed",
    link: (data) => `living/${data.soberLivingId}`,
  },
  "chat/newMessage": {
    title: (userName) => `New message from ${userName}`,
    body: "content",
    link: (data) => `chats/${data.dialogueId}`,
  },
  "tour/leaveReview": {
    title: "Tour - Please leave a review",
    link: (data) => `living/${data.soberLivingId}`,
  },
  "ad/failedCharge": {
    title: "Advertisement - Failed to charge",
    link: (data) => `settings/transactions`,
  },
};

if (messaging)
  messaging.onMessage((payload) => {

    if (payload.data) {
      const { event } = payload.data;
      const eventData = data[event];
      if (!eventData) {
        return;
      }
      const notificationTitle =
        typeof eventData.title === "function"
          ? eventData.title(payload.data.senderName)
          : eventData.title;
      const notificationOptions = {
        body:
          payload.data["body"] || payload.data.hasOwnProperty(eventData.body)
            ? payload.data[eventData.body]
            : "",
        icon: "/favicon.ico",
        tag: payload.data["tag"],
        data: eventData.link ? `${eventData.link(payload.data)}` : "",
      };
      if (!("Notification" in window)) {
       // console.log("This browser does not support system notifications");
      }
      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(
          notificationTitle,
          notificationOptions
        );
        notification.onclick = (event) => {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          if (notificationOptions.data !== "") {
            window.location.href = `${process.env.REACT_APP_BASE_URL}/${notificationOptions.data}`; //relative to domain
          }
          notification.close();
        };
      }
    }
  });

export const swDev = () => {
  function determineAppServerKey() {
    const vapidPublicKey =
      "BJI1V2azPy9eseVKWSMIfHs2Na4-YDFgSoWnNUKc4g0_1Q8B_yaKt6na7QNHoEYH-ICDDTsULSC_jTr1xKiSd1w";
    return urlBase64ToUint8Array(vapidPublicKey);
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  let swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
  navigator.serviceWorker.register(swUrl).then((response) => {
    if (response && response.pushManager)
      return response.pushManager.getSubscription().then(function (subscription) {
        response.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: determineAppServerKey(),
        });
      });
  });
};
