import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
export const getGeoCode = (address) => {
  return new Promise((resolve, reject) => {
    geocodeByAddress(address).then(
      (response) => {
        getLatLng(response[0]).then((latLang) => resolve(latLang));
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const getGeoLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        resolve(position);
      },
      function (error) {
        reject(error);
      }
    );
  });
};

export const checkHasNavigationPermission = () => {
  return new Promise((resolve, reject) => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          resolve(true);
        } else if (result.state === "prompt") {
          resolve(false);
        }else if (result.state === "denied") {
          localStorage.removeItem("coordinates");
          resolve(false);
        }  else {
          reject();
        }
      });
  });
};

export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};


export const getCoordinates = () => {
  const storedItem = localStorage.getItem('coordinates');
  if (storedItem) {
    return JSON.parse(storedItem);
  }
  return null; // Add this line to handle the case when there is no stored item
}
