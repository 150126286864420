import React, { useLayoutEffect, useState, useEffect } from "react";

import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../modules/home/home.page";
import LogoutPage from "../modules/auth/logout";
import * as routerHelpers from "./router-helpers";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import ReactGA from "react-ga";
import Living from "../modules/living/living";
import HeaderComponent from "../components/header/header.component";
import ScheduledTour from "../modules/tours/scheduled-tour";
import FooterComponent from "../components/footer/footer.component";
import Profile from "../modules/home/profile.page";
import Settings from "./../modules/settings/settings";
import Chat from "./../modules/home/chat.page";
import AboutUs from "../modules/common/about-us";
import TermsOfService from "../modules/common/terms-of-service";
import TermOfServicesIOs from "../modules/common/terms-of-service-ios";

import FAQ from "../modules/common/faq";
import PrivacyPolicy from "../modules/common/privacy-policy";
import PaymentOptions from "../modules/payment/payment-options.page";
import ContactUs from "../modules/common/contact-us";
import PlansPricing from "./../modules/home/plansPricing.page";
import Residential from "../modules/residencial/residential";
import Detox from "../modules/detox/detox";
import Iophouse from "../modules/IopCenter/Iophouse";
import php from "../modules/php/php";
import { ManageChat } from "../modules/home/managechat/managechat";
import viewDetailsFacilityCenter from "../modules/home/facilityCenters/viewDetailsFacilityCenter";
import ClaimFacilityView from "../modules/home/claimFacilityView";
import Helpandsupport from "../modules/common/Helpandsupport";

import HelpandsupportIos from "../modules/common/HelpandsupportIos";
import HelpandsupportPlan from "../modules/common/HelpandsupportPlan";

import dashboardIndex from "../modules/dashboard/dashboardIndex";
import DetoxFacility from "../modules/home/detox.page";
import ResidentialFacility from "../modules/home/residential.page";
import IopFacility from "../modules/home/iop.page";
import PhpFacility from "../modules/home/phpFacility.page";
import FacilityHome from "../modules/home/facilityCenters/facilityHome.page";

export const Routes = withRouter(({ history }) => {
  const [isTrue, setisTrue] = useState(false);
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, userLastLocation } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken != null && auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  const location = useLocation();

  useLayoutEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const SecuredRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        // ternary condition

        isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect to="/?isSignIn=true" />
        )
      }
    />
  );

  if (history.location.pathname === "/residential") {
    history.replace("/");
  }
  useEffect(() => {
    if (location.pathname != "/" && location.pathname.endsWith("/")) {
      history.replace(location.pathname.slice(0, -1));
    }
  }, [location, history]);

  return (
    <div>
      <div className="main-wrapper">
        <HeaderComponent />
        <Switch>
          <Route path="/soberliving" exact={true} component={HomePage} />
          <Route
            path="/home/centers/view/:id"
            component={viewDetailsFacilityCenter}
          />
          <SecuredRoute path="/dashboard" component={dashboardIndex} />
          <Route
            path={["/residential", "/"]}
            exact
            component={ResidentialFacility}
          />

          <Route path="/detox" component={DetoxFacility} />
          <Route path="/iop" component={IopFacility} />
          <Route path="/php" component={PhpFacility} />
          <Route path="/living" component={Living} />
          <Route path="/detoxfacility" component={Detox} />
          <Route path="/residentialfacility" component={Residential} />
          <Route path="/phpfacility" component={php} />
          <Route path="/iopfacility" component={Iophouse} />
          <SecuredRoute path="/settings" component={Settings} />
          <SecuredRoute path="/scheduled-tours" component={ScheduledTour} />
          <SecuredRoute path="/profile" component={Profile} />
          <SecuredRoute path="/claimfacility" component={ClaimFacilityView} />
          <SecuredRoute path="/chats/:chatId?" component={Chat} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/help-and-support" component={Helpandsupport} />
          <Route path="/help-and-support-ios" component={HelpandsupportIos} />
          <Route
            path="/help-and-support-ios-plan"
            component={HelpandsupportPlan}
          />
          <Route path="/living" component={Living} />
          <Route path="/contact-support" component={ContactUs} />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/terms-of-service-ios" component={TermOfServicesIOs} />

          <Route path="/faq" component={FAQ} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <SecuredRoute path="/plansPricing" component={PlansPricing} />
          <SecuredRoute path="/payment-options" component={PaymentOptions} />
          <SecuredRoute
            path="/manageSurvey"
            component={ManageChat}
          ></SecuredRoute>
          {/* <Redirect to="/residential" /> */}
          <Redirect exact from="/residential" to="/" />

          <Redirect to="/" />
        </Switch>
      </div>

      <FooterComponent />
    </div>
  );
});
