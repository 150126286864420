import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { livingAction } from "../../actions";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import moment from "moment";
import Image from "react-bootstrap/Image";
import { getCityName } from "../../utils";
import { seoUrlText } from "../../constants";
import Rater from "react-rater";
import { capitalizeWords } from "../../constants/utils";
import NoImage from "../../assets/images/no-image.svg";
const MyReviews = () => {
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const dispatch = useDispatch();
  const myReviews = useSelector((state) => state.living.myReviews);
  const isFetchingMyReviews = useSelector(
    (state) => state.living.fetchingMyReviews
  );

  useEffect(() => {
    dispatch(livingAction.fetchMyReviews()).finally(() => {
      hideLoader();
    });
    if (!isFetchingMyReviews && myReviews.length === 0) {
      showLoader("Fetching Reviews...");
      dispatch(livingAction.fetchMyReviews()).finally(() => {
        hideLoader();
      });
    }
  }, []);

  const onReviewClick = (review) => {
    if (review?.propertyType === "Facility") {
      window.location.href = `/home/centers/view/${review?.property}`;
    } else {
      let city = getCityName(review?.soberLivingLocation);
      if (!review.property) {
        return;
      }
      window.location.href = `/living/${seoUrlText}-${city}&id=${review.property}`;
    }
  };

  const ReviewBoxUI = ({ item }) => {
    return (
      <div
        className="review-block d-flex cursor-pointer"
        onClick={() => onReviewClick(item)}
      >
        <div className="review-image">
          {item.soberLivingImage ? (
            <Image
              src={item.soberLivingImage ? item.soberLivingImage : NoImage}
              fluid
            />
          ) : (
            <>
              <Image src={NoImage} fluid />
              {/* <span>{item.soberLivingName}</span> */}
            </>
          )}
        </div>
        <div className="review-detail flex-fill">
          <div className="top-info d-flex justify-content-between">
            <div className="reviewer-name-date">
              <h4 className="mb-1">
                Review '{capitalizeWords(item.soberLivingName)}'
              </h4>
              <p> {moment(item.createdAt).format("MMMM, YYYY")}</p>
            </div>
            <div className="star-rating d-flex align-items-start">
              <Rater rating={item?.value} interactive={false} />
            </div>
          </div>
          <div className="bottom-info">
            {!item.touredAt ? (
              <p>
                {item.livedFrom
                  ? `${
                      item.propertyType === "SoberLiving"
                        ? "Lived in this sober living"
                        : "Treated in this facility"
                    } from ${moment(item.livedFrom).format(
                      "DD MMM, YYYY"
                    )} to ${moment(item.livedTo).format("DD MMM, YYYY")}`
                  : ""}
              </p>
            ) : (
              <p>
                Toured in this sober living at{" "}
                {moment(item.touredAt).format("DD MMM, YYYY")}
              </p>
            )}
            <span>{item.comment}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="savehome-wrp setting-list recommended active" id="two">
      <h3 className="main-title">My Reviews</h3>
      <div className="saved-home-wrappper mt-4">
        {myReviews.length > 0 ? (
          myReviews
            .slice()
            .map((review, index) => <ReviewBoxUI item={review} key={index} />)
        ) : (
          <div className="no-records">
            <h2 className="text-uppercase">
              THERE ARE NO REVIEWS AT THIS TIME
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyReviews;
