import React, { useState, useContext, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { USER_ROLE } from "../../constants";
import { connect, useDispatch } from "react-redux";
import * as auth from "../../actions";
import { mapErrorToField } from "../../utils";
import arrow from "../../assets/images/arrow_right-24px.svg";
import { Button, Toast } from "react-bootstrap";
import TermsOfService from "../common/terms-of-service";
import ModalComponent from "../../components/modal/modal.component";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { countryList } from "./countryList";
import Select from "react-select";
const Registration = (props) => {
  const dispatch = useDispatch();
  const spinnerContext = useContext(SpinnerContext);
  const [handlePartOne, setHandlePartOne] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [role, setRole] = useState(undefined);
  const [isNext, setIsNext] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showFormData, setShowFormData] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [soId, setSoId] = useState([]);
  const [phone, setPhone] = useState("");
  const [maskedPhone, setMaskedPhone] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const filteredOptions = countryList.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  let SoIdOption = countryList?.map((cl) => {
    return {
      value: cl,
      label: cl,
    };
  });

  const handleToggleDropdown = () => {
    setIsOpen(true);
  };
  const handleOptionClick = (option) => {
    setSearchTerm(option);
    setIsOpen(false);
  };

  const handleSoSelectChange = (newSelection) => {
    setSoId(newSelection);

    setSearchTerm(newSelection?.value);
    setIsOpen(false);
  };

  const handleOnChange = (val) => {
    // setIsOpen(false);
    setSearchTerm(val.target.value);
  };

  const requiredFields = ["name", "email", "password"];
  const newRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownRef]);
  const handleInputChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    const formattedValue = formatPhoneNumber(input);
    setMaskedPhone(formattedValue);
    setPhone(numericValue);
  };

  const formatPhoneNumber = (input) => {
    const phoneNumber = input.replace(/\D/g, ""); // Remove non-numeric characters

    // If the input is empty, return an empty string
    if (!phoneNumber) return "";

    let formattedPhoneNumber = `(${phoneNumber.slice(0, 3)})`;

    // Check if the input length decreased after pressing backspace
    if (input.length < formattedPhoneNumber.length) {
      // Remove the last character if backspace was pressed
      formattedPhoneNumber = formattedPhoneNumber.slice(0, -1);
      return formattedPhoneNumber;
    }

    if (phoneNumber.length > 3) {
      formattedPhoneNumber += `-${phoneNumber.slice(3, 6)}`;
    }
    if (phoneNumber.length > 6) {
      formattedPhoneNumber += `-${phoneNumber.slice(6, 10)}`;
    }
    if (phoneNumber.length > 10) {
      formattedPhoneNumber += `-${phoneNumber.slice(10, 15)}`;
    }

    return formattedPhoneNumber;
  };

  const handleOutsideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      const shouldClosed = window.confirm(
        "All changes would be unsaved if you leave"
      );
      if (shouldClosed) {
        props.onHide(false);
      }
    }
  };

  const signUpSchemaHost = Yup.object().shape({
    name: Yup.string().required("Username is required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email"
      )
      .required("Email is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one special character, and one number"
      )
      .required("Password is required"),

    firstName: Yup.string()
      .required("First name is required")
      .max(50, "First name must be less than or equal to 50 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .max(50, "First name must be less than or equal to 50 characters"),
  });

  const signUpSchemaUser = Yup.object().shape({
    name: Yup.string().required("Username is required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email"
      )
      .required("Email is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one special character, and one number"
      )
      .required("Password is required"),

    firstName: Yup.string()
      .required("First name is required")
      .max(50, "First name must be less than or equal to 50 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .max(50, "First name must be less than or equal to 50 characters"),
  });

  const showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }) => {
    return (
      <div>
        <div>
          <div className="signup-choose-wrapper">
            <div className="modal-header">
              <h5 className="modal-title">Sign Up</h5>
              {role && <p>Create your account</p>}
            </div>
            <div className="modal-body p-0">
              <Toast
                onClose={() => setShowError(false)}
                show={showError}
                delay={3000}
                autohide
                className="list-group-item-warning"
              >
                <Toast.Body>
                  <ul>
                    {props.error &&
                      props.error.data.map(function (item) {
                        return <li key={item}>{item}</li>;
                      })}
                  </ul>
                </Toast.Body>
              </Toast>
              <Form onSubmit={handleSubmit}>
                {!role ? (
                  <div>
                    <div className="form-group mb-0">
                      <label
                        className="form-control choose-label"
                        onClick={() => setRole(USER_ROLE.REGULAR)}
                      >
                        Looking for a facility
                        <img src={arrow} alt="" />
                      </label>
                    </div>
                    <div className="form-group mb-0">
                      <label
                        className="form-control choose-label"
                        onClick={() => setRole(USER_ROLE.HOST)}
                      >
                        List a facility
                        <img src={arrow} alt="" />{" "}
                      </label>
                    </div>
                  </div>
                ) : role && !isNext ? (
                  <div>
                    <div className="form-group">
                      <Field
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        maxLength={50}
                        placeholder="Name*"
                        className="form-control mt-0 mb-2"
                      />
                      {touched?.name && errors?.name && (
                        <ErrorMessage
                          component="div"
                          className="error-message mb-2"
                          name="name"
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <Field
                        type="text"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        placeholder="Email*"
                        className="form-control mt-0 mb-2"
                      />
                      {touched?.email && errors?.email && (
                        <ErrorMessage
                          component="div"
                          className="error-message mb-2"
                          name="email"
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <Field
                        type="password"
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        placeholder="Password*"
                        className="form-control mt-0 mb-2"
                      />
                      {touched?.password && errors?.password && (
                        <ErrorMessage
                          component="div"
                          className="error-message mb-2"
                          name="password"
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <Select
                        value={soId}
                        onChange={handleSoSelectChange}
                        isMulti={false}
                        isSearchable={true}
                        placeholder="Country"
                        options={SoIdOption}
                        className="basic-multi-select form-control"
                        classNamePrefix="select"
                      />{" "}
                      {((!searchTerm && touched?.country && errors?.country) ||
                        (!searchTerm && isTrue && !touched.country)) && (
                        <p style={{ color: "red" }}>Country name is required</p>
                      )}
                    </div>

                    <div>
                      <div className="action-btn">
                        <button
                          onClick={() => {
                            setIsTrue(true);
                            setHandlePartOne(false);
                            const fieldErrorsObj = {};

                            requiredFields.forEach((field) => {
                              if (!values[field]) {
                                fieldErrorsObj[
                                  field
                                ] = `Please enter ${field}.`;
                              } else {
                                fieldErrorsObj[field] = null;
                              }
                            });
                            setFieldErrors(fieldErrorsObj);
                            if (
                              searchTerm &&
                              Object.keys(errors).length <= 3 &&
                              Object.values(fieldErrorsObj).every(
                                (error) => !error
                              )
                            ) {
                              setIsNext(true);
                            } else {
                            }
                          }}
                          className="btn btn-primary radius-20 btn-min-width"
                          size="lg"
                          block
                        >
                          CONTINUE
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div ref={newRef}>
                    <div className="form-group">
                      <Field
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        value={values.firstName}
                        placeholder="First Name*"
                        className="form-control"
                        maxLength={50}
                      />
                      {touched?.firstName &&
                        errors?.firstName &&
                        handlePartOne && (
                          <ErrorMessage
                            component="div"
                            className="error-message"
                            name="firstName"
                          />
                        )}
                      {values?.firstName.length > 50 && !errors?.firstName && (
                        <p className="error-message">
                          First name must be less than or equal to 50 character
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <Field
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        value={values.lastName}
                        placeholder="Last Name*"
                        className="form-control"
                        maxLength={50}
                      />
                      {values?.lastName?.length > 50 && !errors?.lastName && (
                        <p className="error-message">
                          Last name must be less than or equal to 50 character
                        </p>
                      )}
                      {touched?.lastName &&
                        errors?.lastName &&
                        handlePartOne && (
                          <ErrorMessage
                            component="div"
                            className="error-message"
                            name="lastName"
                          />
                        )}
                    </div>

                    {role !== "regular" && (
                      <div className="form-group">
                        <Field
                          type="text"
                          name="companyName"
                          onChange={handleChange}
                          value={values.companyName}
                          placeholder="Company Name"
                          className="form-control"
                          maxLength={50}
                          disabled={role === "regular"}
                        />
                      </div>
                    )}

                    {role !== "regular" && (
                      <div className="form-group">
                        <Field
                          type="text"
                          name="phone"
                          onChange={handleInputChange}
                          value={maskedPhone}
                          placeholder="Phone*"
                          className="form-control"
                          disabled={role === "regular"}
                          maxLength={20}
                        />
                        {!phone && formSubmitted && handlePartOne && (
                          <div className="error-message">
                            Phone number is required
                          </div>
                        )}
                      </div>
                    )}
                    <div className="terms">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          onChange={(e) => setAcceptedTerms(e.target.checked)}
                          required
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          By confirming you agree to our{" "}
                          <a href="#" onClick={() => setShowTerms(true)}>
                            Terms and Conditions
                          </a>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="action-btn my-4 py-2">
                        <Button
                          onClick={() => setHandlePartOne(true)}
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-primary"
                          size="lg"
                          block
                        >
                          Sign Up
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
          <div>
            <ModalComponent
              newRef={newRef}
              className="terms-of-condition"
              component={TermsOfService}
              show={showTerms}
              onHide={() => setShowTerms(false)}
              closeButton
            ></ModalComponent>
          </div>
        </div>
      </div>
    );
  };

  const onRegister = (values, { setErrors, setSubmitting }) => {
    if (!acceptedTerms) {
      setErrors();
      setSubmitting(false);
      return;
    }
    if (values.phone === "") {
      setFormSubmitted(true);
    }
    values.role = role;
    values.country = searchTerm;
    values.termsAndCondition = acceptedTerms;
    values.phone = phone;
    spinnerContext.showLoader("Signing Up");
    props
      .register(values)
      .then(() => {
        setTimeout(props.registerDevice, 10);
        if (values.role === USER_ROLE.HOST) props.onSignUp();
      })
      .catch(() => {
        setShowError(true);
        setErrors(mapErrorToField(props.error));
        setSubmitting(false);
      })
      .finally(() => {
        spinnerContext.hideLoader();
      });
  };

  return (
    showFormData && (
      <div>
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
            confirm_password: "",
            firstName: "",
            lastName: "",
            companyName: "",
            phone: "",
            general: "",
          }}
          onSubmit={onRegister}
          validationSchema={
            role == "host" ? signUpSchemaHost : signUpSchemaUser
          }
        >
          {(formikProps) => showForm(formikProps)}
        </Formik>
        <div className="extra-link">
          <span>Already have an account?</span>
          <a role="button" onClick={props.onSignIn}>
            <span> &nbsp;Sign In</span>
          </a>
        </div>
      </div>
    )
  );
};

export default connect(
  ({ auth }) => ({ error: auth.error }),
  auth.authAction
)(Registration);
