import { preSaleActionTypes } from "../actions/presales.action";


const initalPaymentState = {
  fetchingPreSalePlan: true,
  preSalePlan:[],
  priceId:null,
  priceAmount:null,
  facilityInfo:{}
};

export const preSaleReducer = (state = initalPaymentState, action) => {
  switch (action.type) {

      case preSaleActionTypes.PreSalesPlan_Fetching:
        return { ...state, fetchingPreSalePlan: true };
      case preSaleActionTypes.PreSalesPlan_Fetched:
        return { ...state, fetchingPreSalePlan: false,preSalePlan: action.payload };
      case preSaleActionTypes.PreSaleBasicPlan_Set:
        return {...state, priceId:action.payload?.id, priceAmount:action?.payload?.price}
      case preSaleActionTypes.PreSaleProPlan_Set:
        return {...state, priceId:action.payload?.id,priceAmount:action?.payload?.price }
      case preSaleActionTypes.PreSaleFacilityType_Set:
          return {...state, facilityInfo:{facilitytype:action?.payload?.facilitytype, id:action?.payload?.id, name:action?.payload?.name}}
    default:
      return state;
  }
};