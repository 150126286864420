import React, { useState } from "react";
import { formatCamelCaseToReadabcodtiontreated } from "../../constants/utils";

const itemsPerPage = 15; // Number of items to show per page

const Viewshowmorebutton = ({ GetData, iocn, name }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showAll, setShowAll] = useState(false);
  const totalItems = Object.values(GetData).filter((value) => value).length;
  const currentItems = Object.entries(GetData)
    .filter(([_, value]) => value)
    .slice(0, showAll ? totalItems : currentPage * itemsPerPage);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setCurrentPage(1);
  };

  return (
    <>
    {Object.values(GetData).some(
      (value) => value === true
    )&&<div className="facility-content">
      <h3 className="d-flex align-items-center">
        <img src={iocn} className="img-fluid" alt="" />
        {name}
      </h3>
      <ul className="d-flex align-items-start flex-wrap">
        {currentItems.map(([key, _]) => {
          const formattedKey = formatCamelCaseToReadabcodtiontreated(key);
          return <li key={key}>{formattedKey}</li>;
        })}
        {totalItems > itemsPerPage && (
          <li className="more-button-list">
            <button className="more-button" onClick={toggleShowAll}>
              {showAll
                ? "Show Less"
                : totalItems - currentPage * itemsPerPage > 0
                ? `+ ${totalItems - currentPage * itemsPerPage} More`
                : "Show Less"}
            </button>
          </li>
        )}
      </ul>
    </div>}
    </>
  );
};

export default Viewshowmorebutton;
