import React, { useState } from "react";
import { useEffect } from "react";
import { surveyquestionActions } from "../../actions/participate.survey";
import { useDispatch, useSelector } from "react-redux";
import cancleImg from "../../assets/images/cancle.svg";
import backOrangeIcon from "../../assets/images/back-orange-arrow.svg";
import ThankspaticiaptingModal from "./ThankspaticiaptingModal";
const ParticipatesurevetModal = ({
  open,
  onCancel,
  setShowModal,
  id,
  phoneNumber,
  webUrl,
}) => {
  const dispatch = useDispatch();
  const surveyQuestiones = useSelector(
    (state) => state?.survey?.surveyQuestiones
  );
  const [loading ,setLoading] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [usertextAnswear ,setusertextAnswear]= useState("")
  const [error, setError] = useState("");
  const [participatequestionmodalOpen, setparticipatequestionmodalOpen] =
    useState(false);
  const [
    openthanksparticiptentmessagemodal,
    setopenthanksparticiptentmessagemodal,
  ] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    if (id) {
      fetchSurveyData();
    }
 
    return () => {};
  }, [id]);
  
  const fetchSurveyData = async () => {
    try {
      await dispatch(surveyquestionActions.fetchsurveyquestion(id));
    } catch (error) {
    }
  };

 

  const handleAnswerSelection = (answer) => {
  
    setSelectedAnswer(answer);
    setError("");

  };
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0 ) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      // Retrieve selected answer from answers state
      const currentQuestion = surveyQuestiones[currentQuestionIndex - 1];
      const prevAnswer = answers[currentQuestion.question]?.answer || '';
      setSelectedAnswer(prevAnswer);
      // Reset error state
      setError('');
    }
  }
  
  const handleSubmitallQuestion = async () => {
    if ((!selectedAnswer || selectedAnswer.trim() === "") && usertextAnswear.trim() === "") {
      const currentQuestion = surveyQuestiones[currentQuestionIndex];
      if(currentQuestion?.answerType === "text"){
      setError('Please enter an answer before proceeding');
      }else{
        setError('Please select an answer before proceeding');
      }
      return; 
    }

      
    setusertextAnswear("")
    // Update answers for the current question
    const currentQuestion = surveyQuestiones[currentQuestionIndex];
    let answerType 
    switch (currentQuestion.answerType) {
      case "multipleChoice":
        answerType = selectedAnswer;
        break;
      case "text":
        answerType = usertextAnswear;
        break;
      case "yesOrNo":
        answerType = usertextAnswear === "Yes" ? "Yes" : "No";
        break;
      default:
        answerType = ""; 
        break;
    }

    const updatedAnswer = {
      ...answers,
      [currentQuestion.question]: {
        question: currentQuestion.question,
        answer: answerType,
      },
    };
  
    setAnswers(updatedAnswer);
   setSelectedAnswer("");
    setError('');
    if (currentQuestionIndex < surveyQuestiones.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      const payload = {
        facilityId: id,
        questions: Object.values(updatedAnswer),
      };
   
      try {
        setLoading(true)
        await dispatch(surveyquestionActions.submitSurveyResponse(payload));
        setLoading(false)
        setAnswers({});
        setCurrentQuestionIndex(0);
        setparticipatequestionmodalOpen(false);
        setShowModal(false);
        setopenthanksparticiptentmessagemodal(true);
      } catch (error) {
        console.error("Error submitting survey responses:", error);
        setLoading(false)
        // Handle error
      }
    }
  };

  return (
    <>
      {open && (
        <div className="modal upgrade-plan cancel-subscription-modal prequalification-modal fade show">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="survey-modal_body">
                <div className="participate-survey">
                  <p>
                    This facility has prequalification questions to better
                    understand your needs.
                    <br /> To get started, please agree to the survey.
                  </p>
                  <div className="survey-action d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary-outline justify-content-center radius-20"
                      onClick={onCancel}
                    >
                      Deny
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary justify-content-center radius-20"
                      onClick={() => setparticipatequestionmodalOpen(true)}
                    >
                      I agree
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* --------------------------------------------------------------------------------------------------------------- */}
      {participatequestionmodalOpen && (
        <div
          className="modal upgrade-plan cancel-subscription-modal prequalification-modal fade show"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="survey-modal_body">
                <div className="participate-question">
                  <a
                    className="cancel-btn d-none d-md-flex align-items-center"
                    role="button"
                  >
                    <img
                      src={cancleImg}
                      className="img-fluid"
                      alt=""
                      onClick={() => {
                        setparticipatequestionmodalOpen(false);
                        setShowModal(false);
                        setCurrentQuestionIndex(0);
                        setError("")
                        setusertextAnswear("")
                        setSelectedAnswer("")
                      }}
                    />
                  </a>
                  {currentQuestionIndex > 0 && (
                    <a
                      href="#"
                      className="back-btn d-flex align-items-center"
                      onClick={handlePreviousQuestion}
                    >
                      <img src={backOrangeIcon} className="img-fluid" alt="" />{" "}
                      <span>Back</span>
                    </a>
                  )}
                  <div className="question-block">
                    <h2>{surveyQuestiones[currentQuestionIndex]?.question}</h2>
                    {surveyQuestiones[currentQuestionIndex]?.answerType ===
                    "multipleChoice" ? (
                      <form className="add-sobar-living">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              {surveyQuestiones[
                                currentQuestionIndex
                              ]?.options?.map((option, index) => (
                                <div
                                  key={index}
                                  className="dflex-form flex-column justify-content-start border-0 pb-0"
                                >
                                  <div className="custom-control custom-radio w-100">
                                    <div className="custom-control custom-radio w-100">
                                      <input
                                        type="radio"
                                        id={`option_${index}`}
                                        value={option}
                                        name="questionType"
                                        className="custom-control-input"
                                        checked={selectedAnswer === option}
                                        onChange={() =>
                                          handleAnswerSelection(option)
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`option_${index}`}
                                      >
                                        <span className="option d-flex mb-0 w-100">
                                          <label className="d-flex align-items-center justify-content-center m-0">
                                            {String.fromCharCode(65 + index)}
                                          </label>
                                          <span className="d-flex align-items-center flex-fill">
                                            {option}
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {error && (
                            <div
                              className="text-danger"
                              style={{ margin: "10px 19px" }}
                            >
                              {error}
                            </div>
                          )}
                          <div className="col-md-12">
                            <div className="question-btns d-flex align-items-center">
                              <button
                                type="button"
                                className="btn btn-primary-outline d-block d-md-none radius-20"
                                onClick={()=>{setparticipatequestionmodalOpen(false);  setShowModal(false);}}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary radius-20 justify-content-center"
                                onClick={() =>
                                  handleSubmitallQuestion(currentQuestionIndex)
                                }
                                disabled={loading}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) :                       surveyQuestiones[currentQuestionIndex]?.answerType ===
                    "yesOrNo" ?(
                        (
                        <form className="add-sobar-living">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group mb-0">
                                <div className="dflex-form flex-column justify-content-start border-0 pb-0">
                                  <div className="custom-control custom-radio w-100">
                                    <input
                                      type="radio"
                                      id="yes"
                                      value="Yes"
                                      name="questionType"
                                      className="custom-control-input"
                                      checked={selectedAnswer === "Yes"}
                                      onChange={() =>
                                        handleAnswerSelection("Yes")
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="yes"
                                    >
                                      <span className="option d-flex mb-0 w-100">
                                        <label className="d-flex align-items-center justify-content-center m-0">
                                          A
                                        </label>
                                        <span className="d-flex align-items-center flex-fill">
                                          Yes
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio w-100">
                                    <input
                                      type="radio"
                                      id="no"
                                      value="No"
                                      name="questionType"
                                      className="custom-control-input"
                                      checked={selectedAnswer === "No"}
                                      onChange={() =>
                                        handleAnswerSelection("No")
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="no"
                                    >
                                      <span className="option d-flex mb-0 w-100">
                                        <label className="d-flex align-items-center justify-content-center m-0">
                                          B
                                        </label>
                                        <span className="d-flex align-items-center flex-fill">
                                          No
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {error && (
                              <div
                                className="text-danger"
                                style={{ margin: "10px 19px" }}
                              >
                                {error}
                              </div>
                            )}
                            <div className="col-md-12">
                              <div className="question-btns d-flex align-items-center">
                                <button
                                  type="button"
                                  className="btn btn-primary-outline d-block d-md-none radius-20"
                                  onClick={()=>{setparticipatequestionmodalOpen(false);  setShowModal(false);}}
                                >
                                  Cancel
                                </button>
                                <button
                                disabled={loading}
                                  type="button"
                                  className="btn btn-primary radius-20 justify-content-center"
                                  onClick={() =>
                                    handleSubmitallQuestion(
                                      currentQuestionIndex
                                    )
                                  }
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      )
                    ): surveyQuestiones[currentQuestionIndex]?.answerType === "text" ? (
                      <form className="add-sobar-living">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter an answer"
                                maxlength={100}
                                value={usertextAnswear}
                                onChange={(e) => {
                                  setusertextAnswear(e.target.value);
                                  if (e.target.value.length > 100) {
                                    setError('Please enter an answer with at most 100 characters');
                                  } else {
                                    setError(null); // Clear error if length is within limit
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {error && <div className="text-danger" style={{ margin: "10px 19px" }}>{error}</div>}
                          <div className="col-md-12">
                            <div className="question-btns d-flex align-items-center">
                              <button type="button" className="btn btn-primary-outline d-block d-md-none radius-20"    onClick={()=>{setparticipatequestionmodalOpen(false);  setShowModal(false);}}>
                                Cancel
                              </button>
                              <button type="button" className="btn btn-primary radius-20 justify-content-center" disabled={loading} onClick={() => handleSubmitallQuestion(currentQuestionIndex)}>
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : "" }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ThankspaticiaptingModal
        open={openthanksparticiptentmessagemodal}
        onCancel={() => setopenthanksparticiptentmessagemodal(false)}
        phoneNumber={phoneNumber}
        webUrl={webUrl}
      />
    </>
  );
};

export default ParticipatesurevetModal;
