import React, { Component } from "react";
import Moment from "react-moment";
import Rater from "react-rater";
import ReviewReply from "./review-reply";
import { Field, Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import ReviewReplyForm from "../../components/review/review-reply-form";
class ReviewDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyForm: false,
    };
  }


  render() {
    const name = this.props.review["customName"]
      ? this.props.review["customName"]?.trim()
      : this.props.review["isAnonymous"]
      ? "Anonymous"
      : this.props.review.user.name?.trim();
    const isPro=this.props.isPro
    return (
      <>
      { (<div>
        <div className="review-wrapper">
          <div className="user-img avatar-img">
            {/* <img src={require("../../assets/images/user.jpg")} alt=""/>*/}
            <div className="name-avtar">
              <span> {name?.substr(0, 1)?.toUpperCase()}</span>
            </div>
          </div>
          <div className="review-content">
            <div className="review-heading">
              <h4>{name?.split(" ")[0]}</h4>
              <div className="rating">
                <Rater
                  total={5}
                  rating={this.props.review.value}
                  interactive={false}
                />
              </div>
            </div>
            <span className="date">
              <Moment
                date={this.props.review["createdAt"]}
                format="DD MMM, YYYY"
              />
            </span>
          </div>
          <div className="comment-wrapper">
            {!this.props.review["touredAt"] ? (
              <p>
                Lived in this sober living from{" "}
                {
                  <Moment
                    date={this.props.review["livedFrom"]}
                    format="DD MMM, YYYY"
                  />
                }{" "}
                to{" "}
                {
                  <Moment
                    date={this.props.review["livedTo"]}
                    format="DD MMM, YYYY"
                  />
                }
              </p>
            ) : (
              <p>
                Toured in this sober living at{" "}
                {
                  <Moment
                    date={this.props.review["touredAt"]}
                    format="DD MMM, YYYY"
                  />
                }
              </p>
            )}
            {this.props.review.comment}

            {this.props.isHost && (
              <a
                onClick={() => {
                  this.setState({ showReplyForm: true });
                  this.props.replyCallback(this.props.review.id);
                }}
                className="reply"
              >
                <img src={require("../../assets/images/reply.svg")} alt="" />
                Reply
              </a>
            )}
            {this.state.showReplyForm && (
              <ReviewReplyForm
                submitReview={(review) => {
                  this.props.submitReview(review);
                  this.setState({ showReplyForm: false });
                }}
                userName={this.props.user?.name}
                isHost={true}
              />
            )}
          </div>
        </div>
        <div className="review-reply pl-5">
          {this.props.review.replies.map((reply, index) => {
            return (
              <div key={index}>
                <ReviewReply {...reply} />
              </div>
            );
          })}
        </div>
      </div>)}
      </>
    );
  }
}

export default ReviewDetailsComponent;
