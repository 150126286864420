import React, { Component } from 'react';
import BackButtonComponent from "../../components/other/back-button.component";
import Slider from "rc-slider";
import SoberLivingCard from '../../components/cards/sober-living.card'
import { livingAction } from "../../actions";
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import ActivityLineGraph from "../../components/graph/line.graph";
import { organicDataChartConfigs, sponsoredDataChartConfigs } from '../../constants/activity-graph.constant';
import moment from 'moment';
import { isToday } from "date-fns";
import ConfirmationModalComponent from "../../components/modal/confirmation-modal.component";
import { SpinnerContext } from '../../components/spinner/spinner.component';


const { createSliderWithTooltip } = Slider;
const SliderWithToolTip = createSliderWithTooltip(Slider);

class AddAdvertisement extends Component {
    static contextType=SpinnerContext;
    constructor(props) {
        super(props);
        const { match } = this.props;
        this.state = {
            budgets: [50, 100, 150],
            selectedBudget: 50,
            isCustomizedRate: false,
            livingId: match.params.id,
            livingDetails: {},
            showModal:false
        }
    }
    _APICall() {
        if (!this.props.isFetchinhostLivings) {
         this.context.showLoader('Fetching sober living for you..');
          this.props.fetchhostLivings().finally(() => {
            const livingDetails =this.props.hostLivings.length !== 0&& this.props.hostLivings.filter((living) => living.id === this.state.livingId)[0];
            this.setState({ livingDetails });
            this.setState({selectedBudget:livingDetails.promoteBudget||50})
            this.context.hideLoader();

          });
        }
      }
    
      async componentDidMount() {
        const livingDetails =this.props.hostLivings.length !== 0 &&this.props.hostLivings.filter((living) => living.id === this.state.livingId)[0];
        if(!livingDetails){
            await this._APICall();

        }else{
            this.setState({ livingDetails });
            this.setState({selectedBudget:livingDetails.promoteBudget||50})

        }
      }

    // componentDidMount() {
    //     const livingDetails = this.props.hostLivings.filter((living) => living.id === this.state.livingId)[0];
    //     this.setState({ livingDetails });
    // }

    generateLabels = (activity) => {
        const modifiedLabels = activity.map((d) => {
            return moment(d.date).format('D');
        })
        return modifiedLabels.reverse();
    }

    getSponsoredData = (activity) => {
        const data = activity.map((d) => {
            

            return isToday(new Date(d.date)) ? d.sponsored + (this.state.selectedBudget/30 * 6) : d.sponsored;
        });
        return data.reverse();
    }

    getOrganicData = (activity) => {
        const data = activity.map((d) => {
            return d.organic;
        });
        return data.reverse();
    }

    getChartData = () => {
        const activityData = this.props.activity.last30Days?.filter((act) => act.propertyId === this.state.livingId)[0];
        let chartData = {};
        if (activityData) {
            const labels = this.generateLabels(activityData && activityData.activity);
            chartData.labels = labels;
            chartData.datasets = [
                {
                    ...sponsoredDataChartConfigs,
                    data: this.getSponsoredData(activityData.activity)
                },
                {
                    ...organicDataChartConfigs,
                    data: this.getOrganicData(activityData.activity)
                }
            ]
        }
       
        return chartData;
    }
    onCancelModal=()=>{
        this.setState({showModal:false});
    }
    onShowModal=()=>{
        this.setState({showModal:true});
    }
 
    onTurnOffAd=(livingId)=>{
        this.props
        .onTurnOffAd(livingId)
        .then((data) => {
            this.context.showMessage("Turned off Ad successfully!");
            this.onCancelModal();
            setTimeout(() => {
                this.props.history.replace('/profile');
            }, 5000);
        })
        .catch((err) => {
            this.onCancelModal();
        });
    }
    render() {
        const { budgets, selectedBudget, livingDetails } = this.state;
        const {id,isPromoted,promoteBudget,promotionEndDate,promotionStartDate,advertisementID,stopped}=livingDetails||{};
        return (
            <div>
                <section className="back">
                    <BackButtonComponent className="container d-inline" isBlack={true} />
                  {isPromoted && !stopped&& <button onClick={()=>this.onShowModal()} className="float-right btn btn-primary ml-3 mr-3 mr-md-5 radius-20">
                            Turn Off Ad
                    </button>}
                </section>
                <ConfirmationModalComponent show={this.state.showModal} onCancel={this.onCancelModal} onConfirm={()=>this.onTurnOffAd(id)} title={"Cancel Ad"} message={`If you confirm and end your sober living promotion ad, your current ad will still be available until ${moment(promotionEndDate).format("MM/DD/YYYY")}. Would you like to continue the cancellation?`} cancelButtonName={"No, Go Back"} confirmButtonName={"Confirm"}/>
                
                <section className="listing pt-4">
                    <div className="container">

                        <div className="listing-header">
                            <h3 className="main-title mb-0">Taste ADS </h3>
                        </div>

                       <div className="row">
                            {!isEmpty(livingDetails) &&
                                <div className="col-md-6 col-lg-4 mb-3 md-mb-0">
                                    <SoberLivingCard item={livingDetails} ratingOutside={true} isRecommended={true} promoted={true} />
                                </div>
                            }
                        </div>

                        <div className="listing-header">
                            <h3 className="main-title mb-0">Budget </h3>
                        </div>
                        <div className="budget-selection">
                            <div className="budget-amount">
                                <div className="common-selection ">
                                    <div className="btn-group w-100" role="group" aria-label="Basic example">
                                        <div className="ml-1">
                                        {budgets.map((budget, index) => {
                                            const className = (budget === selectedBudget) ? 'btn group-primary active' : 'btn group-primary'
                                            return (
                                                <button
                                                    key={index}
                                                    type="button"
                                                    className={className}
                                                    onClick={() => this.setState({ selectedBudget: budget, isCustomizedRate: false })}>
                                                    ${budget}
                                                </button>
                                            )
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <span className="or">OR</span>
                            <div className="customize-price">
                                <label htmlFor="">Customize price / per day</label>
                                <SliderWithToolTip
                                    min={parseInt(process.env.REACT_APP_MIN_AD_BUDGET) || 0}
                                    max={parseInt(process.env.REACT_APP_MAX_AD_BUDGET) || 40}
                                    value={selectedBudget}
                                    tipFormatter={(value) => ` ${value}$`}
                                    onChange={(value) => {
                                        if (selectedBudget !== value) {
                                            this.setState({ selectedBudget: value, isCustomizedRate: true })
                                        }
                                    }}
                                />
                            </div> */}
                        </div>

                        <div className="ads-review">
                            <div className="dya-average"><span className="big-font">${selectedBudget}</span><span className="small-font">/ month</span></div>
                            {/* <span className="month-average">${selectedBudget * 30} / month maximum</span> */}
                            <div>
                            {promoteBudget && promoteBudget==selectedBudget?<span className="font-weight-bold">{`Current Ad is $${promoteBudget || ""}/month, billed on ${moment(promotionStartDate).format("MM/DD/YYYY")}`}</span>:
                            <span className="font-weight-bold">{promoteBudget && `Current Ad is $${promoteBudget||""}/month,will end on  ${moment(promotionEndDate).format("MM/DD/YYYY")} and new ad of $${selectedBudget||""}/month will begin on ${moment(promotionEndDate).add(1,"days").format("MM/DD/YYYY")}`}</span>}
                            </div>

                            <span className="month-average">${selectedBudget} / month</span>
                            
                            {selectedBudget > 100 ?
                                (
                                    <ul className="ads-features">
                                        <li>Appear in Recommended Sober Living section for every user within a 30 mile radius of your home.</li>
                                        <li>Appear at the top of the list when someone searches your city or nearby cities.</li>
                                        <li>Appear at the top of Recommended Sober Livings section on every users home page nation wide.</li>
                                        <li>Appear at the top of the list if your state is in the 7 Best States for Sober Living.</li>
                                    </ul>
                                ) :
                                selectedBudget > 50 ?
                                    (
                                        <ul className="ads-features">
                                            <li>Appear in Recommended Sober Living section for every user within a 30 mile radius of your home.</li>
                                            <li>Appear at the top of the list when someone searches your city or nearby cities.</li>
                                        </ul>
                                    ) :
                                    (
                                        <ul className="ads-features">
                                            <li>Appear in Recommended Sober Living section for every user within a 30 mile radius of your home.</li>
                                        </ul>
                                    )
                            }
                        </div>
                        <div className="chart">
                            <ActivityLineGraph
                                data={this.getChartData()}
                            />
                            <div className="chart-legend-wrapper">
                                <div className="chart-legend">
                                    <span className="organic-view-box"></span><span>Organic Views (current)</span>
                                </div>
                                <div className="chart-legend">
                                    <span className="sponsored-view-box"></span><span>Sponsored Views (estimated)</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center my-4">
                            <a
                                id="nextStep"
                                className="btn btn-primary radius-20 btn-min-width text-bold"
                                onClick={() => {
                                    var dailyAmt= this.state.selectedBudget;
                                    this.props.history.push('/payment-options');
                                    this.props.saveLaunchAdDetails({
                                        livingId: this.state.livingId,
                                        selectedBudget: dailyAmt,
                                        livingName: this.state.livingDetails.name,
                                        advertisementID:advertisementID
                                    })
                                }}
                            >
                                Launch ADS
                            </a>
                        </div>

                    </div>
                </section>
            </div>
        )
    }
}

export default connect(
    ({ living }) => ({
        hostLivings: living.hostLivings,
        isFetchinhostLivings: living.fetchingHostLivings,
        isFetchingActivity: living.isFetchingActivity,
        activity: living.activity
    }),
    livingAction
)(AddAdvertisement);
