import React from "react";
const Logoutsessionmodal = ({ open}) => {
 
  return (
    <>
    {open &&
    <div className="modal upgrade-plan cancel-subscription-modal login-session-modal fade show" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="upgrade-plan-content">
                        <p>Your login session has been expired. Please sign in again.</p>
                    </div>
                </div>
            </div>
        </div>
}
    </>
  );
};

export default Logoutsessionmodal;