import React from "react";
import { useSelector } from "react-redux";
import SaveHomeCard from "../../components/cards/SaveHome";


const SavedHomes = () => {
  const { favLivings, favilityAll } = useSelector((state) => ({
    favLivings: state.living.favLivings,
    favilityAll: state.living.favilityAll,
  }));



  return (
    <>
      <div className="savehome-wrp setting-list recommended active" id="two">
        <h3 className="main-title mb-0 flex-fill">
          Saved facilities ({favLivings?.length + favilityAll?.data?.length}){" "}
        </h3>
        <SaveHomeCard data={favLivings} name={"Sober Living"} />
        <SaveHomeCard
          data={favilityAll?.data?.filter(
            (item) => item.facilityType === "iop"
          )}
          name={"Iop"}
        />
        <SaveHomeCard
          data={favilityAll?.data?.filter(
            (item) => item.facilityType === "php"
          )}
          name={"Php"}
        />
        <SaveHomeCard
          data={favilityAll?.data?.filter(
            (item) => item.facilityType === "residential"
          )}
          name={"Residential"}
        />
        <SaveHomeCard
          data={favilityAll?.data?.filter(
            (item) => item.facilityType === "detox"
          )}
          name={"Detox"}
        />
      </div>
    </>
  );
};

export default SavedHomes
