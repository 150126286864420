import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import NoImage from "../../assets/images/no-image.svg";
import tickMarkIcon from "../../assets/images/tick-mark.svg";
import pinImg from "../../assets/images/ic_pin_small.svg";
import starImg from "../../assets/images/star-24px.svg";
import star from "../../assets/images/Star.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import SliderNextArrow from "../slider/sliderNext.component";
import SliderPrevArrow from "../slider/sliderPrevArrow.component";
const RecommendedFacility = (props) => {
  const { recommendedFacilities, settings1, recommendedSlider } = props;

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleAfterChange = (index) => {
    setCurrentSlide(index);
  };
  const settings = {
    dots: true,
    arrows: true,
    infinite: recommendedFacilities?.length > 1, // Set to false if only one item
    speed: 500,
    slidesToShow: Math.min(recommendedFacilities?.length, 1), // Show one slide if only one facility is present
    slidesToScroll: 1,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
    },
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };

  return (
    <div className="facility-content">
      <div className="review-header d-flex align-items-center mb-4">
        <h3 className="my-0  mr-4">
          {" "}
          <img src={star} className="img-fluid facility-img mr-2" alt="" />
          <span>Recommended Facilities</span>
        </h3>
      </div>

      <Slider
        {...settings}
        {...recommendedSlider}
        className={
          recommendedFacilities?.length === 1
            ? "gallery-slider recommend-facilities-slider"
            : "gallery-slider recommend-facilities-slider margin-bottom"
        }
        afterChange={handleAfterChange}
      >
        {recommendedFacilities?.map((ele) => {
          return (
            <React.StrictMode key={ele?.id}>
              <Link
                className="text-decoration-none"
                to={{
                  pathname:
                    ele?.facilityType === "living"
                      ? `/living/sober-living-in-${ele?.geocodingResult?.cityName}&id=${ele?.id}`
                      : `/home/centers/view/${ele?.id}`,
                }}
              >
                <div className="facility-list-item py-0">
                  <div className="img-slider position-relative">
                    {ele?.subscriptionType === "pro" ||
                    ele?.subscriptionDetail?.subscriptionType === "pro" ? (
                      <Slider {...settings}>
                        {ele?.photos?.length > 0 ? (
                          ele?.photos?.map((item) => {
                            return (
                              <img
                                src={item?.path}
                                className="img-fluid facility-img"
                                alt=""
                                key={item?.id}
                              />
                            );
                          })
                        ) : (
                          <img
                            src={NoImage}
                            className="img-fluid facility-img"
                            alt=""
                          />
                        )}
                      </Slider>
                    ) : (
                      <Slider {...settings}>
                        {ele?.photos?.length > 0 ? (
                          <img
                            src={ele?.photos[0]?.path}
                            className="img-fluid facility-img"
                            alt=""
                          />
                        ) : (
                          <img
                            src={NoImage}
                            className="img-fluid facility-img"
                            alt=""
                          />
                        )}
                      </Slider>
                    )}
                    {/* {(ele?.preSalePlanType === "pro" || ele?.subscriptionDetail?.subscriptionType === "pro") && <span className="subscription-msg unsubscribed">Featured</span>} */}
                    {ele?.isPPCSubscribed && (
                      <span className="promote-ad-msg">Ad</span>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="card-heading">
                      {ele?.name?.length > 30
                        ? ele?.name
                            ?.slice(0, 30)
                            ?.split(" ")
                            ?.map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ") + "...."
                        : ele?.name
                            ?.split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                      {ele?.isVerified && (
                        <img src={tickMarkIcon} className="img-fluid" alt="" />
                      )}
                    </div>
                    <span className="location">
                      <img src={pinImg} className="img-fluid" alt="" />
                      {ele?.geocodingResult?.cityName}
                      {", "}
                      {ele?.geocodingResult?.stateShort}
                      {", "}
                      {ele?.geocodingResult?.country}
                    </span>

                    {(ele?.isSubscribed || ele?.isPreSaleSubscribed) && (
                      <span className="location">
                        <img src={starImg} className="img-fluid m-0" alt="" />
                        {ele?.rating?.value !== 0 ? (
                          <>
                            <span>{ele?.rating?.value?.toFixed(1)}</span>
                          </>
                        ) : (
                          <>
                            <span>0.0</span>
                          </>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </Link>
            </React.StrictMode>
          );
        })}
      </Slider>
    </div>
  );
};

export default RecommendedFacility;
