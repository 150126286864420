import { IMAGE_THUMBNAILS, PAYMENT_TYPE } from "../constants";

/**
 * @return {string}
 */
export function Ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getRatingsFromItem = (item) => {
  let value = 0,
    quantity = 0;
  if (item && item.rating) {
    value = item.rating.value;
    quantity = item.rating.quantity;
  }
  // return `${value} / ${quantity}`;
  return value.toString().length == 1
    ? ` ${value.toFixed(1)}`
    : ` ${value.toFixed(1)}`;
};

export const getPriceFromItem = (item, isPriceOnly = false) => {
  let price = "";
  if (item.paymentType === PAYMENT_TYPE.FIXED) {
    price = `${item.rentCost}`;
  } else {
    price = `${item?.rentRange?.from} - ${item?.rentRange?.to}`;
  }
  if (item.isRecommended || isPriceOnly) {
    return `${price}`;
  }
  return `${price} / ${Ucfirst(item.paymentPeriod)}`;
};

export const getLocationFromItem = (item) => {
  const { cityName, stateShort, country } = item.geocodingResult;
  return `${cityName}, ${stateShort}, ${country}`;
};

export const getImagesFromItem = (item, size = IMAGE_THUMBNAILS.THUMB720W) => {
  const images = [];
  if (!item || !item.hasOwnProperty("photos") || item.photos.length === 0) {
    return [];
  }

  item.photos.forEach((image) => {
    if (
      !image.hasOwnProperty("thumbnails") ||
      image["thumbnails"].length === 0
    ) {
      images.push(image.path);
    } else {
      images.push(image.thumbnails[size]);
    }
  });
  return images;
};

export const getDateInFormat = (dateStr) => {
  const date = new Date(dateStr);
  const options = {
    day: "numeric",
    month: "short",
    literal: ",",
    year: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

export const convertEnumToArray = (obj) => {
  const ar = [];
  for (const p in obj) {
    ar.push({ label: p, value: obj[p] });
  }
  return ar;
};

export function isMobile() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export const removeCache = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
  // window.location.reload();
};

export const deleteOldCache = () => {
  var cachesToKeep = ["taste-recovery-v2", "images-v2"];
  caches.keys().then(function (keyList) {
    return Promise.all(
      keyList.map(function (key) {
        if (cachesToKeep.indexOf(key) === -1) {
          return caches.delete(key);
        }
      })
    );
  });
};

export const checkInternet = () => {
  // return fetch('https://www.google.com/', { // Check for internet connectivity
  //     mode: 'no-cors',
  //     })
  // .then(() => {
  //      return true
  // }).catch(() => {
  //      return false
  // })
  return navigator.onLine ? true : false;
};

export const getLocationFromUrl = (url) => {
  let splitUrl = url.split("-");
  let selectStateAndCountry = splitUrl.splice(-2, 2).join(", ");
  let deleteSoberLivingText = splitUrl.splice(0, 3);
  let city = splitUrl.join(" ");
  let location = `${city}, ${selectStateAndCountry}`;
  return location;
};
export const getCityName = (location) => {
  let city = location?.cityName.split(",")[0].replaceAll(" ", "-");
  return city?.toLowerCase();
};
