
import React from 'react';
import slickNextArrow from '../../assets/images/arrow_right.svg';

const SliderNextArrow = (props) => {
  const { className, style, onClick, currentSlide } = props;

  const handleOnClick = (event) => {
    if (currentSlide !== 4) {
      onClick(event);
    }
  };

  return (
    <div
      className={`slick-arrow next-arrow ${currentSlide === 4 ? 'disabled' : ''}`}
      onClick={handleOnClick}
      style={{ pointerEvents: currentSlide === 4 ? 'none' : 'auto' }}
    >
      <img src={slickNextArrow} className="img-fluid" alt="" />
    </div>
  );
};

export default SliderNextArrow;
