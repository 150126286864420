import { combineReducers } from "redux";
import { autReducer } from "./auth.reducer";
import { homeReducer } from "./home.reducer";
import { livingReducer } from "./living.reducer";
import { tourReducer } from "./tour.reducer";
import { paymentReducer } from "./payment.reducer";
import { chatReducer } from "./chat.reducer";
import { iopcenterReducer } from "./iopcenter.reducer";
import { residentialReducer } from "./residential.reducer";
import { detoxReducer } from "./detox.reducer";
import { phpReducer } from "./php.reducer";
import { preSaleReducer } from "./presale.reducer";
import { surveyReducer } from "./survey.reducer";
import { DashboardReducer } from "./dashboard.reducer";
export const rootReducer = combineReducers({
  auth: autReducer,
  home: homeReducer,
  living: livingReducer,
  iopCenter: iopcenterReducer,
  tour: tourReducer,
  payment: paymentReducer,
  chat: chatReducer,
  residential: residentialReducer,
  detox: detoxReducer,
  php: phpReducer,
  preSale: preSaleReducer,
  survey: surveyReducer,
  dashboard: DashboardReducer,
});
