import React, { Component } from "react";
import { Modal, Spinner, Toast, Alert } from "react-bootstrap";

export const SpinnerContext = React.createContext({});

export class SpinnerComponent extends Component {
  spinnerStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    color: "#FF5F5F",
    alignItems: "center",
    zIndex:999999
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      message: "",
      stickyHeader: true,
      stickyFooter: true,
      showLogin: false,
      showMessage: false,
      subscribePlanId: "",
      subscribePlanType:"",
      subscribePage: "",
      livingId: "",
      addSober: "",
      selectedInterval: "",
      selectedAmount: "",
      iopId: "",
      addDetox: "",
      facilityId: "",
      facilityName: "",
      claimedFacility: "",
    };
  }

  showProgress = (message) => this.setState({ isLoading: true, message });
  hideProgress = () => this.setState({ isLoading: false });

  render() {
    const funcs = {
      showLoader: this.showProgress,
      hideLoader: this.hideProgress,
      setStickyHeader: (value) => this.setState({ stickyHeader: value }),
      isStickyHeader: () => this.state.stickyHeader,
      setStickyFooter: (value) => this.setState({ stickyFooter: value }),
      isStickyFooter: () => this.state.stickyFooter,
      showLogin: (showLogin) => this.setState({ showLogin }),
      isShowLogin: () => this.state.showLogin,
      showMessage: (message) => {
        this.setState({ showMessage: true, message });
        setTimeout(() => {
          this.setState({ showMessage: false });
        }, 4000);
      },
      setsubscribePlanId: (value) => this.setState({ subscribePlanId: value }),
      subscribePlanId: this.state.subscribePlanId,
      setsubscribePlanType: (value) => this.setState({ subscribePlanType: value }),
      subscribePlanType: this.state.subscribePlanType,
      setLivingId: (value) => this.setState({ livingId: value }),
      livingId: this.state.livingId,
      setsubscribePage: (value) => this.setState({ subscribePage: value }),
      iopId: this.state?.iopCenter?.iopHouse?.id,
      setsubscribePage: (value) => this.setState({ iopId: value }),
      subscribePage: this.state.subscribePage,
      setSober: (value) => this.setState({ addSober: value }),
      addSober: this.state.addSober,
      setselectedInterval: (value) =>
        this.setState({ selectedInterval: value }),
      selectedInterval: this.state.selectedInterval,
      setselectedAmount: (value) => this.setState({ selectedAmount: value }),
      selectedAmount: this.state.selectedAmount,
      setDetox: (value) => this.setState({ addDetox: value }),
      addDetox: this.state.addDetox,
      setPhp: (value) => this.setState({ addPhp: value }),
      addPhp: this.state.addPhp,
      setResidential: (value) => this.setState({ addResidential: value }),
      addResidential: this.state.addResidential,
      setIop: (value) => this.setState({ addIop: value }),
      addIop: this.state.addIop,
      setFacilityId: (value) => this.setState({ facilityId: value }),
      facilityId: this.state.facilityId,
      setFacilityName: (value) => this.setState({ facilityName: value }),
      facilityName: this.state.facilityName,
      setClaimedFacility: (value) => this.setState({ claimedFacility: value }),
      claimedFacility: this.state.claimedFacility,
    };
    return (
      <SpinnerContext.Provider value={{ ...funcs }}>
        {this.props.children}
        {this.state.showMessage && (
          <Alert className="alert-message" variant={"success message-alert"}>
            {this.state.message}
          </Alert>
        )}
        {this.state.isLoading === true && (
          <Modal
            centered
            show={this.state.isLoading}
            style={this.spinnerStyle}
            className="spinner-taste"
          >
            <Spinner animation="border" role="status" />
            <span className="loading-msg"> {this.state.message}</span>
          </Modal>
        )}
      </SpinnerContext.Provider>
    );
  }
}
