import React, { Component } from 'react';
import Moment from 'react-moment';

class ReviewReply extends Component {
  render() {
    return (
      <div className="review-wrapper">
        <div className="user-img avatar-img">
          <div className="name-avtar">
            <span> {this.props['createdBy'].name.substr(0, 1).toUpperCase()}</span>
          </div>
        </div>
        <div className="review-content">
          <div className="review-heading">
            <h4>{this.props.createdBy.name?.split(" ")[0]}</h4>
          </div>
          <span className="date">
            <Moment date={this.props.createdAt} format="DD MMM, YYYY" />
          </span>
        </div>
        <div className="comment-wrapper">
          {this.props.content}
          {this.props.replies.map((reply, index) => {
            return <ReviewReply {...reply} />;
          })}
        </div>
      </div>
    );
  }
}

export default ReviewReply;
