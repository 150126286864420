import * as Yup from "yup";

export const sampleSchema = Yup.object().shape({
    name: Yup.string().required('Please enter name'),
})

export const addLivingSchemaStep1 = Yup.object().shape({
    name: Yup.
        string().
        min(6, 'Name should be atleast 6 characters').
        required("Sober living name is required"),

    about: Yup.string().required('About field is required'),

    webUrl: Yup.string(),
    phoneNumber: Yup.string().
        max(10, 'Maximum length of Phone Number should be 10 digits').
        nullable(),

    photos: Yup
        // .of() - apply schema here
        .array()
        // .of(Yup.object().shape({
        //     name: Yup.string()
        // }))
        .min(1, 'Please add \'Sober Living Photo\'')
        .required('Please add \'Sober Living Photo\''),

    geocodingResult: Yup.object().shape({
        cityName: Yup.string().required("Please enter \'City\'"),
    }),

    options: Yup.array().min(1, 'Please select \'Available Amenities\'').required('Please select \'Available Amenities\''),

    type: Yup.string().required('Please select \'Type of Property\''),

    paymentType: Yup.string().required('Please select Payment type'),

    paymentPeriod: Yup.string().required('Please select \'Weekly or Monthly Payment\''),

    rentCost: Yup.mixed().when('paymentType', {
        is: 'fixed',
        then: Yup.number().positive('Please enter valid \'Payment\'').required('Please enter \'Payment\'')
    }),

    rentRange: Yup.mixed().when('paymentType', {
        is: 'range',
        then: Yup.object().shape({
            from: Yup.number().required("Please enter minimum price").typeError("Please enter valid form of cost").min(process.env.REACT_APP_MIN_PRICE_RANGE, `Minimum price should be $${parseInt(process.env.REACT_APP_MIN_PRICE_RANGE)}`)
                .when('to', {
                    is: true,
                    then: Yup.number().test("max", "Maximum price difference $50 is required", function (value) {
                        const { to } = this.parent;
                        return value < to - 50;
                    })
                }),
            to: Yup.number().required("Please enter maximum price").typeError("Please enter valid form of cost").test("min", "Maximum price difference $50 is required", function (value) {
                const { from } = this.parent;
                return value > from + 50;
            }).max(parseInt(process.env.REACT_APP_MAX_PRICE_RANGE), `Maximum price should be $${parseInt(process.env.REACT_APP_MAX_PRICE_RANGE)}`)
        })
    }),

    serving: Yup.string().required('Please select \'House serving\''),

    address: Yup.string().required('Please enter \'Street Address\''),

    aptSuite: Yup.string().nullable(),

    state: Yup.string().nullable(),

    zipCode: Yup.string().required('Please enter zip code'),

    coordinates: Yup.object().shape({
        lat: Yup.number(),
        lng: Yup.number()
    }),

    bedroomQuantity: Yup.number().required('Please select \'Bedroom Quantity\''),

    isOfferPrivateBedrooms: Yup.boolean(),

    bathroomQuantity: Yup.number().required('Please select \'Bathroom Quantity\''),
    isOfferPrivateBathrooms: Yup.boolean(),

    maximumOccupancy: Yup.string().required('Please select \'Maximum Occupancy\''),

    isHandicapFriendly: Yup.boolean(),

    isProvideTransportation: Yup.boolean(),

    isSmokeFriendly: Yup.boolean(),

    havePrivateChef: Yup.boolean(),

    isAvailableForTours: Yup.boolean(),
    isAvailableCurrently: Yup.boolean(),
    availableForToursDates: Yup.array(),

    offersPaymentPlans: Yup.boolean(),
    offersScholarships: Yup.boolean(),
    offersCaseManagement: Yup.boolean(),

    haveTrainedSupportStaff: Yup.boolean(),
    staffRequiredHaveAYearOfSobriety: Yup.boolean(),
    isAllowedWithChildren: Yup.boolean()
    // STEP - 2 started
});


export const addLivingSchemaStep2 = Yup.object().shape({

    mustClimbStairs: Yup.boolean(),
    isPetLiveInProperty: Yup.boolean(),
    isNoParking: Yup.boolean(),
    isStreetParkingOnly: Yup.boolean(),
    haveSurveillance: Yup.boolean(),
    isOnlyAcceptsCashOrChecks: Yup.boolean(),
    isAcceptsPaypal: Yup.boolean(),


    requirements: Yup.object().shape({
        isSobrietyRequirement: Yup.boolean(),
        sobrietyRequirement: Yup.string().nullable().when('isSobrietyRequirement', {
            is: true,
            then: Yup.string().required('Please select \'Sobriety Requirement\'')
        }),
        isGoneThroughAPriorProgram: Yup.boolean(),
        isCompletedDetox: Yup.boolean(),
        isAdministerMeds: Yup.boolean(),
    }),

    houseRules: Yup.object().shape({
        dailyChores: Yup.boolean(),
        weeklyChores: Yup.boolean(),
        randomDrugTesting: Yup.boolean(),
        zeroTolerancePolicy: Yup.boolean(),
        curfew: Yup.boolean(),
        curfewTime: Yup.object().shape({
            daily: Yup.string().nullable(),
            sun: Yup.string().nullable().when('daily', {
                is: 'Different hours per day',
                then: Yup.string().required('Please enter curfew time per day value')
            }),
            mon: Yup.string().nullable().when('daily', {
                is: 'Different hours per day',
                then: Yup.string().required('Please enter curfew time per day value')
            }),
            tue: Yup.string().nullable().when('daily', {
                is: 'Different hours per day',
                then: Yup.string().required('Please enter curfew time per day value')
            }),
            wed: Yup.string().nullable().when('daily', {
                is: 'Different hours per day',
                then: Yup.string().required('Please enter curfew time per day value')
            }),
            thu: Yup.string().nullable().when('daily', {
                is: 'Different hours per day',
                then: Yup.string().required('Please enter curfew time per day value')
            }),
            fri: Yup.string().nullable().when('daily', {
                is: 'Different hours per day',
                then: Yup.string().required('Please enter curfew time per day value')
            }),
            sat: Yup.string().nullable().when('daily', {
                is: 'Different hours per day',
                then: Yup.string().required('Please enter curfew time per day value')
            }),
        }),
        mandatoryHouseMeetings: Yup.boolean(),
        twelveStepMeetings: Yup.boolean(),
        currentStep: Yup.number(),
        weeklyCheckIns: Yup.boolean(),
        personalGoals: Yup.boolean(),
        mustBeOutOfHouse: Yup.boolean(),
        mustBeOutOfHouseDateRange: Yup.object().when('mustBeOutOfHouse', {
            is: true,
            then: Yup.object().shape({
                from: Yup.string().required('Please enter correct time range for \'Must Be Out Of House\''),
                to: Yup.string().required('Please enter correct time range for \'Must Be Out Of House\''),
            })
        }),
        haveGuestVisitingHours: Yup.boolean(),
        guestVisitingHours: Yup.object().when('haveGuestVisitingHours', {
            is: true,
            then: Yup.object().shape({
                from: Yup.string().required('Please enter correct time range for \'Guest visiting hours\''),
                to: Yup.string().required('Please enter correct time range for \'Guest visiting hours\'')
            })
        }),
        haveQuietHours: Yup.boolean(),
        quietHours: Yup.object().when('haveQuietHours', {
            is: true,
            then: Yup.object().shape({
                from: Yup.string().required('Please enter correct time range for \'Quiet Hours\''),
                to: Yup.string().required('Please enter correct time range for \'Quiet Hours\''),
            }),
        }),
        isOvernightPassesApprovedInAdvance: Yup.boolean(),
        overnightPassesApprovedInAdvance: Yup.number().when('isOvernightPassesApprovedInAdvance', {
            is: true,
            then: Yup.number().required('Please enter Passes Approved'),
        }),
        isClientRestrictionFor: Yup.boolean(),
        clientRestrictionFor: Yup.string().nullable().when('isClientRestrictionFor', {
            is: true,
            then: Yup.string().required('Please enter Client Restriction'),
        }),
        zeroToleranceForStealing: Yup.boolean(),
        zeroToleranceForDestroyingProperty: Yup.boolean(),
        zeroToleranceForSexualActivity: Yup.boolean(),
        zeroToleranceForConfrontation: Yup.boolean(),
        mustNotifyOfPrescribedMeds: Yup.boolean(),
        allMedsMustBeTakenAsPrescribed: Yup.boolean(),
        isReportingAllRulesViolations: Yup.boolean(),

        meetingCardsAreDueAtHouseMeetings: Yup.boolean(),

        mustHaveASponsorFor12Steps: Yup.boolean(),
        residentsRoomsMustBeClean: Yup.boolean(),
        noLiteringInFrontOfTheProperty: Yup.boolean(),
        unusualJobSchedulesMustBeApproved: Yup.boolean(),
        payRentOnTheFirstOfEachMonth: Yup.boolean(),
        allSharedSpacesMustBeClean: Yup.boolean(),
        noIllegalActivity: Yup.boolean(),
        dndOtherResidents: Yup.boolean(),
        beAccountable: Yup.boolean(),
        abidingLocalCovidRules: Yup.boolean()
    }),


});