import React, { Component } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

class ButtonGroupComponent extends Component {
  render() {
    return (
      <div>
        <ButtonGroup className="w-100" onClick={this.onChange}>
          {Object.keys(this.props.options).map((item, index) => {
            item = this.props.options[item];
            return (
              <Button key={index} value={item} variant="default" className={`group-primary ${this.props.value === item ? ' active' : ''}`}>
                {item}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
    );
  }

  onChange = (val) => {

    this.props.onSelect(val.target.value);
  };
}

export default ButtonGroupComponent;
