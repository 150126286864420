import React from 'react';
import slickNextArrow from '../../assets/images/arrow_right.svg';

const SliderNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <div
            className={`slick-arrow next-arrow`}
            onClick={onClick}
        >
            <img src={slickNextArrow} className="img-fluid" alt="" />
        </div>
    );
};

export default SliderNextArrow;