import { applyMiddleware, compose, createStore } from "redux";
import { rootReducer } from "../reducers/index";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, storeEnhancers(applyMiddleware(thunk)));

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persiststore = persistStore(store);

export default store;
