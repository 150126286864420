import React, { Component, useState } from "react";
import { convertEnumToArray } from '../../utils/common.util'
import { CurfewOptions} from '../../constants/server.constant'

class FromToTimeline extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {
            fromName = '',
            toName = '',
            fromValue = '',
            toValue = '',
            fromHandleChange = () => { },
            toHandleChange = () => { },
            fromErrorText = '',
            toErrorText = '',
            disabled = false
        } = this.props;

        const isDisabled = (value, index, unit) => {
            const timeOptions = convertEnumToArray(CurfewOptions).slice(1);
            let disabled = false;
            let currIndex;
            if (unit === 'from') {
                if (toValue !== '') {
                    //get index of tovalue
                    timeOptions.some((t, i) => {
                        if (t.value === toValue) {
                            currIndex = i;
                            return true;
                        }
                    });

                    if (currIndex < index + 1) disabled = true
                } else {
                    disabled = false;
                }
            } else if (unit === 'to') {
                if (fromValue !== '') {
                    //get index of fromvalue
                    timeOptions.some((t, i) => {
                        if (t.value === fromValue) {
                            currIndex = i;
                            return true;
                        }
                    });

                    if (currIndex > index - 1) disabled = true
                } else {
                    disabled = false;
                }
            }
            return disabled;
        }

        return (
            <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6" style={{ marginBottom: 25 }}>
                        <div className="form-group dflex-form" style={{ marginBottom: 0 }}>
                            <select
                                disabled={disabled}
                                name={fromName}
                                value={fromValue}
                                className="form-control border-0"
                                onChange={fromHandleChange}>
                                <option disabled value="">From</option>
                                {
                                    convertEnumToArray(CurfewOptions).slice(1).map((option, index) => (
                                        <option key={index} value={option.value} >{option.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="error-message">{fromErrorText}</div>
                    </div>
                    <div className="col-md-6" style={{ marginBottom: 25 }}>
                        <div className="form-group dflex-form" style={{ marginBottom: 0 }}>
                            <select disabled={(disabled || fromValue === '')} name={toName} value={toValue} className="form-control border-0" onChange={toHandleChange} >
                                <option value="" disabled>To</option>
                                {
                                    convertEnumToArray(CurfewOptions).slice(1).map((option, index) => (
                                        <option key={index} value={option.value} >{option.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="error-message">{toErrorText}</div>
                    </div>
                </div>
            </div>
        )
    }

}


export default FromToTimeline;