import { chatActionType } from "../actions";

const intialChatState = {
  chats: [],
  fetchingChats: false,
  activeChat: [],
  fetchingActiveChat: false,
  addingChatMessage: false,
  searchingChat: false,
  searchedChat: [],
  Fetched_SurveyList: [],
  surveyLists: false,
  addingSurvey: false,
  fetchingSurvey: false,
  surveyList: {},
  updateSurvey: false,
  surveyStatus: {},
};

export const chatReducer = (state = intialChatState, action) => {
  switch (action.type) {
    case chatActionType.Fetching_Chat:
      return { ...state, fetchingChats: true };
    case chatActionType.Fetched_Chat:
      const { chats } = action.payload;
      return { ...state, fetchingChats: false, chats };

    case chatActionType.Fetching_Active_Chat:
      return { ...state, fetchingActiveChat: true };
    case chatActionType.Fetched_Active_Chat:
      return {
        ...state,
        fetchingActiveChat: false,
        activeChat: action.payload.activeChat,
      };

    case chatActionType.Adding_Chat_Message:
      return { ...state, addingChatMessage: true };

    case chatActionType.Added_Chat_Message:
      return { ...state, addingChatMessage: false };

    case chatActionType.Fetching_Chat_By_Name:
      return { ...state, searchingChat: true };
    case chatActionType.Fetched_Chat_By_Name:
      return {
        ...state,
        searchingChat: false,
        chats: action.payload.searchedChat,
      };

    case chatActionType.Facility_List:
      return {
        ...state,
        surveyLists: false,
        Fetched_SurveyList: action.payload?.facilitylist,
      };

    case chatActionType.Adding_Survey:
      return { ...state, addingSurvey: true, surveyStatus: action.payload };

    case chatActionType.Fetch_Survey:
      return {
        ...state,
        fetchingSurvey: false,
        surveyList: action.payload?.surveyList,
      };

    case chatActionType.Update_Survey:
      return {
        ...state,
        updateSurvey: false,
        surveyStatus: action.payload,
      };

    case chatActionType.Mark_Read:
      const updatedChat = state.chats.map((chat) => {
        if (chat.id === action.payload.chatId) {
          chat.unreadCount = 0;
        }
        return chat;
      });
      return { ...state, chats: updatedChat };
    default:
      return state;
  }
};
