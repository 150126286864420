import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import backImgBlack from "../../assets/images/back.svg";
import plusImg from "../../assets/images/plus.svg";
import cancleImg from "../../assets/images/cancle.svg";
import photoImg1 from "../../assets/images/pexels-photo-1798130.jpg";
import photoImg2 from "../../assets/images/pexels-photo-1525612.jpg";
import GoogleMapComponent from "../../components/map/google-map.component";
import accountImgDesktop1 from "../../assets/images/image_1.png";
import accountImgDesktop2 from "../../assets/images/image_2.png";
import LocationSearchInput from "../../components/map/location-search-input.component";
import FileUploadComponent from "../../components/upload-photos/FileUpload";
import { generateQueryParams, httpClient } from "../../utils/http-client.util";
import { useSelector, useDispatch } from "react-redux";
import { SERVER_APIS } from "../../constants";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import animatedComponents from "react-select/animated";
import { useHistory, useParams } from "react-router-dom";
import { homeAction, residentialAction } from "../../actions/index";
import Subscription from "../../components/subscription/subscription.component";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import ResidentialSubscription from "./residentialSubscription";

import {
  formatCamelCaseToReadabcodtiontreated,
  formatCamelCaseToReadableadditionaservices,
  formatCamelCaseToReadablemedication,
  formatCamelCaseToReadabletreatmentapproaches,
  formatString,
  formatTestKeyToReadableiopphp,
  urlCopypageresrict,
  formatCamelCaseToReadabpersonalAmenities,
  formatCamelCaseToReadabcommonAmenities,
  languages,
  colourStyles,
  formatCamelCaseToReadableteamconsists,
  formatCamelCaseToReadablesubstutanceorder,
  formatCamelCaseToReadableinsurance,
  formatCamelCaseToReadableadditionaservicesforesidentail,
  formatCamelCaseToReadableadditionaservicesforesidentailResidential,
} from "../../constants/utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ReactSelect from "../../components/dropdown/reactSelect.component";
import axios from "axios";
const AddResidential = () => {
  const location = useLocation();
  const residentialState = useSelector((state) => state?.residential);

  const dispatch = useDispatch();
  const history = useHistory();
  const comp = useParams();
  const inputRef = useRef(null);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const datainfo = useSelector((state) => state?.residential?.data);
  const context = React.useContext(SpinnerContext);
  const [currentstep, setCurrentStep] = useState(0);
  const [facilityType, setFacilityType] = useState();
  const [id, setId] = useState();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [isPreSaleSubscribed, setIsPreSaleSubscribed] = useState(false);
  const [isScholarship, setScholarship] = useState(false);
  const [recommendedFacility, setRecommendedFacility] = useState([]);
  const [selectedRecommendedID, setSelectedRecommendedID] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [name, setName] = useState("");
  const [media, setMedia] = useState([]);
  const [photo, setphoto] = useState([]);
  const [about, setAbout] = useState("");
  const [phone, setPhone] = useState("");
  const [ownermail, setownermail] = useState("");
  const [url, setUrl] = useState("https://www.");
  const [virtualUrl, setVirtualUrl] = useState("https://www.");
  const [street, setStreet] = useState("");
  const [rentRange, setRentRange] = useState({
    from: "",
    to: "",
  });
  const [allowableStay, setAllowableStay] = useState({
    from: "",
    to: "",
  });
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [geocodingResult, setGeocodingResult] = useState({
    cityName: "",
    stateLong: "",
  });
  const [isRecommended, setIsRecommended] = useState(false);
  const [cityName, setCityName] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isGenderSpecific, setIsGenderSpecific] = useState(true);
  const [gender, setGender] = useState("anybody");
  const [isAllowDogs, setIsAllowDogs] = useState(false);
  const [isAllowCouples, setIsAllowCouples] = useState(false);
  const [isAllowPregWomen, setIsAllowPregWomen] = useState(false);
  const [isAllowChildren, setIsAllowChildren] = useState(false);
  const [verifyInsurance, setVerifyInsurance] = useState("https://www.");
  const [insuranceNetwork, setinsuranceNetwork] = useState([]);
  const [response, setResonspnse] = useState("");
  const [maskedPhone, setMaskedPhone] = useState("");
  const [selectedMaximumcapicity, setselectedMaximumcapicity] = useState("");
  const [counts, setCounts] = useState({ clientCount: "", clinicianCount: "" });
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [payloadsendselectedLanguage, setpayloadsendSelectedLanguage] =
    useState("");
  const [selectotherLanguage, setselectotherLanguage] = useState([]);
  const [isOtherLanguagesOfferedshowhide, setIsOtherLanguagesOfferedshowhide] =
    useState(true);
  const [payloadsetotherLanguageArray, setpayloadsetotherLanguageArray] =
    useState([]);
  const [specialization, setSpecialization] = useState({
    substanceUseDisorder: false,
    mentalHealth: false,
    dualDiagnosis: false,
    adolescents: false,
    veterans: false,
    seniorsOrOlderAdults: false,
    youngAdults: false,
    telemedicineTelehealthTherapy: false,
    clientWithHIVOrAIDS: false,
    pregnantPostpartumWomen: false,
  });
  const [additionalServiceOffered, setAdditionalServiceOffered] = useState({
    residential24HourResidentia: false,
    residentialDetoxification: false,
    longTermResidential: false,
    shortTermResidential: false,
    php: false,
    iop: false,
    op: false,
  });
  const [personalAmenities, setPersnalAmenities] = useState({
    airConditionedRooms: false,
    armchairOrSofa: false,
    bathroomEssentials: false,
    coffeemaker: false,
    desk: false,
    suiteBathroom: false,
    hairDryer: false,
    housekeeping: false,
    cellPhoneAccess: false,
    internet: false,
    ironingDevice: false,
    jacuzzi: false,
    kitchenette: false,
    laundryService: false,
    personalChef: false,
    privateOrSharedRooms: false,
    sharedBathroom: false,
    snacks: false,
    thermostat: false,
    tv: false,
    view: false,
    walkInCloset: false,
    outdoorSpaceConnectedRoom: false,
  });
  const [commonAmenities, setcommonAmenities] = useState({
    pool: false,
    accessToNature: false,
    arcade: false,
    businessCenter: false,
    computers: false,
    fitnessCenter: false,
    gardens: false,
    gourmetDining: false,
    hotTub: false,
    library: false,
    outdoorDining: false,
    outdoorLounge: false,
    internet: false,
    recreationRoom: false,
    spa: false,
    walkingTrails: false,
    luxury: false,
    beachAccess: false,
    oceanView: false,
    chefPreparedMeals: false,
    excursions: false,
    airportTransfers: false,
    bathroomEssentials: false,
    basketballCourt: false,
  });
  const [conditionTreated, setConditionTreated] = useState({
    adhd: false,
    alcohol: false,
    anger: false,
    anxiety: false,
    benzodiazepines: false,
    bipolar: false,
    burnout: false,
    chronicPainManagement: false,
    chronicRelapse: false,
    coOccurringDisorders: false,
    cocaine: false,
    codependency: false,
    depression: false,
    drugAddiction: false,
    eatingDisorders: false,
    ecstasy: false,
    gambling: false,
    gaming: false,
    griefAndLoss: false,
    heroin: false,
    internetAddiction: false,
    marijuana: false,
    methamphetamine: false,
    nicotine: false,
    obsessiveCompulsiveDisorder: false,
    opioids: false,
    personalityDisorder: false,
    pornography: false,
    postTraumaticStressDisorder: false,
    prescriptionDrugs: false,
    psychedelics: false,
    schizophrenia: false,
    sexHarm: false,
    sexAddiction: false,
    shopping: false,
    smokingCessation: false,
    stress: false,
    suicidality: false,
    syntheticDrugs: false,
    trauma: false,
    weightLoss: false,
  });

  const [medicationTheray, setMedicationTheray] = useState({
    acamprosateCampral: false,
    disulfiram: false,
    buprenorphineWithNaloxone: false,
    buprenorphineWithoutNaloxone: false,
    buprenorphineExtendedReleaseInjection: false,
    naltrexoneOral: false,
    naltrexoneExtendedReleaseInjection: false,
    clonidine: false,
    medicationForMentalDisorders: false,
    nicotineReplacement: false,
    nonNicotineSmokingCessation: false,
  });

  const [treatmentApproaches, setTreatmentApproaches] = useState({
    angerManagement: false,
    briefIntervention: false,
    cognitiveBehavioralTherapy: false,
    contingencyManagementMotivationalIncentives: false,
    motivationalInterviewing: false,
    relapsePrevention: false,
    substanceUseDisorderCounselling: false,
    traumaRelatedCounselling: false,
    twelveStepFacilitation: false,
    faithBasedProgram: false,
    substanceUseDisorderEducation: false,
    individualCounselling: false,
    groupCounselling: false,
    familyCounselling: false,
    mentalCouplesCounselling: false,
    aaTherapy: false,
    reiki: false,
    musicTherapy: false,
    copingMechanism: false,
    griefManagement: false,
    yoga: false,
    breathworks: false,
    meditation: false,
    experientialTherapy: false,
    equineTherapy: false,
    gymDailyFitnessActivities: false,
    emdr: false,
    crossMappingMethod: false,
    brainSpotting: false,
    somaticCoaching: false,
  });

  const [preTreatment, setPreTreament] = useState({
    comprehensiveSubstanceUseAssessment: false,
    comprehensiveMentalHealthAssessment: false,
    outreachToPersonsInTheCommunity: false,
    completeMedicalHistoryPhysicalExam: false,
    screeningForTobaccoUse: false,
    screeningForSubstanceUse: false,
    screeningForMentalDisorders: false,
  });

  const [testing, setTesting] = useState({
    breathalyzerOrBloodAlcoholTesting: false,
    HIVTesting: false,
    drugOrAlcoholUrineScreening: false,
    STDTesting: false,
    TBTesting: false,
    testingForHepatitisB: false,
    testingForHepatitisC: false,
  });

  const [smokingPolicy, setSmokingPolicy] = useState({
    smokingAllowed: false,
    noSmoking: false,
    smokingPermittedInDesignatedAreas: false,
    vapingAllowed: false,
    noVaping: false,
    vapingPermittedInDesignatedAreas: false,
  });
  const [teamConsist, setTeamConsist] = useState({
    psychiatrist: false,
    doctor: false,
    nursePractitioners: false,
    psychologist: false,
    marriageFamilyTherapist: false,
    counsellors: false,
    registeredDieticiansNutritionist: false,
    postDoctoralPsychAssistants: false,
  });
  const [insurance, setInsurance] = useState({
    federalMilitaryInsurance: false,
    privateHealthInsurance: false,
    cashOrSelfPayment: false,
    stateFinancedHealthInsurance: false,
    medicaid: false,
  });
  const [
    isMobileTabContentVisibleforservices,
    setIsMobileTabContentVisibleforservices,
  ] = useState(false);
  const [
    isMobileTabContentVisibleforstaff,
    setIsMobileTabContentVisibleforstaff,
  ] = useState(false);
  const [
    isMobileTabContentVisibleforinsurance,
    setIsMobileTabContentVisibleforstaffinsurance,
  ] = useState(false);
  const [
    isMobileTabContentVisibleforrecommendfacility,
    setIsMobileTabContentVisibleforrecommednfacility,
  ] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [insuranceName, setInsuranceName] = useState([]);
  const [otherProvider, setOtherProvider] = useState("");
  const [othersVisible, setothersVisible] = useState(false);
  const [insuranceProvider, setinsuranceProvider] = useState([
    { value: "NA", label: "NA" },
    { value: "Blue Cross Blue Shield", label: "Blue Cross Blue Shield" },
    { value: "Cigna", label: "Cigna" },
    { value: "humana", label: "Humana" },
    { value: "carelon", label: "Carelon" },
    { value: "anthem", label: "Anthem" },
    { value: "united Healthcare", label: "United Healthcare" },
    { value: "tRICARE", label: "TRICARE" },
    { value: "health Net", label: "Health Net" },
    { value: "optum", label: "Optum" },
    { value: "others", label: "Others" },
  ]);
  const [openModalAmenities, setModalAmenities] = useState(false);
  const updateCurrentStep = useCallback(() => {
    if (currentstep == 0 && residentialState?.isChange) {
      setCurrentStep((prevStep) => (prevStep === currentstep ? 2 : 0));
    }
  }, [currentstep, media, residentialState?.isChange]);

  useEffect(() => {
    let isMounted = true;
    const updateStepAndCleanup = () => {
      if (isMounted) {
        updateCurrentStep();
      }
    };
    updateStepAndCleanup();

    return () => {
      isMounted = false;
      dispatch(residentialAction.isChangeTrue(false));
    };
  }, [currentstep, media, updateCurrentStep()]);

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");

    if (urlCopypageresrict(pastedText)) {
      return;
    }
    setUrl(pastedText);
  };

  const toggleMobileTabContentforAmenities = () => {
    setModalAmenities(!isMobileTabContentVisibleforstaff);
  };
  const toggleMobileTabContent = () => {
    setIsMobileTabContentVisibleforservices(
      !isMobileTabContentVisibleforservices
    );
  };
  const toggleMobileTabContentforstaff = () => {
    setIsMobileTabContentVisibleforstaff(!isMobileTabContentVisibleforstaff);
  };
  const toggleMobileTabContentforinsurance = () => {
    setIsMobileTabContentVisibleforstaffinsurance(
      !isMobileTabContentVisibleforinsurance
    );
  };
  const toggleMobileTabContentforRecomendfacility = () => {
    setIsMobileTabContentVisibleforrecommednfacility(
      !isMobileTabContentVisibleforrecommendfacility
    );
  };
  useEffect(() => {
    const fetchRecommendedHouse = async () => {
      try {
        const response = await axios.get(
          `${SERVER_APIS.recommendedFacilityListById}/${comp?.id}`
        );
        const formattedData = response.data.data.map((item) => ({
          label: item.name + ", " + item?.city + ", " + item?.state,
          value: item.id,
        }));
        setSelectedRecommendedID(formattedData);
      } catch (error) {
        console.error("Error fetching recommended house data:", error);
      }
    };
    if (comp?.id) {
      fetchRecommendedHouse();
    }
  }, []);

  useEffect(() => {
    if (url && !url.startsWith("https://www.") || (virtualUrl && !virtualUrl.startsWith(`https://www.`) || (verifyInsurance && !verifyInsurance.startsWith(`https://www.`)))) {
      // Remove any existing protocol or www and prepend 'https://www.'
      setUrl(`https://www.`);
      setVirtualUrl(`https://www.`)
      setVerifyInsurance(`https://www.`)
    }
  }, [url,verifyInsurance,virtualUrl]);

  useEffect(() => {
    const fetchDropDownRecommendedList = async () => {
      const res = await dispatch(homeAction.getAllFacilityList());
      setRecommendedFacility(res?.data);
    };
    fetchDropDownRecommendedList();
  }, []);

  const handleRecommendedFacility = (selectedOptions) => {
    const selectedIDs = selectedOptions?.map((item) => ({
      label: item?.label,
      value: item?.value,
    }));
    setSelectedRecommendedID(selectedIDs);
  };
  const recommendedfacilityodalviewmodeallstaeIsclear = () => {
    setSelectedRecommendedID([])
  };
  useEffect(() => {
    if (comp?.id !== undefined) {
      ShowData(comp?.id);
    }
  }, [comp?.id]);
  useEffect(() => {
    if (!isGenderSpecific) {
      setGender("anybody");
    }
  }, [isGenderSpecific]);
  const ShowData = async (id) => {
    const getData = await dispatch(residentialAction.getResidencialById(id));
    setId(getData?.id);
    setIsRecommended(getData?.isSubscribed || getData?.isPPCSubscribed);
    setFacilityType(getData?.facilityType);
    setownermail(getData?.ownerMail);
    setScholarship(getData?.offersScholarships);
    setSubscriptionDetails(getData?.subscriptionDetail);
    setIsPreSaleSubscribed(getData?.isPreSaleSubscribed);
    setName(getData?.name);
    setphoto(getData?.photos);
    setAbout(getData?.about);
    setPhone(getData?.phoneNumber);
    const formattedValue = formatPhoneNumber(getData?.phoneNumber);
    setMaskedPhone(formattedValue);
    setcommonAmenities(getData?.commonAmenities);
    setPersnalAmenities(getData?.personalAmenities);
    setUrl(getData?.webUrl ? getData?.webUrl : "");
    setVirtualUrl(getData?.virtualHouseLink ? getData?.virtualHouseLink : "");
    setStreet(getData?.address ? getData?.address : "");
    setGeocodingResult({
      cityName: getData?.city,
      stateLong: getData?.state,
    });
    setZipCode(getData?.zipCode);

    setCityName(getData?.city);
    setState(getData?.state);

    setCoordinates({
      lat: getData?.coordinates?.lat,
      lng: getData?.coordinates?.lng,
    });

    setRentRange({
      from: getData?.rentRange?.from,
      to: getData?.rentRange?.to,
    });
    setAllowableStay({
      from: getData?.allowableStay?.from,
      to: getData?.allowableStay?.to,
    });

    setSpecialization(getData?.specialization);

    setAdditionalServiceOffered(getData?.additionalServiceOffered);
    setIsGenderSpecific(getData?.genderSpecific);
    setGender(getData?.gender);
    setConditionTreated(getData?.conditionTreated);
    setMedicationTheray(getData?.medicationTherapy);
    setTreatmentApproaches(getData?.treatmentApproaches);
    setPreTreament(getData?.preTreatment);
    setTesting(getData?.testing);
    setSmokingPolicy(getData?.smokingPolicy);

    setIsAllowDogs(getData?.serviceDogs);
    setIsAllowCouples(getData?.couples);
    setIsAllowPregWomen(getData?.pregnantWomen);
    setIsAllowChildren(getData?.children);
    setTeamConsist(getData?.teamConsist);
    setInsurance(getData?.insurance);
    setselectedMaximumcapicity(getData?.maximumCapacity);
    setIsOtherLanguagesOfferedshowhide(getData?.isOfferedOtherlanguages);
    setCounts({
      clientCount: getData?.clients,
      clinicianCount: getData?.clinical,
    });
    const format = getData?.insuranceName?.map((obj) => {
      return {
        label: obj.charAt(0)?.toUpperCase() + obj?.slice(1),
        value: obj,
      };
    });

    setInsuranceName(format);
    setVerifyInsurance(getData && getData?.verifyInsurance);
    const formatotherlaguage = getData?.otherlanguages?.map((obj) => {
      return {
        label: obj.charAt(0)?.toUpperCase() + obj?.slice(1),
        value: obj,
      };
    });
    const outputArray = formatotherlaguage.map((item) => item.label);
    setselectotherLanguage(formatotherlaguage);
    setpayloadsetotherLanguageArray(outputArray);
    setVerifyInsurance(getData?.verifyInsurance);
    setSelectedLanguage({
      label: getData?.primaryLanguage,
      value: getData?.primaryLanguage,
    });
  };

  const servicemodalviewmodeallstaeIsclear = () => {
    setselectedMaximumcapicity("");
    setselectotherLanguage([]);
    setScholarship(false);
    setSelectedLanguage({
      label: "",
      value: "",
    });
    setSpecialization((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });

    setAdditionalServiceOffered((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });

    setConditionTreated((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });
    setMedicationTheray((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });

    setTreatmentApproaches((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });

    setPreTreament((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });

    setTesting((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });

    setSmokingPolicy((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });
    setGender("");
    setIsGenderSpecific(false);
    setIsAllowDogs(true);
    setIsAllowCouples(true);
    setIsAllowPregWomen(true);
    setIsAllowChildren(true);
  };
  const staffmodalviewmodeallstaeIsclear = () => {
    setTeamConsist((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });
    setCounts({ clientCount: "", clinicianCount: "" });
  };
  const amenitiesmobilemodalIsclear = () => {
    setPersnalAmenities((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });
    setcommonAmenities((prevState) => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });
  };

  const handleInsurance = (e) => {
    if (e?.some((item) => item.value === "NA")) {
      setInsuranceName([
        {
          value: "NA",
          label: "NA",
        },
      ]);
    } else {
      setInsuranceName(e);
    }
  };

  const insuranceodalviewmodeallstaeIsclear = () => {
    setInsurance(
      Object.fromEntries(Object.keys(insurance).map((key) => [key, false]))
    );

    setinsuranceNetwork([
      {
        value: "",
        label: "",
      },
    ]);
    setVerifyInsurance("");
    setInsuranceName([]);
  };

  useEffect(() => {
    if (datainfo?.setData && comp?.id) {
      setMedia(datainfo?.setData);
    }
  }, [datainfo?.setData]);

  useEffect(() => {
    if (datainfo?.setData) {
      setMedia(datainfo?.setData);
    }
  }, [datainfo?.setData]);

  const handleOnchangeCheckBox = (key, name) => {
    const updateState = (prevState, key) => ({
      ...prevState,
      [key]: !prevState[key],
    });
    switch (name) {
      case "specialization":
        setSpecialization((prevStaff) => updateState(prevStaff, key));
        break;
      case "additionalServiceOffered":
        setAdditionalServiceOffered((prevStaff) => updateState(prevStaff, key));
        break;
      case "conditionTreated":
        setConditionTreated((prevStaff) => updateState(prevStaff, key));
        break;
      case "medicationTheray":
        setMedicationTheray((prevStaff) => updateState(prevStaff, key));
        break;
      case "treatmentApproaches":
        setTreatmentApproaches((prevStaff) => updateState(prevStaff, key));
        break;
      case "preTreatment":
        setPreTreament((prevStaff) => updateState(prevStaff, key));
        break;
      case "testing":
        setTesting((prevStaff) => updateState(prevStaff, key));
        break;
      case "smokingPolicy":
        setSmokingPolicy((prevStaff) => updateState(prevStaff, key));
        break;
      case "teamConsist":
        setTeamConsist((prevStaff) => updateState(prevStaff, key));
        break;
      case "personalAmenities":
        setPersnalAmenities((prevStaff) => updateState(prevStaff, key));
        break;
      case "commonAmenities":
        setcommonAmenities((prevStaff) => updateState(prevStaff, key));
        break;
      case "insurance":
        setInsurance((prevStaff) => updateState(prevStaff, key));
        break;
      default:
        break;
    }
  };

  const handleChangelanguage = (selectedOption) => {
    const languageExists = payloadsetotherLanguageArray.filter(
      (lng) => lng.value === selectedOption.value
    );

    if (payloadsetotherLanguageArray.includes(selectedOption.value)) {
      setSelectedLanguage({
        label: "",
        value: "",
      });
      setShowErrorModal(true);
      setMissingFields("you already selected this language as other language ");

      return;
    }
    setSelectedLanguage(selectedOption);
    setpayloadsendSelectedLanguage(languageExists?.value);
  };
  const handleChangeOtherLanguage = (selectedOption) => {
    if (!selectedLanguage) {
      setShowErrorModal(true);
      setMissingFields("Select primary language first");
      return;
    }
    setselectotherLanguage(selectedOption);
    const otherLanguagesOptions = selectedOption?.map(({ value }) => value);

    setpayloadsetotherLanguageArray(otherLanguagesOptions);
  };

  const handleFileChange = () => {
    setMedia(datainfo);
  };

  const newMissingFields = [];
  const validationCheck = () => {
    const urlRegex = /^www|http|https\./;
    const urlRegexvalidurl = /^https?:\/\/www\./;

    if (name.trim() === "") {
      setShowErrorModal(true);
      newMissingFields.push("Residential facility name  is required");
    } else if (name.length < 6) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Residential facility name  should have atleast 6 characters"
      );
    } else if (name.length >= 255) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Residential facility name  should not be more than 255 Characters"
      );
    } else if (media.length === 0) {
      setShowErrorModal(true);
      newMissingFields.push("Please add Residential house photo");
    } else if (about.trim() === "") {
      setShowErrorModal(true);
      newMissingFields.push("About field is required");
    } else if (about.length > 5000) {
      setShowErrorModal(true);
      newMissingFields.push(
        "About field should have accepted less than 5000 characters"
      );
    } else if (phone?.length === 0 || phone === null) {
      setShowErrorModal(true);
      newMissingFields.push("Phone number is required");
    } else if (phone?.length < 10) {
      setShowErrorModal(true);
      newMissingFields.push("Phone number must have min 10 digits");
    } else if (phone?.length > 16) {
      setShowErrorModal(true);
      newMissingFields.push("Phone number can have max 15 digits");
    } else if (url.length === 0) {
      setShowErrorModal(true);
      newMissingFields.push("Website Link: website is required");
    } else if (
      (url && !urlRegexvalidurl.test(url)) ||
      !urlRegex.test(url) ||
      url === "https://www."
    ) {
      setShowErrorModal(true);
      newMissingFields.push("Website Link: Please enter a valid URL");
    } else if (!virtualUrl === "https://www." && !urlRegex.test(virtualUrl)) {
      setShowErrorModal(true);
      newMissingFields.push("Virtual House Link: Please enter a valid URL");
    } else if (street.trim() === "") {
      setShowErrorModal(true);
      newMissingFields.push("Street Name is required");
    } else if (!"geocodingResult") {
      setShowErrorModal(true);
      newMissingFields.push("City and state is required");
    } else if (state.trim() === "") {
      setShowErrorModal(true);
      newMissingFields.push("City and state is required");
    } else if (rentRange.from <= 0) {
      setShowErrorModal(true);
      newMissingFields.push("Minimum price should be greater than 0");
    } else if (rentRange.to <= 0) {
      setShowErrorModal(true);
      newMissingFields.push("Maximum price should be greater than 0");
    } else if (rentRange.from > 999999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Minimum price must be less than or equal to 999999"
      );
    } else if (rentRange.to > 999999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Maximum price must be less than or equal to 999999"
      );
    } else if (
      rentRange.from &&
      rentRange.to &&
      rentRange.from - rentRange.to >= 0
    ) {
      setShowErrorModal(true);
      newMissingFields.push("Maximum price must be greater than minimum price");
    } else if (allowableStay.from <= 0) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Please enter number of staying day  From greater than 0"
      );
    } else if (allowableStay.to <= 0) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Please enter number of staying day To greater than 0"
      );
    } else if (allowableStay.from - allowableStay.to > 0) {
      setShowErrorModal(true);
      newMissingFields.push(
        "From allowableStay range must be less than or equal to To rent range"
      );
    } else if (allowableStay.from > 999999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Minimum allowable staying days must be less than or equal to 999999"
      );
    } else if (allowableStay.to > 999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Maximum allowable Staying days must be less than or equal to 999"
      );
    } else if (!Object.values(specialization).some((value) => value === true)) {
      setShowErrorModal(true);
      newMissingFields.push("At least one specialization must be selected");
    } else if (
      selectedMaximumcapicity === "" ||
      selectedMaximumcapicity === null
    ) {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the maximum capicity");
    } else if (parseInt(selectedMaximumcapicity) <= 0) {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the maximum capicity more than 0");
    } else if (selectedMaximumcapicity > 999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Maximum capicity must be less than or equal to 999"
      );
    } else if (selectedLanguage === "" || selectedLanguage?.value === "") {
      setShowErrorModal(true);
      newMissingFields.push("please select the primary language");
    } else if (
      isOtherLanguagesOfferedshowhide &&
      selectotherLanguage.length === 0
    ) {
      setShowErrorModal(true);
      newMissingFields.push("Please select at least one other language.");
    } else if (isGenderSpecific && gender === "anybody") {
      setShowErrorModal(true);
      newMissingFields.push("Invalid gender type");
    } else if (counts.clientCount === "" && counts.clinicianCount === "") {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the count of client and clinician");
    } else if (counts.clientCount === "") {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the count of client");
    } else if (counts.clientCount <= 0) {
      setShowErrorModal(true);
      newMissingFields.push("Count of client should be greater than 0");
    } else if (counts.clientCount > 999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Minimum count of client must be less than or equal to 999"
      );
    } else if (counts.clinicianCount === "") {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the count of clinician");
    } else if (counts.clinicianCount <= 0) {
      setShowErrorModal(true);
      newMissingFields.push("Count of clinician should be greater than 0");
    } else if (counts.clinicianCount > 999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Maximum  count of clinician  must be less than or equal to 999"
      );
    } else if (!Object.values(insurance).some((value) => value === true)) {
      setShowErrorModal(true);
      newMissingFields.push("At least one insurance  option must be selected");
    } else if (!Object.values(insurance).some((value) => value === true)) {
      setShowErrorModal(true);
      newMissingFields.push("Please select insurance provider name");
    } else if (insuranceNetwork?.length === 0 || insuranceName.length === 0) {
      setShowErrorModal(true);
      newMissingFields.push("Please select network insurance name");
    } else if (verifyInsurance && !urlRegex.test(verifyInsurance)) {
      setShowErrorModal(true);
      newMissingFields.push("Invalid Verify Insurance Link ");
    } else {
    }

    if (newMissingFields.length > 0) {
      setTimeout(() => {
        setShowErrorModal(false);
      }, 4000);
      setMissingFields(...newMissingFields);
      return false;
    }
    return true;
  };
  const sevicemodalvalidationformobile = () => {
    if (!Object.values(specialization).some((value) => value === true)) {
      setShowErrorModal(true);
      newMissingFields.push("At least one specialization must be selected");
    } else if (
      selectedMaximumcapicity === "" ||
      selectedMaximumcapicity === null
    ) {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the maximum capicity");
    } else if (parseInt(selectedMaximumcapicity) <= 0) {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the maximum capicity more than 0");
    } else if (selectedMaximumcapicity > 999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Maximum capicity must be less than or equal to 999"
      );
    } else if (selectedLanguage === "" || selectedLanguage?.value === "") {
      setShowErrorModal(true);
      newMissingFields.push("please select the primary language");
    } else if (
      isOtherLanguagesOfferedshowhide &&
      selectotherLanguage.length === 0
    ) {
      setShowErrorModal(true);
      newMissingFields.push("Please select at least one other language.");
    } else if (isGenderSpecific && gender === "anybody") {
      setShowErrorModal(true);
      newMissingFields.push("Invalid gender type");
    } else {
    }
    if (newMissingFields.length > 0) {
      setTimeout(() => {
        setShowErrorModal(false);
      }, 4000);
      setMissingFields(...newMissingFields);
      return false;
    }
    return true;
  };

  const staffmodalvalidationformobile = () => {
    if (counts.clientCount === "" && counts.clinicianCount === "") {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the count of client and clinician");
    } else if (counts.clientCount === "") {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the count of client");
    } else if (counts.clientCount <= 0) {
      setShowErrorModal(true);
      newMissingFields.push("Count of client should be greater than 0");
    } else if (counts.clientCount > 999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Minimum count of client must be less than or equal to 999"
      );
    } else if (counts.clinicianCount === "") {
      setShowErrorModal(true);
      newMissingFields.push("Please enter the count of clinician");
    } else if (counts.clinicianCount <= 0) {
      setShowErrorModal(true);
      newMissingFields.push("Count of clinician should be greater than 0");
    } else if (counts.clinicianCount > 999) {
      setShowErrorModal(true);
      newMissingFields.push(
        "Maximum  count of clinician  must be less than or equal to 999"
      );
    } else {
    }
    if (newMissingFields.length > 0) {
      setTimeout(() => {
        setShowErrorModal(false);
      }, 4000);
      setMissingFields(...newMissingFields);
      return false;
    }
    return true;
  };
  const insurancemodalvalidationformobile = () => {
    const urlRegex = /^www|http|https\./;
    if (!Object.values(insurance).some((value) => value === true)) {
      setShowErrorModal(true);
      newMissingFields.push("At least one insurance  option must be selected");
    } else if (!Object.values(insurance).some((value) => value === true)) {
      setShowErrorModal(true);
      newMissingFields.push("Please select insurance provider name");
    } else if (insuranceNetwork?.length === 0 || insuranceName.length === 0) {
      setShowErrorModal(true);
      newMissingFields.push("Please select network insurance name");
    } else if (verifyInsurance && !urlRegex.test(verifyInsurance)) {
      setShowErrorModal(true);
      newMissingFields.push("Invalid Verify Insurance Link ");
    } else {
    }
    if (newMissingFields.length > 0) {
      setTimeout(() => {
        setShowErrorModal(false);
      }, 4000);
      setMissingFields(...newMissingFields);
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validationCheck();

    if (isValid) {
      try {
        const data = {
          name: name,
          media: media,
          about: about,
          phoneNumber: phone,
          webUrl: url,
          virtualHouseLink: virtualUrl,
          address: street,
          state: state,
          city: cityName?.split(",")[0]?.trim(),
          zipCode: zipCode,
          ownerMail: ownermail,
          coordinates: coordinates,
          rentRange: rentRange,
          allowableStay: allowableStay,
          specialization: specialization,
          commonAmenities: commonAmenities,
          personalAmenities: personalAmenities,
          additionalServiceOffered: additionalServiceOffered,
          genderSpecific: isGenderSpecific,
          gender: gender ? gender : "anybody",
          conditionTreated: conditionTreated,
          medicationTherapy: medicationTheray,
          treatmentApproaches: treatmentApproaches,
          preTreatment: preTreatment,
          testing: testing,
          smokingPolicy: smokingPolicy,
          offersScholarships: isScholarship,
          serviceDogs: isAllowDogs,
          couples: isAllowCouples,
          pregnantWomen: isAllowPregWomen,
          children: isAllowChildren,
          teamConsist: teamConsist,
          insurance: insurance,
          insuranceName: insuranceNetwork,
          verifyInsurance: verifyInsurance,
          maximumCapacity: parseInt(selectedMaximumcapicity),
          clients: parseInt(counts?.clientCount),
          clinical: parseInt(counts?.clinicianCount),
          primaryLanguage: selectedLanguage?.value,
          isOfferedOtherlanguages: isOtherLanguagesOfferedshowhide,
          otherlanguages: payloadsetotherLanguageArray,
          isVerifyinsurance: true,
          recommendId: selectedRecommendedID?.map((item) => item?.value),
        };

        showLoader("Uploading media files..");
        const filesWithIds = media.filter((item) => item.id);
        const filteredImageData = media.filter((item) => !item.id);
        let uploadedFilesCount = 0;
        // Upload files without IDs
        const fileUploadPromises = filteredImageData.map(
          async (file, index) => {
            const formData = new FormData();
            formData.append(`file${index}`, file);

            try {
              const response = await httpClient.post(
                SERVER_APIS.MediaUpload,
                formData
              );
              uploadedFilesCount++;
              showLoader(`Uploading media files ${uploadedFilesCount}`);
              setResonspnse(response);
              return response.data;
            } catch (error) {
              throw new Error(
                `Failed to upload file ${index + 1}: ${error.message}`
              );
            }
          }
        );

        const uploadedFilesData = await Promise.all(fileUploadPromises);

        const resMedia = uploadedFilesData.map((uploadResult, index) => {
          if (
            !uploadResult.errors &&
            uploadResult.data &&
            uploadResult.data.length > 0
          ) {
            const imageData = uploadResult.data[0];
            return {
              position: index,
              id: imageData.id,
            };
          } else {
            console.error(
              `Error occurred while processing upload result ${index + 1}:`,
              uploadResult.errors
            );
            return null;
          }
        });
        const filteredResMedia = resMedia.filter((media) => media !== null);

        const combinedMedia = [...filesWithIds, ...resMedia];
        // Update data with media information
        const updatedData = {
          ...data,
          media: combinedMedia,
        };

        if (comp?.id) {
          // update APi
          showLoader("Updated residential facility info....");
          await dispatch(
            residentialAction.editResidential(comp?.id, updatedData)
          );
          if (!isPreSaleSubscribed && subscriptionDetails === null) {
            context.setResidential("addSubscriptionPage");
            setCurrentStep(currentstep + 1);
            dispatch(residentialAction.isChangeTrue(true));
            hideLoader("");
          } else {
            history.push("/profile");
            hideLoader("");
          }
        } else {
          if (response === "") {
            showLoader("Add residential center....");
            const action = await dispatch(
              residentialAction.addResidential(updatedData)
            );
            context.setResidential("addSubscriptionPage");
            if (action.status === 200) {
              setCurrentStep(currentstep + 1);
              dispatch(residentialAction.isChangeTrue(true));
              // setIsChange(true)
              hideLoader("");
            }
          }
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    } else {
      console.log("Validation failed. Please check the form.");
    }
  };
  const handleAddOtherProvider = () => {
    if (otherProvider.trim() !== "") {
      const newOption = {
        value: `Others:${otherProvider}`,
        label: `Others:${otherProvider}`,
      };
      setInsuranceName((prevOptions) => {
        return prevOptions
          .filter((option) => option.value !== "others")
          .concat(newOption);
      });
      setothersVisible(true);
      setOtherProvider("");
      //removing others from dropdown
      const updatedInsuranceProvider = insuranceProvider.filter(
        (option) => option.value !== "others"
      );
      setinsuranceProvider(updatedInsuranceProvider);
    }
  };

  const handleOtherProviderChange = (event) => {
    setOtherProvider(event.target.value);
  };
  useEffect(() => {
    if (insuranceName?.length === 0 && othersVisible === true) {
      setothersVisible(false);

      setinsuranceProvider((prevProvider) => [
        ...prevProvider,
        { value: "others", label: "Others" },
      ]);
    }
    if (insuranceName?.length > 0) {
      const insuranceNetworkname = insuranceName?.filter(
        (option) => option?.value !== "others"
      );
      setinsuranceNetwork(insuranceNetworkname?.map((item) => item.label));
    }
    if (!insuranceName.some((option) => option.value?.startsWith("Others:"))) {
      if (!insuranceProvider.some((option) => option.value === "others")) {
        setinsuranceProvider((prevProvider) => [
          ...prevProvider,
          { value: "others", label: "Others" },
        ]);
        setothersVisible(false);
      }
    } else {
      const updatedInsuranceProvider = insuranceProvider.filter(
        (option) => option.value !== "others"
      );
      setinsuranceProvider(updatedInsuranceProvider);
    }
  }, [insuranceName]);

  const formatPhoneNumber = (input) => {
    const phoneNumber = input?.replace(/\D/g, "");

    if (!phoneNumber) return "";

    let formattedPhoneNumber = `(${phoneNumber.slice(0, 3)})`;

    if (input.length < formattedPhoneNumber.length) {
      formattedPhoneNumber = formattedPhoneNumber.slice(0, -1);
      return formattedPhoneNumber;
    }

    if (phoneNumber.length > 3) {
      formattedPhoneNumber += `-${phoneNumber.slice(3, 6)}`;
    }
    if (phoneNumber.length > 6) {
      formattedPhoneNumber += `-${phoneNumber.slice(6, 10)}`;
    }
    if (phoneNumber.length > 10) {
      formattedPhoneNumber += `-${phoneNumber.slice(10, 15)}`;
    }

    return formattedPhoneNumber;
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    const formattedValue = formatPhoneNumber(input);
    setMaskedPhone(formattedValue);
    setPhone(numericValue);
  };

  return (
    <>
      {currentstep == 0 && !location?.state?.state?.isUpgrade ? (
        <>
          <section className="back">
            <div className="container">
              <a role="button">
                <img
                  src={backImgBlack}
                  alt=""
                  onClick={() => history.push("/profile")}
                />
              </a>
            </div>
          </section>
          <section className="listing sober-living-listing-wrapper pt-4 mt-1">
            <div className="container">
              <div className="listing-header test mb-0">
                <h3 className="main-title mb-0">
                  {comp?.id ? "EDIT" : "ADD"} Residential Treatment Center{" "}
                  <span className="step-badge">STEP 1</span>
                </h3>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="taste-recovery-form add-sobar-living">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="listing-sub-header test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2">
                        <h4 className="main-title mb-0">About</h4>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Name <span className="mandatory">*</span>
                        </label>
                        <input
                          name="name"
                          maxLength="255"
                          className="form-control"
                          placeholder="Enter name of facility "
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <FileUploadComponent
                      handleFileChange={handleFileChange}
                      photos={comp?.id ? photo : media}
                    />

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          About <span className="mandatory">*</span>
                        </label>
                        <textarea
                          name="about"
                          maxLength="5000"
                          rows="5"
                          className="form-control"
                          placeholder="Write something about this facility"
                          value={about}
                          onChange={(e) => {
                            const enteredText = e.target.value;
                            if (
                              /^[A-Za-z\s@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(
                                enteredText
                              ) ||
                              enteredText === ""
                            ) {
                              setAbout(enteredText);
                            }
                          }}
                        ></textarea>
                        <span className="note">{about?.length}/5000</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Phone <span className="mandatory">*</span>
                        </label>
                        <input
                          name="phoneNumber"
                          type="text"
                          className="form-control"
                          value={maskedPhone}
                          onChange={handleInputChange}
                          placeholder="Enter a valid phone number"
                          onWheel={(e) => e.target.blur()} // Prevent mouse wheel from changing the value
                          onKeyDown={(e) => {
                            if (
                              e.key === "ArrowUp" ||
                              e.key === "ArrowDown"
                            ) {
                              e.preventDefault(); // Prevent arrow keys from changing the value
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Website Link <span className="mandatory">*</span>
                        </label>
                        <input
                          name="webUrl"
                          // type="url"
                          className="form-control"
                          placeholder="Enter"
                          maxLength="200"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          onPaste={handlePaste}
                        />
                        <span className="note">{url.length}/200</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Virtual House Link (Pro) </label>
                        <input
                          name="virtualUrl"
                          className="form-control"
                          placeholder="Enter a valid virtual house link"
                          maxLength="200"
                          value={virtualUrl}
                          onChange={(e) => setVirtualUrl(e.target.value)}
                        />
                        <span className="note">{virtualUrl?.length}/200</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Street Address <span className="mandatory">*</span>
                        </label>
                        <input
                          name="address"
                          className="form-control"
                          placeholder="Street"
                          value={street}
                          onChange={(e) => setStreet(e.target.value)}
                          maxLength="100"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          City &amp; State <span className="mandatory">*</span>
                        </label>
                        <LocationSearchInput
                          placeholder="City & State"
                          dropdownStyle="city-dropdown"
                          fetchStateZip={true}
                          value={
                            geocodingResult && geocodingResult.cityName
                              ? `${geocodingResult.cityName} ${
                                  geocodingResult.stateLong
                                    ? `, ${geocodingResult.stateLong}`
                                    : ""
                                }`
                              : ""
                          }
                          onCitySelect={(res) => {
                            setCoordinates({ lat: res.lat, lng: res.lng });
                            setCityName(res.cityName);

                            if (res.state) {
                              setState(res?.state);
                              setZipCode(res?.zipCode);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="map-view form-group mb-4 mb-md-5">
                        <label>
                          Street Address <span className="mandatory">*</span>
                        </label>
                        <GoogleMapComponent
                          className="details-map"
                          markers={[coordinates || { lat: 0, lng: 0 }]}
                          newCenter={{
                            lat: coordinates ? coordinates.lat : 0,
                            lng: coordinates ? coordinates.lng : 0,
                          }}
                          handleClick={(e) => {
                            setCoordinates({ lat: e.lat, lng: e.lng });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Range Of Cash Pay <span className="mandatory">*</span>
                        </label>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group mb-0">
                              <label className="small-label">From</label>
                              {rentRange?.from != "" && (
                                <span className="dollor">$</span>
                              )}
                              <input
                                name="rentRange.from"
                                type="number"
                                min="0"
                                className="form-control price"
                                placeholder="Min. Price"
                                value={rentRange?.from}
                                onChange={(e) => {
                                  setRentRange({
                                    ...rentRange,
                                    from: e.target.value,
                                  });
                                }}
                                onWheel={(e) => e.target.blur()} // Prevent mouse wheel from changing the value
                                onKeyDown={(e) => {
                                  if (
                                    e.key === "ArrowUp" ||
                                    e.key === "ArrowDown"
                                  ) {
                                    e.preventDefault(); // Prevent arrow keys from changing the value
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group mb-0">
                              <label className="small-label">To</label>
                              {rentRange.to != "" && (
                                <span className="dollor">$</span>
                              )}
                              <input
                                name="rentRange.to"
                                type="number"
                                min="0"
                                className="form-control  price"
                                placeholder="Max. Price"
                                value={rentRange.to}
                                onChange={(e) => {
                                  setRentRange({
                                    ...rentRange,
                                    to: e.target.value,
                                  });
                                }}
                                onWheel={(e) => e.target.blur()} // Prevent mouse wheel from changing the value
                                onKeyDown={(e) => {
                                  if (
                                    e.key === "ArrowUp" ||
                                    e.key === "ArrowDown"
                                  ) {
                                    e.preventDefault(); // Prevent arrow keys from changing the value
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Range Of Term Of Stay{" "}
                          <span className="mandatory">*</span>
                        </label>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group mb-0">
                              <label className="small-label">From</label>
                              <input
                                name="dateRange.from"
                                type="number"
                                className="form-control"
                                placeholder="Days"
                                value={allowableStay?.from}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (parseInt(value) > 999) {
                                    value = "999";
                                  }
                                  setAllowableStay({
                                    ...allowableStay,
                                    from: value,
                                  });
                                }}
                                onWheel={(e) => e.target.blur()} // Prevent mouse wheel from changing the value
                                onKeyDown={(e) => {
                                  if (
                                    e.key === "ArrowUp" ||
                                    e.key === "ArrowDown"
                                  ) {
                                    e.preventDefault(); // Prevent arrow keys from changing the value
                                  }
                                }}
                              />
                              {allowableStay.from &&
                                allowableStay.from != "" &&
                                (allowableStay.from === "1" ? (
                                  <span className="days">Day</span>
                                ) : (
                                  <span className="days">Days</span>
                                ))}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group mb-0">
                              <label className="small-label">To</label>
                              <input
                                name="dateRange.to"
                                type="number"
                                className="form-control"
                                placeholder="Days"
                                value={allowableStay?.to}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (parseInt(value) > 999) {
                                    value = "999";
                                  }
                                  setAllowableStay({
                                    ...allowableStay,
                                    to: value,
                                  });
                                }}
                                onWheel={(e) => e.target.blur()} // Prevent mouse wheel from changing the value
                                onKeyDown={(e) => {
                                  if (
                                    e.key === "ArrowUp" ||
                                    e.key === "ArrowDown"
                                  ) {
                                    e.preventDefault(); // Prevent arrow keys from changing the value
                                  }
                                }}
                              />
                              {allowableStay.to &&
                                allowableStay.to != "" &&
                                (allowableStay.to === "1" ? (
                                  <span className="days">Day</span>
                                ) : (
                                  <span className="days">Days</span>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="listing-sub-header form-mobile-tab test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2"
                        onClick={toggleMobileTabContent}
                      >
                        <h4 className="main-title mb-0">Services</h4>
                      </div>
                      <div
                        className={`form-mobile-tab-content ${
                          isMobileTabContentVisibleforservices ? "show" : ""
                        }`}
                      >
                        <div className="form-tab-content-header d-block d-md-none">
                          <div className="header-actions mb-4 d-flex align-items-center justify-content-between">
                            <div className="header-actions-left">
                              <a role="button">
                                <img
                                  src={backImgBlack}
                                  alt=""
                                  onClick={() =>
                                    setIsMobileTabContentVisibleforservices(
                                      false
                                    )
                                  }
                                />
                              </a>
                            </div>
                            <div className="header-actions-right d-flex align-items-center">
                              <button
                                className="btn apply-btn"
                                onClick={servicemodalviewmodeallstaeIsclear}
                                type="button"
                              >
                                CLEAR
                              </button>
                              <button
                                className="btn apply-btn"
                                onClick={() => {
                                  const isValidMobile =
                                    sevicemodalvalidationformobile();
                                  if (isValidMobile) {
                                    setIsMobileTabContentVisibleforservices(
                                      false
                                    );
                                  }
                                }}
                                type="button"
                              >
                                APPLY
                              </button>
                            </div>
                          </div>
                          <div className="header-title mb-4 pb-2">
                            <h4 className="main-title mb-0">SERVICES</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4">
                                What Do You Specialize In?
                                <span className="mandatory">*</span>
                              </label>
                              <div className="dflex-form border-0">
                                {Object.keys(specialization)?.map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`specialization${key}`}
                                          name={`specialization.${key}`}
                                          checked={specialization[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "specialization"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`specialization${key}`}
                                        >
                                          {formatCamelCaseToReadablesubstutanceorder(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                What Is Your Maximum Capacity?{" "}
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                name="selectedMaximumcapicity"
                                className="form-control"
                                placeholder="Enter maximum capacity"
                                min="0"
                                type="number"
                                value={selectedMaximumcapicity}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (parseInt(value) > 999) {
                                    value = "999";
                                  }
                                  setselectedMaximumcapicity(value);
                                }}
                                onWheel={(e) => e.target.blur()} // Prevent mouse wheel from changing the value
                                onKeyDown={(e) => {
                                  if (
                                    e.key === "ArrowUp" ||
                                    e.key === "ArrowDown"
                                  ) {
                                    e.preventDefault(); // Prevent arrow keys from changing the value
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="languageSelect">
                                What Is Your Primary Language?{" "}
                                <span className="mandatory">*</span>
                              </label>
                              <div className="form-group">
                                <Select
                                  id="languageSelect"
                                  value={selectedLanguage}
                                  onChange={handleChangelanguage}
                                  styles={colourStyles}
                                  options={languages}
                                  placeholder="Select a primary language"
                                  isSearchable
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="mb-4">
                                Other Languages Offered?
                                <span className="mandatory">*</span>
                              </label>
                              <div className="dflex-form justify-content-start border-0">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="isOtherLanguagesOfferedshowhideYes"
                                    className="custom-control-input"
                                    onChange={() =>
                                      setIsOtherLanguagesOfferedshowhide(true)
                                    }
                                    checked={isOtherLanguagesOfferedshowhide}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="isOtherLanguagesOfferedshowhideYes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="isOtherLanguagesOfferedshowhideNo"
                                    className="custom-control-input"
                                    onChange={() => {
                                      setIsOtherLanguagesOfferedshowhide(false);
                                      setselectotherLanguage([]);
                                    }}
                                    checked={!isOtherLanguagesOfferedshowhide}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="isOtherLanguagesOfferedshowhideNo"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isOtherLanguagesOfferedshowhide && (
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="languageSelect">
                                  Other Languages?{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Select
                                  id="languageSelect"
                                  value={selectotherLanguage}
                                  onChange={handleChangeOtherLanguage}
                                  options={languages.filter(
                                    (language) =>
                                      language?.value !==
                                      selectedLanguage?.value
                                  )}
                                  placeholder="Select other languages"
                                  isSearchable
                                  styles={colourStyles}
                                  isMulti
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4">
                                What Are The Additional Services Offered?
                              </label>
                              <div className="dflex-form border-0">
                                {Object.keys(additionalServiceOffered).map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        key={index}
                                        className="custom-control custom-checkbox"
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          name={`additionalServiceOffered.${key}`}
                                          id={`additionalServiceOffered${key}`}
                                          checked={
                                            additionalServiceOffered[key]
                                          }
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "additionalServiceOffered"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`additionalServiceOffered${key}`}
                                        >
                                          {formatCamelCaseToReadableadditionaservicesforesidentailResidential(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="mb-4">
                                Is Your Program Gender-Specific
                              </label>
                              <div className="dflex-form justify-content-start border-0">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="genderSpecificYes"
                                    className="custom-control-input"
                                    onChange={() => setIsGenderSpecific(true)}
                                    checked={isGenderSpecific}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="genderSpecificYes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="genderSpecificNo"
                                    className="custom-control-input"
                                    onChange={() => setIsGenderSpecific(false)}
                                    checked={!isGenderSpecific}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="genderSpecificNo"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isGenderSpecific && (
                            <div className="col-md-12">
                              <div className="form-group mb-0">
                                <label className="mb-4">
                                  Gender Type{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="dflex-form justify-content-start border-0">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="male"
                                      className="custom-control-input"
                                      checked={gender === "men"}
                                      onChange={() => setGender("men")}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="male"
                                    >
                                      Male
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="female"
                                      className="custom-control-input"
                                      checked={gender === "women"}
                                      onChange={() => setGender("women")}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="female"
                                    >
                                      Female
                                    </label>
                                  </div>
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="LGBTQ"
                                      className="custom-control-input"
                                      checked={gender === "lgbtq"}
                                      onChange={() => setGender("lgbtq")}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="LGBTQ"
                                    >
                                      LGBTQ+
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4">
                                What Are The Conditions Treated?
                              </label>
                              <div className="dflex-form border-0">
                                {Object.keys(conditionTreated)?.map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        key={index}
                                        className="custom-control custom-checkbox"
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`conditionTreated${key}`}
                                          name={`conditionTreated.${key}`}
                                          checked={conditionTreated[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "conditionTreated"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`conditionTreated${key}`}
                                        >
                                          {formatCamelCaseToReadabcodtiontreated(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4">Medication Therapy</label>
                              <div className="dflex-form border-0">
                                {Object?.keys(medicationTheray)?.map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`medicationTheray${key}`}
                                          name={`medicationTheray.${key}`}
                                          checked={medicationTheray[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "medicationTheray"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`medicationTheray${key}`}
                                        >
                                          {formatCamelCaseToReadablemedication(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4">
                                Treatment Approaches
                              </label>
                              <div className="dflex-form border-0">
                                {Object.keys(treatmentApproaches)?.map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`treatmentApproaches${key}`}
                                          name={`treatmentApproaches.${key}`}
                                          checked={treatmentApproaches[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "treatmentApproaches"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`treatmentApproaches${key}`}
                                        >
                                          {formatCamelCaseToReadabletreatmentapproaches(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4">
                                Assessment/ Pre-treatment
                              </label>
                              <div className="dflex-form border-0">
                                {Object.keys(preTreatment).map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`preTreatment${key}`}
                                          name={`preTreatment.${key}`}
                                          checked={preTreatment[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "preTreatment"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`preTreatment${key}`}
                                        >
                                          {formatCamelCaseToReadabletreatmentapproaches(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4">Testing</label>
                              <div className="dflex-form border-0">
                                {Object.keys(testing).map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`testing${key}`}
                                          name={`testing.${key}`}
                                          checked={testing[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "testing"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`testing${key}`}
                                        >
                                          {formatTestKeyToReadableiopphp(key)}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4">
                                Facility Smoking Policy
                              </label>
                              <div className="dflex-form border-0">
                                {Object.keys(smokingPolicy).map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`smokingPolicy${key}`}
                                          name={`smokingPolicy.${key}`}
                                          checked={smokingPolicy[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "smokingPolicy"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`smokingPolicy${key}`}
                                        >
                                          {formatString(key)}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="mb-4">
                                Do You Allow Service Dogs?
                              </label>
                              <div className="dflex-form justify-content-start border-0">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="dogsYes"
                                    className="custom-control-input"
                                    onChange={() => setIsAllowDogs(true)}
                                    checked={isAllowDogs}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="dogsYes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="dogsNo"
                                    className="custom-control-input"
                                    onChange={() => setIsAllowDogs(false)}
                                    checked={!isAllowDogs}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="dogsNo"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="mb-4">
                                Do You Allow Couples?
                              </label>
                              <div className="dflex-form justify-content-start border-0">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="coupleYes"
                                    className="custom-control-input"
                                    onChange={() => setIsAllowCouples(true)}
                                    checked={isAllowCouples}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coupleYes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="coupleNo"
                                    className="custom-control-input"
                                    onChange={() => setIsAllowCouples(false)}
                                    checked={!isAllowCouples}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="coupleNo"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="mb-4">
                                Do You Allow Pregnant Women?
                              </label>
                              <div className="dflex-form justify-content-start border-0">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="pregnantwomenYes"
                                    className="custom-control-input"
                                    onChange={() => setIsAllowPregWomen(true)}
                                    checked={isAllowPregWomen}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="pregnantwomenYes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="pregnantwomenNo"
                                    className="custom-control-input"
                                    onChange={() => setIsAllowPregWomen(false)}
                                    checked={!isAllowPregWomen}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="pregnantwomenNo"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="mb-4">
                                Do You Allow Children?
                              </label>
                              <div className="dflex-form justify-content-start border-0">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="childrenYes"
                                    className="custom-control-input"
                                    onChange={() => setIsAllowChildren(true)}
                                    checked={isAllowChildren}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="childrenYes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="childrenNo"
                                    className="custom-control-input"
                                    onChange={() => setIsAllowChildren(false)}
                                    checked={!isAllowChildren}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="childrenNo"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="mb-4">
                                Do You Offer Scholarships ?
                              </label>
                              <div className="dflex-form justify-content-start border-0">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="isScholarshipYes"
                                    className="custom-control-input"
                                    onChange={() => setScholarship(true)}
                                    checked={isScholarship}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="isScholarshipYes"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="isScholarshipNo"
                                    className="custom-control-input"
                                    onChange={() => setScholarship(false)}
                                    checked={!isScholarship}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="isScholarshipNo"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="listing-sub-header form-mobile-tab test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2"
                        onClick={toggleMobileTabContentforAmenities}
                      >
                        <h4 className="main-title mb-0">Amenities</h4>
                      </div>
                      <div
                        className={`form-mobile-tab-content ${
                          openModalAmenities ? "show" : ""
                        }`}
                      >
                        <div className="form-tab-content-header d-block d-md-none">
                          <div className="header-actions mb-4 d-flex align-items-center justify-content-between">
                            <div className="header-actions-left">
                              <a role="button">
                                <img
                                  src={backImgBlack}
                                  alt=""
                                  onClick={() => setModalAmenities(false)}
                                />
                              </a>
                            </div>
                            <div className="header-actions-right d-flex align-items-center">
                              <button
                                type="button"
                                className="btn clear-btn"
                                onClick={amenitiesmobilemodalIsclear}
                              >
                                CLEAR
                              </button>
                              <button
                                type="button"
                                className="btn apply-btn"
                                onClick={() => {
                                  setModalAmenities(false);
                                }}
                              >
                                APPLY
                              </button>
                            </div>
                          </div>
                          <div className="header-title mb-4 pb-2">
                            <h4 className="main-title mb-0">Amenities</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4 pb-md-2">
                                Personal Amenities{" "}
                              </label>
                              <div className="dflex-form border-0">
                                {Object.keys(personalAmenities)
                                  .sort()
                                  ?.map((key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          className="custom-control-input"
                                          type="checkbox"
                                          id={`personalAmenities${key}`}
                                          name={`personalAmenities.${key}`}
                                          checked={personalAmenities[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "personalAmenities"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`personalAmenities${key}`}
                                        >
                                          {formatCamelCaseToReadabpersonalAmenities(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4 pb-md-2">
                                Common Amenities{" "}
                              </label>
                              <div className="dflex-form border-0">
                                {Object.keys(commonAmenities)
                                  .sort()
                                  ?.map((key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          className="custom-control-input"
                                          type="checkbox"
                                          id={`commonAmenities${key}`}
                                          name={`commonAmenities.${key}`}
                                          checked={commonAmenities[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "commonAmenities"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`commonAmenities${key}`}
                                        >
                                          {formatCamelCaseToReadabcommonAmenities(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div
                        className="listing-sub-header form-mobile-tab test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2"
                        onClick={toggleMobileTabContentforstaff}
                      >
                        <h4 className="main-title mb-0">Staff Associated</h4>
                      </div>
                      <div
                        className={`form-mobile-tab-content ${
                          isMobileTabContentVisibleforstaff ? "show" : ""
                        }`}
                      >
                        <div className="form-tab-content-header d-block d-md-none">
                          <div className="header-actions mb-4 d-flex align-items-center justify-content-between">
                            <div className="header-actions-left">
                              <a role="button">
                                <img
                                  src={backImgBlack}
                                  alt=""
                                  onClick={() =>
                                    setIsMobileTabContentVisibleforstaff(false)
                                  }
                                />
                              </a>
                            </div>
                            <div className="header-actions-right d-flex align-items-center">
                              <button
                                className="btn clear-btn"
                                onClick={staffmodalviewmodeallstaeIsclear}
                                type="button"
                              >
                                CLEAR
                              </button>
                              <button
                                className="btn apply-btn"
                                onClick={() => {
                                  const isValidMobile =
                                    staffmodalvalidationformobile();
                                  if (isValidMobile) {
                                    setIsMobileTabContentVisibleforstaff(false);
                                  }
                                }}
                                type="button"
                              >
                                APPLY
                              </button>
                            </div>
                          </div>
                          <div className="header-title mb-4 pb-2">
                            <h4 className="main-title mb-0">
                              STAFF ASSOCIATED
                            </h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4 pb-md-2">
                                Who Does Your Team Consists Of?
                              </label>
                              <div className="dflex-form  border-0">
                                {Object.keys(teamConsist)?.map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          className="custom-control-input"
                                          type="checkbox"
                                          id={`teamConsist${key}`}
                                          name={`teamConsist.${key}`}
                                          checked={teamConsist[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "teamConsist"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`teamConsist${key}`}
                                        >
                                          {formatCamelCaseToReadableteamconsists(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                            <div className="row pb-4">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    What Is The Client To Clinical Team Ratio?{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <div className="row">
                                    <div className="col-6">
                                      <div className="form-group mb-0">
                                        <label className="small-label">
                                          Clients
                                        </label>

                                        <input
                                          name="counts.clientCount"
                                          type="number"
                                          className="form-control"
                                          placeholder="Client "
                                          value={counts?.clientCount}
                                          min="0"
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            if (parseInt(value) > 999) {
                                              value = "999";
                                            }
                                            setCounts({
                                              ...counts,
                                              clientCount: value,
                                            });
                                          }}
                                          onWheel={(e) => e.target.blur()} // Prevent mouse wheel from changing the value
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "ArrowUp" ||
                                              e.key === "ArrowDown"
                                            ) {
                                              e.preventDefault(); // Prevent arrow keys from changing the value
                                            }
                                          }}
                                        />
                                        {counts?.clientCount &&
                                          counts?.clientCount != "" &&
                                          (counts?.clientCount === "1" ? (
                                            <span className="days">Client</span>
                                          ) : (
                                            <span className="days">
                                              Clients
                                            </span>
                                          ))}
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-group mb-0">
                                        <label className="small-label">
                                          Clinicians
                                        </label>

                                        <input
                                          name="count.clinicianCount"
                                          type="number"
                                          min="0"
                                          className="form-control"
                                          placeholder=" Clinician"
                                          value={counts.clinicianCount}
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            if (parseInt(value) > 999) {
                                              value = "999";
                                            }
                                            setCounts({
                                              ...counts,
                                              clinicianCount: value,
                                            });
                                          }}
                                          onWheel={(e) => e.target.blur()} // Prevent mouse wheel from changing the value
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "ArrowUp" ||
                                              e.key === "ArrowDown"
                                            ) {
                                              e.preventDefault(); // Prevent arrow keys from changing the value
                                            }
                                          }}
                                        />
                                        {counts?.clinicianCount &&
                                          counts?.clinicianCount != "" &&
                                          (counts?.clinicianCount === "1" ? (
                                            <span className="days">
                                              Clinician
                                            </span>
                                          ) : (
                                            <span className="days">
                                              Clinicians
                                            </span>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="listing-sub-header form-mobile-tab test mt-4 mt-md-5 mb-4 pt-2 pt-md-0 pb-md-2"
                        onClick={toggleMobileTabContentforinsurance}
                      >
                        <h4 className="main-title mb-0">Insurance</h4>
                      </div>
                      <div
                        className={`form-mobile-tab-content ${
                          isMobileTabContentVisibleforinsurance ? "show" : ""
                        }`}
                      >
                        <div className="form-tab-content-header d-block d-md-none">
                          <div className="header-actions mb-4 d-flex align-items-center justify-content-between">
                            <div className="header-actions-left">
                              <a role="button">
                                <img
                                  src={backImgBlack}
                                  alt=""
                                  onClick={() =>
                                    setIsMobileTabContentVisibleforstaffinsurance(
                                      false
                                    )
                                  }
                                />
                              </a>
                            </div>
                            <div className="header-actions-right d-flex align-items-center">
                              <button
                                type="button"
                                className="btn clear-btn"
                                onClick={insuranceodalviewmodeallstaeIsclear}
                              >
                                CLEAR
                              </button>
                              <button
                                className="btn apply-btn"
                                onClick={() => {
                                  const isValidMobile =
                                    insurancemodalvalidationformobile();
                                  if (isValidMobile) {
                                    setIsMobileTabContentVisibleforstaffinsurance(
                                      false
                                    );
                                  }
                                }}
                                type="button"
                              >
                                APPLY
                              </button>
                            </div>
                          </div>
                          <div className="header-title mb-4 pb-2">
                            <h4 className="main-title mb-0">INSURANCE</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group checkbox_wrapper mb-0">
                              <label className="mb-4 pb-md-2">
                                Payment, Insurance, Funding Accepted{" "}
                                <span className="mandatory">*</span>
                              </label>
                              <div className="dflex-form border-0">
                                {Object.keys(insurance).map(
                                  (key, index, array) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox"
                                        key={index}
                                      >
                                        <input
                                          className="custom-control-input"
                                          type="checkbox"
                                          id={`insurance${key}`}
                                          name={`insurance.${key}`}
                                          checked={insurance[key]}
                                          onChange={() =>
                                            handleOnchangeCheckBox(
                                              key,
                                              "insurance"
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`insurance${key}`}
                                        >
                                          {formatCamelCaseToReadableinsurance(
                                            key
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                What Is Your Network Insurance?{" "}
                                <span className="mandatory">*</span>
                              </label>
                              <div className="form-group">
                                <Select
                                  value={insuranceName}
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  selec
                                  isMulti
                                  options={insuranceProvider.slice(
                                    0,
                                    insuranceName?.some(
                                      (item) => item.value === "NA"
                                    )
                                      ? 0
                                      : 11
                                  )}
                                  onChange={(e) => handleInsurance(e)}
                                />
                              </div>
                              {othersVisible ||
                              insuranceName?.some(
                                (item) => item.value === "others"
                              ) ? (
                                <div className="other-insurance mt-3">
                                  <a
                                    className="btn btn-default"
                                    onClick={handleAddOtherProvider}
                                  >
                                    Add
                                  </a>
                                  <div className="form-group d-flex align-items-center mt-3 pt-1">
                                    <input
                                      className="form-control flex-fill"
                                      placeholder="Specify the other"
                                      id="otherProvider"
                                      value={otherProvider}
                                      onChange={handleOtherProviderChange}
                                    />
                                  </div>
                                </div>
                              ) : null}

                              <div></div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                Do You Have a Verify Insurance Benefits Link?{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter valid insurance URL"
                                value={verifyInsurance}
                                onChange={(e) =>
                                  setVerifyInsurance(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ReactSelect
                      handleRecommendedFacility={handleRecommendedFacility}
                      recommendedFacility={recommendedFacility}
                      selectedRecommendedID={selectedRecommendedID}
                      setIsMobileTabContentVisibleforstaffinsurance={
                        toggleMobileTabContentforRecomendfacility
                      }
                      setSelectedRecommendedID={setSelectedRecommendedID}
                      insurancemodalvalidationformobile={
                        insurancemodalvalidationformobile
                      }
                      insuranceodalviewmodeallstaeIsclear={
                        recommendedfacilityodalviewmodeallstaeIsclear
                      }
                      isMobileTabContentVisibleforinsurance={
                        isMobileTabContentVisibleforrecommendfacility
                      }
                      toggleMobileTabContentforinsurance={
                        toggleMobileTabContentforRecomendfacility
                      }
                    />
                    <div className="col-md-12 mt-3">
                      <div className="d-flex flex-column flex-md-row justify-content-center mt-sm-5 pt-3 pt-sm-5">
                        <button
                          type="submit"
                          className="btn btn-primary radius-20 btn-min-width"
                        >
                          {comp?.id &&
                          (isPreSaleSubscribed || subscriptionDetails !== null)
                            ? "Submit"
                            : "Next"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
          <Modal
            centered
            show={showErrorModal}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "auto",
              color: "#FF5F5F",
              alignItems: "center",
            }}
            className="spinner-taste"
            onHide={() => {}}
          >
            <img
              src={require("../../assets/images/cross-cancel.png")}
              width="50"
              heigth="50"
              alt="Error!"
              onClick={() => setShowErrorModal(false)}
            />
            <span className="loading-msg">
              {missingFields || "Please fill in the form"}
            </span>
          </Modal>
        </>
      ) : (
        <>
          <ResidentialSubscription
            info={{
              facilityType:
                residentialState?.residential?.facilityType || facilityType,
              id: residentialState?.residential?.id || id,
              name: residentialState?.residential?.name || name,
            }}
          />
        </>
      )}
    </>
  );
};

export default AddResidential;
