import { DashboardActionType } from "../actions";

const initialDetoxState = {
  fetching: false,
  dashboard: [],
};

export const DashboardReducer = (state = initialDetoxState, action) => {
  switch (action.type) {
    case DashboardActionType.fetch_Dashboard_ActionType:
      return { ...state, fetching: true };
    case DashboardActionType.fetch_Dashboard_Success:
      return { ...state, fetching: false, dashboard: action.payload };
    case DashboardActionType.fetch_Dashboard_Failure:
      return { ...state, fetching: false, error: action.payload.error };


      // dashbaord  Details
      
    case DashboardActionType.fetch_DashboarDetail_Success:
      return { ...state, fetching: false, dashbaordDetail: action.payload };
    case DashboardActionType.fetch_DashboardDetail_Failure:
      return { ...state, fetching: false, error: action.payload.error };

    // create click
    case DashboardActionType.Create_click_Success:
      return { ...state, fetching: false, createclick: action.payload };
    case DashboardActionType.Create_click_Failure:
      return { ...state, fetching: false, error: action.payload.error };

      // setData  click
    case DashboardActionType.set_Data_Success:
      return { ...state, fetching: false, setData: action.payload };
    case DashboardActionType.set_Data_Failure:
      return { ...state, fetching: false, error: action.payload.error };


    default:
      return state;
  }
};
