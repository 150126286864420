import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import LivingDetails from "./living-details";
import LivingSearch from "./living-search";
import AddLiving from './add-living';
import AddAdvertisement from './add-ads';
import ReactHelmet from "../../components/react-helmet/react-helmet";

class Living extends Component {

  render() {
    const AdminSecuredRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          // ternary condition

          this.props.isAuthorized && this.props.isHost ? (
            <Component {...props} />
          ) : (
              <Redirect to="/?isSignIn=true" />
            )
        }
      />
    );

    return (
      <>
      <ReactHelmet title={"Taste Recovery"} description={"Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."}/>

      <Switch>
        <AdminSecuredRoute path="/living/add-living" exact={true} component={AddLiving} />
        <AdminSecuredRoute path="/living/add-advertisement/:id" exact={true} component={AddAdvertisement} />
        <AdminSecuredRoute path="/living/edit-living/:id" exact={true} component={AddLiving} />
        <Route path="/living/search/:location" exact={true} component={LivingSearch} />
        <Route path="/living/:id" exact={true} component={LivingDetails} />
      </Switch>
      </>
    );
  }
}

export default connect(({ auth }) => ({
  isAuthorized: auth.authToken != null && auth.user != null,
  isHost: auth.isHost
}))(Living);