export const SERVER_APIS = {
  SIGN_IN_URL: `auth/signin`,
  SIGN_UP_URL: `auth/signup`,
  FORGOT_PASSWORD_URL: `auth/forgot`,
  LOGOUT_URL: `auth/logout`,
  RESET_PASSWORD_URL: `auth/reset/password`,
  GET_ME: `users/me`,
  TOP_STATES_URL: "living/top/states",
  Addiop_Center: "/iop",
  Add_Residential: "/residential",
  Living_TOP_BY_GENDER_URL: "living/top/byGenders",
  Living_Recommended: "living/recommended",
  Living_URL: "living",
  Living_URL_Facility: "facility",
  Facility_URL: "facility",
  Iop_URL: "iop",
  Detox: "/detox",
  GetAll_favoriteList: "/facility/profile/",
  Php: "/php",
  Facility_SAVE: "facility",
  Submitsurvey: "/survey/submit",
  Surveyquestions: "/survey",
  MediaUpload: "/media/upload",
  Residential: `residential`,
  GetBy_ID_IOP_Center: "iop",
  Living_List_URL: "living/list",
  Living_Search_URL: "living/search",
  Living_Search_Info_URL: "living/search/info",
  Report_URL: "report",
  Review_URL: "review",
  Review_By_Living_URL: "review/byLiving",
  Tours_URL: "tour",
  Tours_Calendar_URL: "tour/calendar",
  Payment_Card_URL: "payments/card",
  Payment_Card_Init_URL: "payments/init",
  IOP_Payment_Card_Init_URL: "iop/subscription/plans",
  CUPPON_CODE_URL: "coupon/apply",
  PreSale_Plan: "presales/subscription/plans",
  SUBSCRIPE_IOP_HOUSE: "/iop/{iopCenterId}/subscribe",
  Payment_Transaction_URL: "payments/transaction",
  Device_Register_URL: "device",
  Alert_URL: "alert",
  REFRESH_TOKEN_URL: `refresh/token`,
  TOKEN_KEY: `token`,
  REFRESH_TOKEN_KEY: `refresh_token`,
  STAT_ENDPOINT: "stat",
  Living_Fav_URL: "living/favorite",
  Living_Recently_Viewed_URL: "living/viewed",
  My_Reviews: "review/my",
  Fetch_Chats: "chat/dialogue",
  dashboard_Get: "ctr/owner/dashboard",
  Dashbaord_Detail: `/ctr/filter/data`,
  dashboard_CreateClick: "ctr/create/click",
  Search_Chat: "chat/dialogue/search",
  Get_Activity: "activity",
  Media_Upload: "media/upload",
  Media_Upload_VerifiedBadge: "media/upload/docs",
  Verified_Badge: "verify/request",
  CONTACT: "contact",
  Presale: "presales",
  Residential_Plan: "/residential/subscription/plans",
  Detox_Plan: "/detox/subscription/plans",
  Php_Plan: "/php/subscription/plans",
  Iop_Plan: "/iop/subscription/plans",
  Facility_List: "/facility/list",
  Facility_By_Id: "/facility",
  Survey_Fetch_List: "/survey/facility/list",
  Add_Survey: "/survey",
  Fetch_Survey_By_Id: "/survey",
  Update_Survey_Data: "/survey",
  // PPC_Subs_Url:"/PPCController_ppcClickOperation"
  PPC_Subs_Url: "/ppc/create",
  PPC_Auth: "/ppc/clicked",
  PPC_Non_Auth: "/ppc/clicked/beforesignin",
  Cancel_PPC: "/ppc/cancel",
  Fetch_Claim_List: "/facility/claim/list",
  Update_Claim: "/facility",
  Remove_Coupon: "/coupon",
  Session_Logout: "/session",
  All_Facility_List:"facility/list/listWithIdAndTitle",
  recommendedFacilityListById:"facility/recommend"
};

export const USER_ROLE = {
  REGULAR: "regular",
  HOST: "host",
};

export const PAYMENT_TYPE = {
  FIXED: "fixed",
  RANGE: "range",
};

export const CLIENT_RESTRICTION = {
  NONE: "None",
  ONE_WEEK: "One Week",
  TWO_WEEK: "Two Weeks",
  THIRTY_DAYS: "Thirty Days",
};

export const CURFEW_DAYS = {
  daily: "daily",
  sun: "Sunday",
  mon: "Monday",
  tue: "Tuesday",
  thu: "Thursday",
  wed: "Wednesday",
  fri: "Friday",
  sat: "Saturday",
};

export const IMAGE_THUMBNAILS = {
  THUMB360W: "thumb360w",
  THUMB720W: "thumb720w",
};

export const LivingStatus = {
  Lived: "lived",
  Toured: "toured",
};

export const PropertyOptions = {
  Internet: "internet",
  Fridge: "fridge",
  Tv: "tv",
  Ac: "a/c",
  Washer: "washer",
  Dryer: "dryer",
  Towels: "towels",
  Bedding: "bedding",
  Parking: "parking",
  Heating: "heating",
  Pool: "pool",
  Jacuzzi: "jacuzzi",
  Chef: "chef",
  NoSmoking: "noSmoking",
};

export const PropertyType = {
  Apartment: "apartment",
  Condominium: "condominium",
  Townhouse: "townhouse",
  House: "house",
  Duplex: "duplex",
  Triple: "triple",
  Bungalow: "bungalow",
  Villa: "villa",
  Trailer: "trailer",
};

export const PaymentPeriod = {
  Weekly: "weekly",
  Monthly: "monthly",
};

export const PaymentPeriodFilter = {
  Any: "any",
  Weekly: "weekly",
  Monthly: "monthly",
};

export const PropertyServing = {
  Any: "any",
  Men: "men",
  Women: "women",
  Lgbtq: "lgbtq",
};

export const SobrietyRequirement = {
  NONE: "none",
  ONE_WEEK: "one_week",
  THIRTY_DAYS: "thirty_days",
  SIXTY_DAYS: "sixty_days",
  NINETY_DAYS: "ninety_days",
  SIX_MONTHS: "six_months",
  ONE_YEAR: "one_year",
};

export const OccupancyMeter = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  SIX: "6",
  SEVEN: "7",
  EIGHT: "8",
  NINE: "9",
  TEN: "10",
  ELEVEN: "11",
  TWELVE: "12",
  THIRTEEN: "13",
  FOURTEEN: "14",
  FIVETEEN: "15",
  SIXTEEN: "16",
  SEVENTEEN: "17",
  EIGHTEEN: "18",
  NINETEEN: "19",
  TWENTY: "20",
  OVER_LIMIT: "max",
};
export const PaymentType = {
  Fixed: "fixed",
  Range: "range",
};

export const StandartOptions = {
  Any: "any",
  Yes: "yes",
  No: "no",
};



export const RoomOptions = {
  Any: "any",
  Private: "private",
  Shared: "shared",
};

export const ReportCause = {
  InappropriateMedia: "inappropriate_media",
  InappropriateMessages: "inappropriate_messages",
  ImagesMismatch: "images_mismatch",
  SpamScam: "spam_or_scam",
  Other: "other",
};
export const BedroomRange = {
  start: 1,
  end: 15,
};

export const BathroomRange = {
  start: 1,
  end: 7,
};

export const CurfewOptions = {
  "Different hours per day": "different_hours_per_day",
  "1 am": "1 AM",
  "2 am": "2 AM",
  "3 am": "3 AM",
  "4 am": "4 AM",
  "5 am": "5 AM",
  "6 am": "6 AM",
  "7 am": "7 AM",
  "8 am": "8 AM",
  "9 am": "9 AM",
  "10 am": "10 AM",
  "11 am": "11 AM",
  "12 pm": "12 PM",
  "1 pm": "1 PM",
  "2 pm": "2 PM",
  "3 pm": "3 PM",
  "4 pm": "4 PM",
  "5 pm": "5 PM",
  "6 pm": "6 PM",
  "7 pm": "7 PM",
  "8 pm": "8 PM",
  "9 pm": "9 PM",
  "10 pm": "10 PM",
  "11 pm": "11 PM",
  "12 am": "12 AM",
};

export const TwelveStepMeetingOptions = {
  "1 per week": 1,
  "2 per week": 2,
  "3 per week": 3,
  "4 per week": 4,
  "5 per week": 5,
  "6 per week": 6,
  "7 per week": 7,
  "8 per week": 8,
  "9 per week": 9,
  "10 per week": 10,
  "11 per week": 11,
  "12 per week": 12,
};

export const TimeCapsules = {
  "9 AM": "09:00",
  "10 AM": "10:00",
  "11 AM": "11:00",
  "12 PM": "12:00",
  "1 PM": "13:00",
  "2 PM": "14:00",
  "3 PM": "15:00",
  "4 PM": "16:00",
  "5 PM": "17:00",
  "6 PM": "18:00",
};

export const MaxOccupancy = Array.from({ length: 40 }, (_, i) => i + 1);

export const sobrietyRequirement = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "Test Clean",
    value: "test_clean",
  },
  {
    label: "3 days",
    value: "three_days",
  },
  {
    label: "one week",
    value: "one_week",
  },
  {
    label: "30 days",
    value: "thirty_days",
  },
  {
    label: "60 days",
    value: "sixty_days",
  },
  {
    label: "90 days",
    value: "ninety_days",
  },
  {
    label: "6 months",
    value: "six_months",
  },
  {
    label: "1 year",
    value: "one_year",
  },
];

export const CLIENT_RESTRICTION_OPTIONS = [
  {
    label: "1 week",
    value: "ONE_WEEK",
  },
  {
    label: "2 weeks",
    value: "TWO_WEEK",
  },
  {
    label: "30 days",
    value: "THIRTY_DAYS",
  },
];

export const weekDayOrder = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7,
  daily: 8,
};

export const seoUrlText = "sober-living-in";
