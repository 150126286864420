import React, { Component } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import ModalComponent from "../../components/modal/modal.component";
import TourCalendarComponent from "../../components/tour-calendar/tour-calendar.component";
import { tourAction } from "../../actions/tour.action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { livingAction } from "../../actions";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { isAfter, isBefore, isEqual } from "date-fns";
import parseIsoDate from "yup/lib/util/isodate";
import { withRouter } from "react-router-dom";

class TourBooking extends Component {
  static contextType = SpinnerContext;

  constructor(props) {
    super(props);
    this.state = {
      tourModalShow: false,
      showListActionMenu: false,
      property: {},
      availableDates: [],
      error: "",
      showError: false,
    };
    this.onBookATour = this.onBookATour.bind(this);
    this.onSelectTimeSlot = this.onSelectTimeSlot.bind(this);
  }

  componentDidMount() {
    if (this.props.user) {
      const property = this.props.property;
      this.setState({ property });
      this.context.showLoader("Fetching Tour Calendar");
      this.props
        .fetchTourCalendar(property.id)
        .finally(() => this.context.hideLoader());
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps && this.props.user) {
      this.setState(
        {
          property: this.props.property,
        },
        () => {
          const availableDates = this.state.property[
            "availableForToursDates"
          ]?.filter((existingDate) => {
            return !this.props.tourCalendar.some((bookDate) => {
              const currentDate = parseIsoDate(existingDate),
                startDate = parseIsoDate(bookDate.start),
                endDate = parseIsoDate(bookDate.end);
              return (
                (isAfter(currentDate, startDate) ||
                  isEqual(currentDate, startDate)) &&
                isBefore(currentDate, endDate)
              );
            });
          });
          const { bookingDetails } = this.state.property;
          if (bookingDetails) availableDates.push(bookingDetails.startsAt);
          this.setState({ availableDates });
        }
      );
    }
  }

  render() {
    return (
      <div className="property-btns mr-3">
        {!this.state.property.bookingDetails || !this.props.user ? (
          <Button
            className="btn btn-primary radius-20 mr-3"
            onClick={() => this.onBookATour(true)}
          >
            Book a Tour
          </Button>
        ) : (
          <DropdownButton
            as={ButtonGroup}
            title="Change Booking"
            toggle={true}
            className=""
            onSelect={this.onBookATour}
          >
            <Dropdown.Item eventKey="change" className="btn">
              Reschedule Tour{" "}
            </Dropdown.Item>
            <Dropdown.Item eventKey="cancel" className="btn">
              Cancel Tour
            </Dropdown.Item>
          </DropdownButton>
        )}
        <ModalComponent
          component={TourCalendarComponent}
          show={this.state.tourModalShow}
          availableDates={this.state.availableDates}
          bookingDetails={this.state.property["bookingDetails"]}
          onSelectTimeSlot={this.onSelectTimeSlot}
          className="calendar-modal"
          error={this.state.error}
          showError={this.state.showError}
          hideError={this.hideError}
          onHide={() => this.onBookATour(false)}
          size="lg"
        />
      </div>
    );
  }

  onBookATour(value) {
    if (this.props.user) {
      const { bookingDetails, id } = this.state.property;
      if (bookingDetails && value === "change") {
        this.setState({ tourModalShow: true });
      } else if (value === "cancel") {
        this.context.showLoader("Cancelling the tour");
        this.props.cancelTour(id).finally(() => {
          this.context.hideLoader();
          this.refreshLivingDetails();
        });
      } else {
        this.setState({ tourModalShow: value });
      }
    } else {
      // this.props.history.push("/home?isSignIn=true");
      this.context.showLogin(true);
    }
  }

  onSelectTimeSlot(startsAt) {
    const bookingDetails = Object.assign(
      {},
      this.state.property.bookingDetails
    );
    const { id } = this.state.property;
    let tourPromise;
    this.context.showLoader("Booking a tour");
    if (Object.keys(bookingDetails).length > 0) {
      bookingDetails.startsAt = startsAt;
      tourPromise = this.props.updateATour(bookingDetails, id);
    } else {
      tourPromise = this.props.bookATour({ startsAt, soberLivingId: id });
    }
    tourPromise
      .then(() => {
        this.onBookATour(false);
        this.props.history.push("/scheduled-tours");
      })
      .catch((error) => {
        this.setState({ showError: true, error });
      })
      .finally(() => {
        this.context.hideLoader();
      });
  }

  hideError = () => this.setState({ showError: false });

  refreshLivingDetails() {
    const { id } = this.state.property;
    this.context.showLoader("Refreshing sober living");
    Promise.all([
      this.props.fetchLivingById(id),
      this.props.fetchTourCalendar(id),
    ]).finally(() => this.context.hideLoader());
  }
}

export default withRouter(
  connect(
    ({ tour, living, auth }) => ({
      isTourBooking: tour.bookingATours,
      properties: living.properties,
      tourCalendar: tour.calendar,
      fetchingTourCalendar: tour.fetchingCalendar,
      fetchingLiving: living.fetchingLiving,
      user: auth.user,
    }),
    (dispatch) => {
      return {
        ...bindActionCreators({ ...tourAction, ...livingAction }, dispatch),
      };
    }
  )(TourBooking)
);
