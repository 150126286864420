import React, { Component } from 'react';
import SoberLivingCard from '../../components/cards/sober-living.card';
import { tourAction } from '../../actions/tour.action';
import { connect } from 'react-redux';
import { SpinnerContext } from '../../components/spinner/spinner.component';
import { Fragment } from 'react';
import ReactHelmet from '../../components/react-helmet/react-helmet';

class ScheduledTour extends Component {
  static contextType = SpinnerContext;

  componentDidMount() {
    // this.context.setStickyHeader(true);
    this.context.showLoader('Fetching scheduled tours');
    this.props.fetchTours().finally(() => this.context.hideLoader());
  }

  render() {
    return (
      <div>
      <ReactHelmet title={"Taste Recovery - Scheduled Tours"} description={"Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."}/>
      {this.props.scheduledTours && this.props.scheduledTours.length > 0 ? (
          <section className="recommended-living">
            <div className="container">
              <h3 className="main-title text-left">Scheduled Tours ({this.props.scheduledTours.length})</h3>
              <div className="slider-wrapper">
                <div className="recommended">
                  <div className="row">
                    {this.props.scheduledTours.map((item, index) => {
                      return (
                        <div className="col-md-6 col-lg-4" key={index}>
                          <SoberLivingCard item={item} isRecommended="true" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
        <section className="recommended-living">
            <div className="container">
              <h3 className="main-title text-left">Scheduled Tours ({this.props.scheduledTours?.length})</h3>
              <div className="no-records">
             { this.props.user?.role==="host"?<Fragment> <h2>THERE HAVE BEEN NO SCHEDULED TOURS FOR YOUR HOUSE YET</h2><p>Please check your current tours availability to make sure it is up to date.</p> </Fragment> :<Fragment> <h2>YOU HAVE NOT SCHEDULED TOURS YET</h2>
                 <p>Scheduled tours will show up here</p>
              </Fragment>
             }
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default connect(
  ({ tour,auth }) => ({
    scheduledTours: tour.tours,
    user:auth.user
  }),
  tourAction
)(ScheduledTour);
