import React, { Component } from 'react';
import { connect } from 'react-redux';
import { livingAction, paymentActions } from '../../actions';
import { SpinnerContext } from './../../components/spinner/spinner.component';
import { Button } from 'react-bootstrap';
import ToastError from '../../components/error/toast-error';
import { bindActionCreators } from 'redux';
import { addCommasToPrice, capitalizeWords, formatDateToCustom } from '../../constants/utils';

class Transactions extends Component {
  static contextType = SpinnerContext;

  constructor(props) {
    super(props);
    this.state = {
      showError: false
    };
  }

  componentDidMount() {
    if (!this.props.isFetching) {
      this.context.showLoader('Fetching transactions');
      this.props.getTransactions().finally(() => this.context.hideLoader());
    }
  }


  render() {
    return (
      <div className="savehome-wrp setting-list">
        <h3 className="main-title">Transactions </h3>
        <ToastError error={this.props.error} showError={this.state.showError} hideError={() => this.setState({ showError: false })} />
        <div className="saved-home-wrappper mt-4">
          <div className="transaction-wrp">
            {this.props.transactions.map((transaction) => {
              return (
                <div className="transaction-card" key={transaction?.id}>
                  <div className="transaction-detail">
                    <span>
                      Transaction ID: <strong>{transaction?.id}</strong>
                    </span>
                    <span>
                    {transaction?.facilityId ? "Facility name":"Campaign name"}  : <strong>{capitalizeWords(transaction?.soberLivingName)}</strong>
                    </span>
                    <span>
                      Amount: <strong>${addCommasToPrice(transaction?.total)}</strong>
                    </span>
                    <span>
                      Card number: <strong>xxxx xxxx xxxx {transaction?.last4}</strong>
                    </span>
                    <span>
                      Transaction date: <strong> {formatDateToCustom(transaction?.createdAt)}</strong>
                    </span>
                    {transaction?.isSuccess ? (
                      <span className="status success">Success</span>
                    ) : (
                      <div>
                        <p>{!transaction?.facilityId && "*The ads for this campaign have been stopped. Please, top up your card and retry or change the payment method"}</p>
                        {transaction?.type != 'subscription' ?
                          <><span className="status failed">Failed</span>
                            <div className="retry-card">
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic
                        example"
                              >
                                <Button className="btn group-primary" onClick={() => this.onRetryTransaction(transaction?.id)}>
                                  Retry
                                </Button>
                                <Button className="btn group-primary" onClick={() => this.onChangeCard(transaction)}>
                                  Change card
                                </Button>
                              </div>
                            </div>
                          </> : ''}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {this.props.transactions.length === 0 && (
              <div className="no-records">
                <h2 className="text-uppercase">OOPS, No transaction yet...</h2>
                <p>Your transactions will show up here</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  onRetryTransaction = (transactionId) => {
    this.context.showLoader('Retrying transaction');
    this.props
      .retryTransaction(transactionId)
      .then(() => this.context.showMessage('Success'))
      .catch(() => this.setState({ showError: true }))
      .finally(() => this.context.hideLoader());
  };

  onChangeCard = (transaction) => {
    this.props
      .saveLaunchAdDetails({
        livingId: transaction?.soberLivingId,
        selectedBudget: transaction?.total,
        livingName: transaction?.soberLivingName,
        cardLast4: transaction?.last4
      })
      .then(() => this.props.history.push('/settings/payment-options'));
  };
}

export default connect(
  ({ payment }) => ({
    isFetching: payment?.isFetchingTransactions,
    transactions: payment?.transactions,
    error: payment?.error
  }),
  (dispatch) => {
    return {
      ...bindActionCreators({ ...paymentActions, ...livingAction }, dispatch)
    };
  }
)(Transactions);
