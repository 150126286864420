import React, { Component } from "react";
import { Nav, Navbar, Button } from "react-bootstrap";
import rightArrowImg from "../../assets/images/arrow_right-24px.svg";
import backImg from "../../assets/images/back.svg";
import SavedAlerts from "./saved-alerts";
import SavedHomes from "./saved-homes";
import MyReviews from "./my-reviews";
import Dashboard from "../dashboard/dashboardIndex";
import RecentlyViewed from "./recently-viewed";
import { bindActionCreators } from "redux";
import {
  Route,
  Switch,
  NavLink,
  Link,
  BrowserRouter,
  Redirect,
  withRouter,
  matchPath,
} from "react-router-dom";
import MediaQuery from "react-responsive";
import AboutUs from "../common/about-us";
import TermsOfService from "../common/terms-of-service";
import FAQ from "../common/faq";
import AddCard from "./../payment/add-card";
import SavedCards from "./saved-cards";
import EditProfile from "./edit-profile";
import { connect } from "react-redux";
import { livingAction, detoxAction, DashboardAction } from "../../actions";
import logout from "../auth/logout";
import RemovedAccount from "../auth/removed-account";
import LivingDetails from "../living/living-details";
import Transactions from "./transactions";
import paymentOptionsPage from "../payment/payment-options.page";
import ContactUs from "../common/contact-us";
import ReactGA from "react-ga";
import livingSearch from "../living/living-search";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import Helpandsupport from "../common/Helpandsupport";

class Settings extends Component {
  settingOptions = [
    {
      label: "Saved alerts",
      path: "settings/saved-alerts",
      icon: "favorite.svg",
      isHost: true,
      isUser: true,
    },
    {
      label: "Saved Facilities",
      path: "settings/saved-homes",
      icon: "saved.svg",
      isHost: true,
      isUser: true,
    },
    {
      label: "Recently viewed",
      path: "settings/recently-viewed",
      icon: "view.svg",
      isHost: true,
      isUser: true,
    },
    {
      label: "Dashboard",
      path: "dashbaord",
      icon: "dashboard.svg",
      isHost: true,
      isUser: false,
    },
    {
      lineBreak: true,
    },
    {
      label: "My Reviews",
      path: "settings/my-reviews",
      icon: "review.svg",
      isHost: false,
      isUser: true,
    },
    {
      label: "Edit my Profile",
      path: "settings/profile",
      icon: "edit-profile.svg",
      isHost: true,
      isUser: true,
    },
    {
      lineBreak: true,
    },
    {
      label: "Your Cards",
      path: "settings/saved-cards",
      icon: "credit-card.svg",
      isHost: true,
      isUser: false,
    },
    {
      label: "Transactions",
      path: "settings/transactions",
      icon: "loop-24px.svg",
      isHost: true,
      isUser: false,
    },
    {
      requireHost: true,
      lineBreak: true,
    },
    {
      label: "About",
      path: "settings/about-us",
      icon: "about.svg",
      isHost: true,
      isUser: true,
    },
    {
      label: "Terms of Service",
      path: "settings/terms-of-service",
      icon: "terms.svg",
      isHost: true,
      isUser: true,
    },
    {
      label: "FAQs",
      path: "settings/faq",
      icon: "favorite.svg",
      isHost: false,
      isUser: false,
    },

    {
      label: "Help and support",
      path: "settings/help-and-support",
      icon: "favorite.svg",
      isHost: true,
      isUser: true,
    },
    {
      label: "Contact Us",
      path: "settings/contact-support",
      icon: "contact_support.svg",
      isHost: true,
      isUser: true,
    },
    {
      lineBreak: true,
    },
    {
      label: "Logout",
      path: "settings/logout",
      icon: "exit_to_app.svg",
      isHost: true,
      isUser: true,
    },
    {
      label: "Remove",
      path: "settings/removed-me",
      icon: "remove-acc.svg",
      isHost: true,
      isUser: true,
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      showMenu: true,
    };
  }

  componentDidMount() {
    this._APIcalls();
    const { pathname } = this.props.location;
    if (pathname.split("/").length > 2) {
      this.setState({ showMenu: false });
    }
  }

  componentDidUpdate() {
    if (window.location.pathname.split("/").length > 2) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  _APIcalls() {
    if (
      !this.props.isFetchingFavLivings &&
      this.props.favLivings.length === 0
    ) {
      this.props.fetchFavLivings().finally(() => {});
    }
    if (!this.props.isFetchingRecentlyViewed) {
      this.props.fetchRecentlyViewed().finally(() => {});
    }

    if (!this.props.isfacility) {
      this.props.onFetchAllFacility().finally(() => {});
    }

    if (!this.props.fetchingRecentlyRecentflag) {
      this.props.onFetchAllFacilityRecent().finally(() => {});
    }
  }
  isMobile() {
    return window.innerWidth <= 992; // Define your own breakpoint for mobile
  }

  render() {
    const { favLivings, recentlyViewed, isHost } = this.props;

    if (this.isMobile() === false) {
      this.settingOptions = this.settingOptions.filter(
        (option) => option.path !== "dashbaord"
      );
    }

    const SecuredRoute = ({ component: Component, ...rest }) => {
      const hostNotAllowedRoutes = ["/settings/my-reviews"];
      const userNotAllowedRoutes = [
        "/settings/saved-cards",
        "/settings/transactions",
      ];
      const currentUserRole = isHost
        ? hostNotAllowedRoutes
        : userNotAllowedRoutes;

      if (rest.path === "/living/:id") {
        window.location.reload();
        return;
      }
      if (rest.path === "/living/search/:location") {
        window.location.reload();
        return;
      }
      return (
        <Route
          {...rest}
          render={(props) =>
            !currentUserRole.includes(rest.path) ? (
              <Component {...props} />
            ) : (
              <Redirect to="/settings/saved-alerts" />
            )
          }
        />
      );
    };
    return (
      <BrowserRouter>
        <ReactHelmet
          title={"Taste Recovery - Settings"}
          description={
            "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
          }
        />

        <section
          className={`setting-pages setting-section ${
            window?.location?.pathname === "/dashbaord" ? "pt-0" : ""
          }`}
        >
          <div
            className={`container ${
              window?.location?.pathname === "/dashbaord" ? "p-0" : ""
            }`}
          >
            <div className="setting-main-wrapper d-flex align-items-start">
              <MediaQuery maxWidth="767px">
                {(matches) => (
                  <Navbar expand={matches ? this.state.showMenu : true}>
                    <Navbar.Collapse id="basic-navbar-nav">
                      <div
                        className="col-md-3 mobile-display-toggle"
                        id="mobileDisplay"
                      >
                        <div className="sidebar-settings">
                          <h3 className="main-title">Settings</h3>
                          <div className="sidebar-wrapper">
                            <Nav
                              defaultActiveKey="/settings"
                              className="flex-column"
                              as="ul"
                            >
                              {this.settingOptions.map((option, index) => {
                                const renderOption = isHost
                                  ? option.isHost
                                  : option.isUser;
                                return (
                                  <div
                                    key={index}
                                    className={
                                      option.lineBreak &&
                                      (!option.requireHost || isHost)
                                        ? "section-break-div"
                                        : ""
                                    }
                                  >
                                    {renderOption && (
                                      <li>
                                        <Nav.Link
                                          as={NavLink}
                                          to={`/${option.path}`}
                                          onClick={() =>{
                                            if(option.path=== "settings/saved-homes"){
                                              this.props.onFetchAllFacility().finally(() => {});
                                            }
                                            if(option.path === "settings/recently-viewed"){
                                              this.props.onFetchAllFacilityRecent().finally(() => {});
                                            }
                                            
                                            this.setState({ showMenu: false })
                                          }
                                        }
                                        >
                                          {option.label === "Saved Homes" ? (
                                            <span>
                                              <img
                                                src={require(`../../assets/images/${option.icon}`)}
                                                alt=""
                                              />
                                              {option.label}
                                            </span>
                                          ) : this.isMobile() === true &&
                                            option.label === "Dashboard" ? (
                                            option.label === "Dashboard" ? (
                                              <span
                                                onClick={() =>
                                                  this.props.setDataforRedirection(
                                                    {
                                                      name: false,
                                                      url: `/settings`,
                                                    }
                                                  )
                                                }
                                              >
                                                <img
                                                  src={require(`../../assets/images/${option.icon}`)}
                                                  alt=""
                                                />
                                                {option.label}
                                              </span>
                                            ) : (
                                              ""
                                            )
                                          ) : option.label ===
                                            "Recently viewed" ? (
                                            <span>
                                              <img
                                                src={require(`../../assets/images/${option.icon}`)}
                                                alt=""
                                              />
                                              {option.label}
                                            </span>
                                          ) : (
                                            <span>
                                              <img
                                                src={require(`../../assets/images/${option.icon}`)}
                                                alt=""
                                              />
                                              {option.label}
                                            </span>
                                          )}

                                          <span>
                                            <img src={rightArrowImg} alt="" />
                                          </span>
                                        </Nav.Link>
                                      </li>
                                    )}
                                  </div>
                                );
                              })}
                            </Nav>
                          </div>
                        </div>
                      </div>
                    </Navbar.Collapse>
                  </Navbar>
                )}
              </MediaQuery>
              <MediaQuery maxWidth="767px">
                {(matches) =>
                  (matches ? !this.state.showMenu : true) ? (
                    <div
                      className={`col-md-9 ${
                        window?.location?.pathname === "/dashbaord"
                          ? "p-0"
                          : ""
                      }`}
                    >
                      {window?.location?.pathname === "/dashbaord" ? (
                        <>
                          {/* <div className="back setting-back">
                            <a
                              role="button"
                              onClick={() => this.setState({ showMenu: true })}
                            >
                              <img src={backImg} alt="" />
                            </a>
                          </div> */}
                        </>
                      ) : (
                        <></>
                      )}

                      <div
                        className={`settings-content ${
                          window?.location?.pathname === "/dashbaord"
                            ? "pt-0"
                            : ""
                        }`}
                      >
                        <Switch>
                          <SecuredRoute
                            path="/settings/saved-alerts"
                            exact={true}
                            component={SavedAlerts}
                          />
                          <SecuredRoute
                            path="/settings/saved-homes"
                            exact={true}
                            component={SavedHomes}
                          />
                          <SecuredRoute
                            path="/settings/my-reviews"
                            exact={true}
                            component={MyReviews}
                          />

                          <SecuredRoute
                            path="/dashbaord"
                            exact={true}
                            component={Dashboard}
                          />
                          <SecuredRoute
                            path="/settings/profile"
                            exact={true}
                            component={EditProfile}
                          />
                          <SecuredRoute
                            path="/settings/contact-support"
                            exact={true}
                            component={ContactUs}
                          />
                          <SecuredRoute
                            path="/settings/recently-viewed"
                            exact={true}
                            component={RecentlyViewed}
                          />
                          <SecuredRoute
                            path="/settings/living/:id"
                            exact={true}
                            component={LivingDetails}
                          />
                          <SecuredRoute
                            path="/settings/about-us"
                            exact={true}
                            component={AboutUs}
                          />
                          <SecuredRoute
                            path="/settings/terms-of-service"
                            exact={true}
                            component={TermsOfService}
                          />
                          <SecuredRoute
                            path="/settings/help-and-support"
                            exact={true}
                            component={Helpandsupport}
                          />
                          <SecuredRoute
                            path="/settings/faq"
                            exact={true}
                            component={FAQ}
                          />

                          <SecuredRoute
                            path="/settings/saved-cards"
                            exact={true}
                            component={SavedCards}
                          />
                          <SecuredRoute
                            path="/settings/transactions"
                            exact={true}
                            component={Transactions}
                          />
                          <SecuredRoute
                            path="/settings/payment-options"
                            exact={true}
                            component={paymentOptionsPage}
                          />
                          <Route
                            path="/settings/logout"
                            exact={true}
                            component={logout}
                          />
                          <Route
                            path="/settings/removed-me"
                            exact={true}
                            component={RemovedAccount}
                          />
                          <SecuredRoute
                            path="/living/search/:location"
                            exact={true}
                            component={livingSearch}
                          />
                        </Switch>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                }
              </MediaQuery>
            </div>
          </div>
        </section>
      </BrowserRouter>
    );
  }
}

export default withRouter(
  connect(
    ({ living, auth }) => ({
      isFetchingFavLivings: living.fetchingFavLivings,
      favilityAll: living.favilityAll,
      fetchingRecentlyRecentflag: living.fetchingRecentlyRecentflag,
      recentlyViewedData: living.recentlyViewedData,
      isfacility: living.fetchingAllFacility,
      fetchingAllFacility :living.fetchingAllFacility,
      favLivings: living.favLivings,
      isFetchingRecentlyViewed: living.fetchingRecentlyViewed,
      recentlyViewed: living.recentlyViewed,
      isHost: auth.isHost,
    }),

    (dispatch) => {
      return {
        ...bindActionCreators(
          { ...livingAction, ...DashboardAction },
          dispatch
        ),
      };
    }
  )(Settings)
);
