import React, { Component } from "react";
import imgAbout from "../../assets/images/img-about.jpg";
import ReactHelmet from "../../components/react-helmet/react-helmet";
class AboutUs extends Component {
  render() {
    return (
      <>
        <ReactHelmet
          title={"Taste Recovery - About Us"}
          description={
            "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
          }
        />
        <section
          className={`container savehome-wrp setting-list ${
            this.props.location.pathname === "/settings/about-us" ? "p-0" : ""
          }`}
          id="eight"
        >
          <h3 className="main-title title-decoration">About </h3>
          <img className="img-fluid" src={imgAbout}></img>
          <div className="saved-home-wrappper mt-4">
            <div className="cms-wrp text-justify">
              <p>
                Taste Recovery was founded to support those seeking a safe
                refuge and a new start. The definition of taste is to discover
                through experience. With knowledge of what types of treatment
                facilities are out there, we hope to give you the best options
                to rediscover yourself and give you the best chance at finding
                long term sobriety.
              </p>
              <p>
                There is a lack of resources to help those looking to start a
                solid foundation in early recovery. We have heard the
                desperation from addicts, alcoholics, and their fearful loved
                ones. Early sobriety is difficult and is a time when addicts are
                at their most vulnerable. It is imperative that there is a
                trusted resource where individuals can go to find treatment that
                is safe, secure, and supportive as they launch their recovery.
              </p>
              <p>
                Taste Recovery wants to shine a light on this industry in a
                positive way. We hope to highlight the professionals that are
                doing everything in their power to ensure a safe, stable, and
                sober environment, while providing structure and support to
                those that choose their facility to get well.
              </p>
              <p>
                Taste Recovery offers those searching for treatment an
                opportunity to find a facility that best fits their individual
                needs. We believe that this will benefit each individual’s
                recovery by providing a new community that offers confidence and
                security. Recovery transcends when addicts and alcoholics find
                their sober tribe. We can’t do this alone. It takes a village, a
                home, a tribe, a community. Let us be a part of your journey as
                you trudge the road to happy destiny.
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default AboutUs;
