import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store, { persiststore } from "./store";
import { swDev } from "./push-notification";

ReactDOM.render(
  <App store={store} persistor={persiststore} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

swDev();
serviceWorker.register();
