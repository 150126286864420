import React from "react";
import cancleImg from "../../assets/images/cancle.svg";
import phoneIcon from "../../assets/images/phone.svg";
import virtualTourIcon from "../../assets/images/virtual-tour.svg";
import { formatPhoneNumber } from "../../constants/utils";
const ThankspaticiaptingModal = ({ open, onCancel, phoneNumber, webUrl }) => {
  return (
    <div>
      {open && (
        <div
          className="modal upgrade-plan cancel-subscription-modal prequalification-modal fade show"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          show
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="survey-modal_body">
                <div className="participate-question">
                  <a
                    href="#"
                    className="cancel-btn d-flex align-items-center"
                    role="button"
                  >
                    <img
                      src={cancleImg}
                      className="img-fluid"
                      alt=""
                      onClick={onCancel}
                    />
                  </a>
                  <div className="facility-content">
                    <div className="sidebar m-0 p-0">
                      <div className="card text-center m-0 border-0">
                        <p className="mb-0">
                          Thanks for participating in this survey to help us
                          understand your needs.
                          <br /> We will get back to you soon, meanwhile you can
                          also contact us at
                        </p>
                        <div className="card-actions d-flex flex-column">
                          <button
                            type="button"
                            className="btn btn-primary radius-20 m-0 justify-content-center"
                          >
                            <span>
                              <img
                                src={phoneIcon}
                                className="img-fluid"
                                alt=""
                              />
                              {formatPhoneNumber(phoneNumber)}
                            </span>
                          </button>
                          <div className="or-divider">
                            <p>OR</p>
                          </div>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                `${webUrl}`,
                                "_blank"
                              );
                            }}
                            data-toggle="tooltip"
                            title={webUrl}
                            className="btn btn-primary-outline radius-20 m-0 justify-content-center"
                          >
                            <span>
                              <img
                                src={virtualTourIcon}
                                className="img-fluid"
                                alt=""
                              />
                            Visit our site
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThankspaticiaptingModal;
