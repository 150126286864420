import React from "react";
import ConfirmationModalComponent from "./confirmation-modal.component";
export default class PromiseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      message:""
    };

    this.promiseInfo = {};
  }

  show = async (position,length) => {
    
    return new Promise((resolve, reject) => {
      this.promiseInfo = {
        resolve,
        reject
      };
      // let message=position==length?`Click Retry to upload image at position number ${position+1} after fixing internet connection.`:`Click Retry to upload image at position number ${position+1} after fixing internet connection or click Cancel to upload next image`;
      let message=`Click 'Retry' to upload image at position number ${position+1} after fixing internet connection or click 'Cancel' to upload next image`;
      this.setState({
        show: true,
        message:message
      });
    });
  };

  hide = () => {
    this.setState({
      show: false
    });
  };

  render() {
    const { show ,message} = this.state;
    const { resolve, reject } = this.promiseInfo;
    return (
      <ConfirmationModalComponent
        onConfirm={() => {
          this.hide();
          resolve(true);
        }}
        onCancel={() => {
          this.hide();
          resolve(false);
        }}
        cancelButtonName="Cancel"
        confirmButtonName="Retry"
        show={show}
        title={"Check your internet connection"}
        message={message}

      />
    );
  }

  getResolve() {
    const { resolve = () => {} } = this.promiseInfo || {};
    return result => {
      resolve(result);
      this.hide();
    };
  }

  /**
   * reject
   */
  getReject() {
    const { reject = () => {} } = this.promiseInfo || {};
    return err => {
      reject(err);
      this.hide();
    };
  }
}
