
import React, { useState, useContext, useEffect } from "react";
import selectTourDates from "../../modules/living/select-tour-dates";
import { Modal } from "react-bootstrap";
import Compressor from 'compressorjs';
import { SpinnerContext } from "../../components/spinner/spinner.component";
import uploadImg from '../../assets/images/upload.svg';
import pdfIcon from '../../assets/images/pdf.png';
import wordIcon from '../../assets/images/word.png';

const VerifiedBadgeUpload = ({ selectedPhotos = [], draggable, addImage, deleteImage, isUploading, getImageData }) => {
    const context = useContext(SpinnerContext);
    const [dragId, setDragId] = useState("");
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        getImageData(selectedPhotos);
    }, [selectedPhotos])

    const onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        let files = event.target.files;
        let start = selectedPhotos?.length;
        let compressedPhotos = [];

        if (parseInt(selectedPhotos.length) + parseInt(files.length) <= 5) {
            Object.keys(files).forEach(function (key) {
                if (files[key]?.size >= 2000000) {
                    context.showLoader('Please wait while compressing...');

                    let compressImage = new Compressor(files[key], {
                        quality: 0.4,
                        success: (compressedResult) => {
                            if (isAllowedFileType(compressedResult.type)) {
                                compressedResult.position = start++;
                                compressedResult.id = new Date().valueOf().toString() + start
                                compressedPhotos.push(compressedResult);
                                let arrangeSelectedPhotoPosition = selectedPhotos.map((item, index) => item.position = index);
                                const finalImages = [...selectedPhotos, ...compressedPhotos];
                                addImage(finalImages);
                                setTimeout(() => context.hideLoader(), 3000);
                            } else {
                                setShowErrorModal(true);
                                setErrorMessage("Only Image, Word, or PDF files are allowed.");
                                setTimeout(() => {
                                    setShowErrorModal(false);
                                }, 2500)
                            }
                        },
                    });
                } else {
                    if (isAllowedFileType(files[key].type)) {
                        files[key].position = start++;
                        files[key].id = new Date().valueOf().toString() + start;
                        let arrangeSelectedPhotoPosition = selectedPhotos.map((item, index) => item.position = index);
                        const finalImages = [...selectedPhotos, files[key]];
                        addImage(finalImages);
                    } else {
                        setShowErrorModal(true);
                        setErrorMessage("Only Image, Word, or PDF files are allowed.");
                        setTimeout(() => {
                            setShowErrorModal(false);
                        }, 2500)
                    }
                }
            });
        } else {
            setShowErrorPopup(true);
            setTimeout(() => {
                setShowErrorPopup(false);

            }, 2500);
        }
        event.target.value = "";
    };

    const isAllowedFileType = (fileType) => {
        const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "image/svg", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
        return allowedFormats.includes(fileType);
    };

    const isPdfFile = (fileType) => {
        return fileType === "application/pdf";
    };

    const isWordFile = (fileType) => {
        return fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    };

    const deletePhotos = (index) => {
        const files = [...selectedPhotos];
        files.splice(index, 1);

        files.map((image, index) => {
            image.position = index;
        });

        deleteImage(files);
    };

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = (ev) => {
        const dragBox = selectedPhotos.find((photo) => photo.id === dragId);
        const dropBox = selectedPhotos.find((photo) => photo.id === ev.currentTarget.id);

        const dragBoxPostion = dragBox?.position;
        const dropBoxPosition = dropBox?.position;

        const newPhotoState = selectedPhotos.map((photo, index) => {
            if (index == dragBoxPostion) {
                photo.position = dropBoxPosition;
            }
            if (dropBoxPosition <= index && dragBoxPostion > index) {
                photo.position++;
            }
            if (dragBoxPostion < index && dropBoxPosition >= index) {
                photo.position--;
            }
            return photo;
        });
        addImage(newPhotoState);
    };

    return (
        <div className="added-photo-listing">
            {showErrorPopup &&
                <Modal
                    centered
                    show={showErrorPopup}
                    onHide={() => { }}
                    className="upgrade-plan cancel-subscription-modal updated-successfully-modal"
                    style={{ background: "transparent", }}
                >
                    <div className="upgrade-plan-content d-flex flex-column align-items-center">
                        <img src={require('../../assets/images/cross-cancel.png')} width="50" height="50" alt="Error!" />
                        <span className="loading-msg">{"Can't add more than 5 photos"}</span>
                    </div>

                </Modal>
            }
            {showErrorModal &&
                <Modal
                    centered
                    show={showErrorModal}
                    onHide={() => setShowErrorModal(false)}
                    className="upgrade-plan cancel-subscription-modal updated-successfully-modal"
                    style={{ background: "transparent" }}
                >
                    <div className="upgrade-plan-content d-flex flex-column align-items-center">
                        <span className="loading-msg">{errorMessage}</span>
                    </div>
                </Modal>
            }
            <input id="fileupload"
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => onChangeFile(e)}
                accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
            />
            <div
                className={`photo-wrp placeholder ${(isUploading || selectedPhotos.length == 5) ? 'disabled' : ''}`}
                onClick={() => document.getElementById("fileupload").click()}>
                <span className="add-pic">
                    <img src={uploadImg} alt="" />
                </span>
            </div>

            {selectedPhotos.sort((a, b) => a.position - b.position).map((photo, index) => {
                let reader;
                if (!photo?.path) {
                    reader = new FileReader()
                    reader.readAsDataURL(photo)
                }
                return (
                    <div className="photo-wrp cursor-pointer" key={index} id={photo.id} draggable={draggable} onDragOver={(ev) => ev.preventDefault()}
                        onDragStart={draggable ? handleDrag : () => { return false }}
                        onDrop={handleDrop} >
                        {isPdfFile(photo.type) ? (
                            <img src={pdfIcon} alt="PDF" /> // Display PDF icon for PDF files
                        ) : isWordFile(photo.type) ? (
                            <img src={wordIcon} alt="Word" /> // Display Word file icon for Word files
                        ) : (
                            <img src={photo?.path ? photo?.thumbnails ? photo.thumbnails["thumb720w"] : photo.path : URL.createObjectURL(photo)} alt="Uploaded" /> // Display uploaded image
                        )}
                        <span className={`delete-pic ${isUploading ? 'disabled' : ''}`} onClick={() => deletePhotos(index)}>
                            <img src={require("../../assets/images/ic_close.svg")} alt="" />
                        </span>
                    </div>)
            })}

        </div>
    );
}

export default VerifiedBadgeUpload;
