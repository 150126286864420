import React from 'react'

const Questions = (props) => {
    const {image, heading, details}= props
  return (
    <>
    {details && <div className="facility-content">
                    <h3 className="d-flex align-items-center">
                      <img src={image} className="img-fluid" alt="" />
                      {heading}
                    </h3>
                    <ul className="d-flex align-items-start flex-wrap">
                      <li> Yes</li>
                    </ul>
    </div>}
    </>
  )
}

export default Questions