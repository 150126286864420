import React from "react";
import pinImg from "../../assets/images/ic_pin_small.svg";
import tickMarkIcon from "../../assets/images/tick-mark.svg";
import starImg from "../../assets/images/star-24px.svg";
import SliderNextArrow from "../slider/sliderNext.component";
import SliderPrevArrow from "../slider/sliderPrevArrow.component";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import ContentComponent from "../button/ContentComponent";
import { capitalizeWords } from "../../constants/utils";
import NoImage from "../../assets/images/no-image.svg";
const SaveHome = ({ data, name }) => {
  const history = useHistory();
  var settings1 = {
    dots: true,
    infinite: false,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };

  const handleClick = (e, id, city, name) => {
    if (name === "Sober Living") {
      history.push(`/living/sober-living-in-${city}&id=${id}`);
    } else {
      history.push(`/home/centers/view/${id}`);
    }

    window.location.reload(true);
    e.preventDefault();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="listing-sub-header test mt-3">
            <h4 className="main-title mb-0">{name.toUpperCase()}</h4>
          </div>
          <div className="row">
            {data?.length === 0 && (
              <div className="no-records">
                <h2 className="text-uppercase text-bold">
                  You have not favorited any {name}s yet
                </h2>
                <p>
                  When you see a home that you like, bookmark it so you can
                  compare your favourites later!
                </p>
              </div>
            )}

            {data?.map((obj) => {
              return (
                <>
                  <div className="col-md-12 col-lg-6">
                    {" "}
                    <div className="facility-list-item">
                      <div className="img-slider position-relative">
                        {obj?.photos?.length > 0 ? (
                          <Slider {...settings1}>
                            {obj?.photos?.map((item) => {
                              return (
                                <>
                                  <img
                                    src={item?.path}
                                    className="img-fluid facility-img"
                                    alt=""
                                  />
                                </>
                              );
                            })}
                          </Slider>
                        ) : (
                          <img
                            src={NoImage}
                            className="img-fluid facility-img"
                            alt=""
                          />
                        )}

                        {obj?.isPPCSubscribed && (
                          <span className="promote-ad-msg">Ad</span>
                        )}
                        {obj?.preSalePlanType === "pro" ? (
                          <span className="subscription-msg unsubscribed">
                            Featured{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="card-body">
                        <div className="card-heading_wraper d-flex align-items-center justify-content-between">
                          <div
                            className="card-heading cursor-pointer"
                            onClick={(e) =>
                              handleClick(
                                e,
                                obj?.id,
                                obj?.geocodingResult?.cityName,
                                name
                              )
                            }
                          >
                            {capitalizeWords(obj?.name)}
                            {obj?.isVerified ? (
                              <>
                                <img
                                  src={tickMarkIcon}
                                  className="img-fluid"
                                  alt=""
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <span className="location align-items-center">
                            <img src={starImg} className="img-fluid" alt="" />
                            {obj?.rating?.value?.toFixed(1)}
                          </span>
                        </div>
                        <ContentComponent obj={obj} />
                        <span className="location">
                          <img src={pinImg} className="img-fluid" alt="" />
                          {obj?.geocodingResult?.cityName},
                          {obj?.geocodingResult?.stateShort},
                          {obj?.geocodingResult?.country}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveHome;
