import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  detoxAction,
  livingAction,
  addiopcenterAction,
  phpAction,
  residentialAction,
} from "../../actions/index";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "react-avatar";
import Moment from "react-moment";
import Rater from "react-rater";
import replyImg from "../../assets/images/reply.svg";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import axios from "axios";
import { SERVER_APIS } from "../../constants";
import RecommendedFacility from "../../components/home/recommendedFacility";
import SliderNextArrow from "../../components/slider/imageSliderNext";
import SliderPrevArrow from "../../components/slider/imageSliderPrev";
import noReviewImg from "../../assets/images/no-review-image.svg";
const HostReply = ({ chatid, type, data }) => {
  const state = useSelector((state) => state);
  const isHost = state?.auth?.isHost;
  const user = state?.auth?.user?.name;
  const comp = useParams();
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [GetData, setGetData] = useState([]);

  const [rating, setRating] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [isReply, setIsReply] = useState(false);
  const [replyId, setReplyId] = useState();
  const [replyReview, setReplyReview] = useState({ content: "" });
  const [comment, setComment] = useState("");
  const [isReviewAdd, setISReveiewAdd] = useState(false);
  const [getReview, setGetReview] = useState([]);
  const [isReplyAdd, setISReplyAdd] = useState(false);
  const [confirmation, setconfirmation] = useState(false);
  const [recommendedFacilities, setRecommendedFacilities] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    ShowData(chatid);
  }, [chatid]);

  useEffect(() => {
    const fetchRecommendedHouse = async () => {
      try {
        const response = await axios.get(
          `${SERVER_APIS.recommendedFacilityListById}/${chatid}`
        );
        setRecommendedFacilities(response?.data?.data);
      } catch (error) {
        console.error("Error fetching recommended house data:", error);
      }
    };
    if (comp?.id) {
      fetchRecommendedHouse();
    }
  }, []);

  const ShowData = async (id) => {
    showLoader(`Geting ${type} center please wait `);
    if (type === "detox") {
      const data = await dispatch(detoxAction.getDetoxById(id));
      document.title = data?.name;

      setGetData(data);
    }
    if (type === "iop") {
      const data = await dispatch(
        addiopcenterAction.getByIdIopcenterdeatials(id)
      );
      document.title = data?.name;

      setGetData(data);
    }

    if (type === "php") {
      const data = await dispatch(phpAction.getPhpById(id));
      document.title = data?.name;

      setGetData(data);
    }

    if (type === "residential") {
      const data = await dispatch(residentialAction.getResidencialById(id));
      document.title = data?.name;

      setGetData(data);
    }

    hideLoader("");
    if (confirmation) {
      window.location.reload();
    }
  };

  useEffect(() => {
    fetchReview(comp.id);
  }, [isReviewAdd]);
  const fetchReview = async () => {
    if (isReviewAdd) {
      showLoader("adding your review.....");
    }

    if (isReply) {
      showLoader("replying to review.....");
    }
    const data = await dispatch(livingAction.fetchLivingReviewsById(chatid));
    hideLoader("");
    setGetReview(data);
    setISReveiewAdd(false);
  };

  const handleReplyReview = async (replyReview, id, getData) => {
    dispatch(livingAction.addReviewReply(replyReview, id, getData));

    if (isReply) {
      await dispatch(livingAction.fetchLivingReviewsById(chatid));
    }
    //  setISReplyAdd
    setconfirmation(true);
    ShowData(chatid);
    setIsReply(false);
    setReplyReview({ content: "" });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const onSubmitReview = () => {
    const review = {
      user: user,
      value: rating,
      comment: comment,
      livedFrom: fromDate,
      livedTo: toDate,
      property: chatid,
      isAnonymous: false,
    };
    setconfirmation(true);
    dispatch(livingAction.addReview(review));
    setconfirmation(true);
    setISReveiewAdd(true);
    setRating("");
    setFromDate("");
    setToDate("");
    setComment("");
    fetchReview(chatid);
  };
  var recommendedSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current),
  };

  var settings1 = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };

  return (
    <>
      {(GetData?.isSubscribed ||
        GetData?.isPreSaleSubscribed ||
        GetData?.subscriptionDetail === null ||
        GetData?.subscriptionDetail != null) && (
        <div className="form-view-reviews">
          <div id="reviews" className="container">
            <div className="row">
              <div className="col-md-10 col-lg-8">
                {((state?.living?.reviewsForAnonymousUser.length !== 0 &&
                  state?.auth?.user?.role === "host") ||
                  state?.auth?.user?.role !== "host") && (
                  <div className="facility-content">
                    {state?.living?.reviewsForAnonymousUser.length !== 0 && (
                      <h3 className="mt-0">Reviews</h3>
                    )}
                    {state?.living?.reviewsForAnonymousUser.length !== 0 &&
                      state?.living?.reviewsForAnonymousUser?.map((ele) => {
                        const userNameInitial = ele?.user?.name
                          .charAt(0)
                          .toUpperCase();
                        return (
                          <>
                            <div className="review-block d-flex">
                              <div className="review-image">
                                <Avatar
                                  as
                                  img
                                  name={ele?.user?.name
                                    ?.charAt(0)
                                    .toUpperCase()}
                                  size="40"
                                  className="img-fluid"
                                  round={true}
                                />
                              </div>
                              <div className="review-detail flex-fill">
                                <div className="top-info d-flex justify-content-between">
                                  <div className="reviewer-name-date">
                                    <h4 className="mb-1">
                                      {ele?.user?.name?.split(" ")[0]}
                                    </h4>
                                    <p>
                                      {" "}
                                      <Moment
                                        date={ele?.createdAt}
                                        format="DD MMM, YYYY"
                                      />
                                    </p>
                                  </div>
                                  <div className="star-rating d-flex align-items-center">
                                    <Rater
                                      rating={ele?.value}
                                      interactive={false}
                                    />
                                  </div>
                                </div>
                                <div className="bottom-info">
                                  {"livedFrom" in ele &&
                                    ele?.livedFrom !== null &&
                                    "livedTo" in ele &&
                                    ele?.livedTo !== null && (
                                      <p>
                                        Treated in this facility
                                        {"livedFrom" in ele &&
                                          ele?.livedFrom !== null && (
                                            <>
                                              {" "}
                                              from{" "}
                                              <Moment
                                                date={ele?.livedFrom}
                                                format="DD MMM, YYYY"
                                              />
                                            </>
                                          )}
                                        {"livedTo" in ele &&
                                          ele?.livedTo !== null && (
                                            <>
                                              {" "}
                                              to{" "}
                                              <Moment
                                                date={ele?.livedTo}
                                                format="DD MMM, YYYY"
                                              />
                                            </>
                                          )}
                                        .
                                      </p>
                                    )}

                                  <p>{ele?.comment}</p>

                                  {isHost &&
                                    state?.auth?.user?.id ==
                                      GetData?.createdBy && (
                                      <div className="reply-button">
                                        <button
                                          type="button"
                                          className="btn-reply"
                                          onClick={() => {
                                            setIsReply(true);
                                            setReplyId(ele?.id);
                                          }}
                                        >
                                          <img
                                            src={replyImg}
                                            className="img-fluid"
                                            alt=""
                                          />{" "}
                                          Reply
                                        </button>
                                      </div>
                                    )}
                                  {isHost && isReply && replyId == ele?.id && (
                                    <div className="reply-block mt-3">
                                      <div className="form-group d-flex flex-column">
                                        <textarea
                                          placeholder="Reply to review"
                                          name="review"
                                          maxlength="500"
                                          rows="4"
                                          class="form-control"
                                          onChange={(e) =>
                                            setReplyReview({
                                              content: e.target.value,
                                            })
                                          }
                                        ></textarea>
                                      </div>
                                      <div className="form-group d-flex mb-0">
                                        <button
                                          disabled={
                                            replyReview.content.length == 0
                                          }
                                          type="button"
                                          className="btn btn-primary radius-20 justify-content-center mt-4 mb-4 mb-md-0"
                                          onClick={() =>
                                            handleReplyReview(
                                              replyReview,
                                              ele?.id,
                                              GetData
                                            )
                                          }
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {ele?.replies?.map((item) => {
                                  return (
                                    <div className="review-block review-reply-block d-flex">
                                      <div className="review-image">
                                        <Avatar
                                          as
                                          img
                                          name={state?.auth?.user?.name
                                            ?.charAt(0)
                                            .toUpperCase()}
                                          size="40"
                                          className="img-fluid"
                                          round={true}
                                        />
                                      </div>
                                      <div className="review-detail flex-fill">
                                        <div className="top-info d-flex justify-content-between">
                                          <div className="reviewer-name-date">
                                            <h4 className="mb-1">
                                              {
                                                state?.auth?.user?.name?.split(
                                                  " "
                                                )[0]
                                              }
                                            </h4>
                                            <p>
                                              {" "}
                                              <Moment
                                                date={item?.createdAt}
                                                format="DD MMM, YYYY"
                                              />
                                            </p>
                                          </div>
                                          <div className="star-rating d-flex align-items-center"></div>
                                        </div>
                                        <div className="bottom-info">
                                          <p>{item?.content}</p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        );
                      })}

                    {!isHost && state?.auth?.user?.role === "regular" && (
                      <div className="reply-block">
                        <h3>Add Your Review</h3>
                        <div className="form-group d-flex align-items-center">
                          <p>
                            Rate Here{" "}
                            <Rater
                              total={5}
                              rating={rating}
                              onRating={({ rating }) => setRating(rating)}
                            />
                          </p>
                        </div>
                        <div className="form-group d-flex flex-column">
                          <label>Treated here from</label>
                          <input
                            type="date"
                            max={
                              toDate ? toDate : new Date().toJSON().slice(0, 10)
                            }
                            className="form-control"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group d-flex flex-column">
                          <label>Treated here to</label>
                          <input
                            type="date"
                            min={fromDate}
                            max={new Date().toJSON().slice(0, 10)}
                            className="form-control"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group d-flex flex-column">
                          <label>Your review</label>
                          <textarea
                            placeholder="Add your review here"
                            name="review"
                            maxlength="500"
                            rows="4"
                            class="form-control"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="form-group d-flex mb-0">
                          <button
                            type="button"
                            onClick={() => onSubmitReview()}
                            disabled={!rating || comment.trim() === ""}
                            className="btn btn-primary radius-20 justify-content-center mt-4 mb-4 mb-md-0"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {
                  <div className="facility-content">
                    {(isHost && GetData?.subscriptionDetail === null) ||
                    state?.living?.reviewsForAnonymousUser.length == 0 ? (
                      <>
                        <div className="review-no-records position-relative text-center my-4 pb-2">
                          <div className="review-header d-flex align-items-center mb-4">
                            <h3 className="my-0 mr-4">Reviews</h3>
                          </div>
                          <img src={noReviewImg} className="mb-5 mt-4" />
                          <h2>OOPS, NO REVIEWS YET...</h2>
                          <p>Reviews will show up here</p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                }
              </div>

              {recommendedFacilities?.length > 0 &&
                GetData?.subscriptionDetail && (
                  <div className="col-md-7 col-lg-4">
                    <RecommendedFacility
                      settings1={settings1}
                      recommendedSlider={recommendedSlider}
                      recommendedFacilities={recommendedFacilities}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HostReply;
