import React, { Component } from "react";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { connect } from "react-redux";
import BackButtonComponent from "../../components/other/back-button.component";
import { livingAction, homeAction } from "../../actions";
import UploadPhotosComponent from "../../components/upload-photos/upload-photos.component";
import CustomDropDown from "../../components/dropdown/dropdown.component";
import CustomCheckBox from "../../components/checkbox/checkbox.component";
import Select from "react-select";
import facilityIcon from "../../assets/images/facilities.svg";
import {
  PaymentType,
  TwelveStepMeetingOptions,
  CLIENT_RESTRICTION_OPTIONS,
  PropertyOptions,
  sobrietyRequirement,
  CurfewOptions,
  MaxOccupancy,
  PropertyType,
  PaymentPeriod,
  PropertyServing,
  SobrietyRequirement,
  BedroomRange,
  OccupancyMeter,
} from "../../constants/server.constant";
import { checkInternet, convertEnumToArray } from "../../utils/common.util";
import AvailableOptionCard from "../../components/cards/available-option.card";
import GoogleMapComponent from "../../components/map/google-map.component";
import FromToTimeline from "../../components/from-to-timeline/from-to-timeline.component";
import { Field, Form, Formik, ErrorMessage } from "formik";
import TourDatePicker from "./select-tour-dates";
import backImgBlack from "../../assets/images/back.svg";
import Slider from "rc-slider";
import { Button, Toast, Form as RBForm, FormCheck } from "react-bootstrap";
import { entries, isEmpty } from "lodash";
import LocationSearchInput from "../../components/map/location-search-input.component";
import { states } from "../../constants/states";
import { Modal } from "react-bootstrap";
import MediaQuery from "react-responsive";
import rightArrowImg from "../../assets/images/arrow_right-24px.svg";
import { getGeoLocation, checkHasNavigationPermission } from "../../utils";

// form schama
import {
  addLivingSchemaStep1,
  addLivingSchemaStep2,
} from "./add-living-schema";

// default values
import {
  DEFAULT_VALUES_STEP1,
  DEFAULT_VALUES_STEP2,
  DEFAULT_VALUES_STEP3,
} from "../../constants/add-living-default-values.constant";
import { Fragment } from "react";

import lodash from "lodash";

import { bindActionCreators } from "redux";

import PromiseModal from "../../components/modal/promise-modal.component";
import axios from "axios";
import { SERVER_APIS } from "../../constants";
import moment from "moment";
import ReactSelect from "../../components/dropdown/reactSelect.component";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
let uploadedMediaIds = [];
let data = { media: [] };
const myRef = React.createRef();
let mediaIds = [];

class AddLiving extends Component {
  errorPopUpStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    color: "#FF5F5F",
    alignItems: "center",
  };
  houseAndToursContainer = React.createRef();
  recommendedContainer = React.createRef();
  static contextType = SpinnerContext;
  is_Mounted = false;
  static defaultProps = {};

  formTwoValues;

  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errors: [],
      currentStep: 1,
      isRecommended: false,
      recommendId: [],
      stepOneForm: null,
      stepTwoForm: null,
      stepThreeForm: null,
      different_hours_per_day: false,
      livingId: this.props.match.params?.id,
      living: this.props.hostSelectedLiving,
      editMode: this.props.match.params?.id ? true : false,
      coordinates: {
        lat: 42.42254053329316,
        lng: -71.00966335367495,
      },
      showErrorPopup: false,
      formErrors: null,
      showHouseAndTours: false,
      showRecommended: false,
      houseAndToursData: {
        isAvailableForTours: false,
        isAvailableCurrently: false,
        availableForToursDates: [],
      },
      notUploadedCount: 0,
      uploadedCount: 0,
      imageUploadedOutOfTotalImage: 0,
      imageUploadErrors: [],
      selectedRecommendedID: [],
      SubscriptionOpt: "Opt-Out",
      planPrice: "",
      amount: "",
      interval: "monthly",
      isSubscribed: false,
      // livingIdForSubscribe: '',
      isShowCancel: false,
      popUpMessage: "",
      showSuccessPopup: false,
      showErrorPopup: false,
      subscriptionId: "",
      isShowBasic: false,
      showUpgradeModel: false,
      isShowButton: false,
      houseProperty: "",
    };
  }

  componentWillUnmount() {
    this.is_Mounted = false;
  }

  componentDidMount() {
    this.is_Mounted = true;
    const { match } = this.props;
    this.context.setStickyFooter(false);
    uploadedMediaIds = [];
    mediaIds = [];
    const livingId = match.params?.id;
    this.props.fetchPlan();
    this.props.getAllFacilityList();

    if (livingId) {
      // call API - grab response
      if (!this.props.isFetchingLiving.data) {
        this.context.showLoader("Getting Sober Living Details ...");
        this.props
          .fetchLivingById(livingId)
          .then((res) => {
            if (this.is_Mounted) {
              const data = res[0].data;
              // set states - map values

              const stepOneForm = this.initializeFormValues(1, livingId, data);
              const stepTwoForm = this.initializeFormValues(2, livingId, data);
              const stepThreeForm = this.initializeFormValues(
                3,
                livingId,
                data
              );
              this.setState({
                isRecommended: data?.isPPCSubscribed || data?.isSubscribed,
              });
              const different_hours_per_day =
                data.houseRules.curfewTime.daily === "Different hours per day"
                  ? true
                  : false;
              this.setState(
                {
                  stepOneForm,
                  stepTwoForm,
                  stepThreeForm,
                  different_hours_per_day,
                  livingId,
                  houseAndToursData: {
                    isAvailableForTours: stepOneForm.isAvailableForTours,
                    isAvailableCurrently: stepOneForm.isAvailableCurrently,
                    availableForToursDates: stepOneForm.availableForToursDates,
                  },
                },
                (state) => {
                  console.log("in callback", state);
                }
              );
            } else {
              window.location.reload();
            }
          })
          .catch((err) => {
            // If API call fails then set the default values
          })
          .finally(() => {
            if (this.is_Mounted) this.context.hideLoader();
          });
      }
      this.getSelectedRecommended();
    }
  }

  getSelectedRecommended = async (setFieldValue) => {
    const { match } = this.props;
    const livingId = match.params?.id;

    try {
      const response = await axios.get(
        `${SERVER_APIS.recommendedFacilityListById}/${livingId}`
      );
      const formattedData = response.data.data.map((item) => ({
        label: item.name + ", " + item?.city + ", " + item?.state,
        value: item.id,
      }));
      this.setState({ selectedRecommendedID: formattedData });
      setFieldValue(
        "recommendId",
        formattedData.map((item) => item?.value)
      );
    } catch (error) {
      console.error("Error fetching recommended house data:", error);
    }
  };

  handleRecommendedFacility = (selectedOptions, setFieldValue) => {
    this.setState({ selectedRecommendedID: selectedOptions });
    setFieldValue(
      "recommendId",
      selectedOptions.map((item) => item?.value)
    );
  };

  setPlanstate = () => {
    this.setState(
      {
        planPrice: this.props.subscribePlans?.[0]?.id,
        amount: this.props.subscribePlans?.[0]?.unit_amount,
        interval: this.props.subscribePlans?.[0]?.recurring.interval,
        currentStep: 3,
      },
      () => {
        this.context.hideLoader();
      }
    );
    window.scrollTo(0, 0);
  };

  cancelSubscription = () => {
    this.context.showLoader("");
    const value = {
      subscriptionId: this.state.subscriptionId,
      userId: this.props.user?.id,
    };
    var authOptions = {
      method: "post",
      url: `${SERVER_APIS.Living_URL}/${this.state.livingId}/cancel/subscription`,
      data: value,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(authOptions).then(async (res) => {
      try {
        this.setState({
          popUpMessage: res.data.message,
          isShowCancel: false,
          showSuccessPopup: true,
        });
        this.context.hideLoader();
      } catch (e) {
        this.context.hideLoader();
        setTimeout(() => {
          this.setState({
            showErrorPopup: false,
            isShowCancel: false,
            popUpMessage: e.error,
          });
        }, 1000);
      }
    });
  };

  // call only when in edit mode
  initializeFormValues(formNumber, livingId, data) {
    // GET BY ID
    let details = { ...data };
    this.setState({
      isSubscribed: details?.isSubscribed,
      subscriptionId: details?.subscriptionDetail?.subscriptionId,
      planPrice: details?.subscriptionDetail?.priceId,
      interval: details?.subscriptionDetail?.interval,
      amount: details?.subscriptionDetail?.price,
      houseProperty: details,
    });
    // if (isEmpty(details)) {
    //     this.props.fetchLivingById(livingId).finally(() => {
    //         details = { ...this.props.properties?.get(livingId) };
    //     });
    // }

    // const currentValues = this.props.hostSelectedLiving[0];
    const currentValues = details;
    const formValues = {};
    const defaultObj =
      formNumber === 1 ? DEFAULT_VALUES_STEP1 : DEFAULT_VALUES_STEP2;

    for (const key in currentValues) {
      const hasProperty = defaultObj.hasOwnProperty(key);
      if (hasProperty) {
        if (key === "rentRange") {
          formValues[key] = {
            from:
              currentValues[key]?.from ??
              parseInt(process.env.REACT_APP_MIN_PRICE_RANGE),
            to:
              currentValues[key]?.to ??
              parseInt(process.env.REACT_APP_MAX_PRICE_RANGE),
          };
        } else if (key === "houseRules") {
          if (currentValues[key].daily === "") {
            this.setState({ different_hours_per_day: true });
          } else {
            this.setState({ different_hours_per_day: false });
          }
          formValues[key] = currentValues[key];
        } else {
          formValues[key] = currentValues[key];
        }
      }
    }

    if (formNumber === 1) {
      // const { cityName, country, stateLong } = formValues.geocodingResult
      // set city name value
      formValues.rentCost = formValues.rentCost ?? "";
    }
    if (formNumber === 2) {
      const {
        guestVisitingHours,
        mustBeOutOfHouseDateRange,
        quietHours,
        curfewTime,
      } = formValues.houseRules;
      // is value of "from" and "to" time is null then assign them '' string
      // Find better way to do this
      if (!guestVisitingHours.from) {
        formValues.houseRules.guestVisitingHours.from = "";
      }
      if (!guestVisitingHours.to) {
        formValues.houseRules.guestVisitingHours.to = "";
      }
      if (!mustBeOutOfHouseDateRange.from) {
        formValues.houseRules.mustBeOutOfHouseDateRange.from = "";
      }
      if (!mustBeOutOfHouseDateRange.to) {
        formValues.houseRules.mustBeOutOfHouseDateRange.to = "";
      }
      if (!quietHours.from) {
        formValues.houseRules.quietHours.from = "";
      }
      if (!quietHours.to) {
        formValues.houseRules.quietHours.to = "";
      }

      // isOvernightPassesApprovedInAdvance and isClientRestrictionFor are not stored on database
      formValues.houseRules.isOvernightPassesApprovedInAdvance =
        currentValues.overnightPassesApprovedInAdvance == 0 ? false : true;
      formValues.houseRules.isClientRestrictionFor =
        currentValues.clientRestrictionFor == "NONE" ? false : true;
    }

    return formValues;
  }

  setDifferentHourStatus(livingId) {
    let details = { ...this.props.properties?.get(livingId) };
    let status;
    if (isEmpty(details)) {
      status = false;
    } else {
      status =
        details.property.houseRules.curfewTime?.daily === "" ? true : false;
    }
    return status;
  }

  availableOptionSelectHandler = (options, option) => {
    // const { options } = this.state;
    let isSelected;
    // if already selected
    isSelected = options.includes(option);

    let updated;
    if (isSelected) {
      const op = [...options];
      const index = op.indexOf(option);
      op.splice(index, 1);
      updated = op;
    } else {
      updated = [...options, option];
    }
    // this.setState({ options: updated })
    return updated;
  };

  checkInternetForPhotoUploadRetry = async (position, length) => {
    try {
      const modal = myRef.current;
      if (!navigator.onLine) {
        let retry = await modal.show(position, length);
        if (retry) {
          let retryStatus = await this.checkInternetForPhotoUploadRetry(
            position,
            length
          );
          return retryStatus;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } catch (err) {
      console.log("checkInternetForPhotoUploadRetry Error", err);
    }
  };

  async uploadPhotos(data) {
    const uploaded = data.photos.filter((d) => d.path !== undefined);
    this.setState({ imageUploadErrors: [] });

    let chosenImage = data.photos.filter((d) => d.path === undefined);

    const notUploaded = data.photos
      .filter((d) => d.path === undefined)
      .filter((image) => {
        return uploadedMediaIds.indexOf(image.id) == -1;
      });

    if (notUploaded?.length > 0) {
      try {
        const isInternetAvailable = await checkInternet();
        if (isInternetAvailable) {
          for (const photo of notUploaded) {
            let index = notUploaded.indexOf(photo);
            this.context.showLoader(
              notUploaded.length > 1
                ? `Uploading your sober living media ${index + 1} of ${
                    notUploaded.length
                  } photos`
                : `Uploading your sober living media`
            );
            const formData = new FormData();
            formData.append("photos", photo);
            // debugger

            try {
              let retryStatus = await this.checkInternetForPhotoUploadRetry(
                photo.position,
                chosenImage?.length
              );

              let mediaId;
              if (retryStatus) {
                mediaId = await this.props.uploadMedia(formData);
              } else {
                this.setState({
                  imageUploadErrors: this.state.imageUploadErrors.concat({
                    position: photo.position,
                    message: "Network error",
                  }),
                });
                continue;
              }

              if (!mediaId) {
                this.context.hideLoader();

                return;
              } else {
                uploadedMediaIds = uploadedMediaIds.concat(photo.id);

                mediaIds = [
                  ...mediaIds,
                  { mediaId: mediaId[0].id, position: photo.position },
                ];
                if (index == notUploaded.length - 1) {
                  this.context.hideLoader();
                  this.setState({ errors: [], showError: false });
                }
              }
            } catch (err) {
              this.setState({
                imageUploadErrors: this.state.imageUploadErrors.concat({
                  position: photo.position,
                  message: err.message,
                }),
              });
            }
          }
        } else {
          this.setState({
            showErrorPopup: true,
            formErrors: {
              "Please check your internet connection":
                "Please check your internet connection",
            },
            ...this.state.formErrors,
          });
          setTimeout(() => {
            this.setState({ showErrorPopup: false });
          }, 2000);
        }
      } catch (err) {}
    }
    // assign id to only the not uploaded photos
    if (mediaIds.length > 0) {
      let lastIndex = uploaded.length;
      let newUploadedPhotos = chosenImage
        .filter((image) => {
          return uploadedMediaIds.indexOf(image.id) != -1;
        })
        .map((photo, index) => {
          let mediaId = mediaIds.find(
            (mediaId) => mediaId.position == photo.position
          ).mediaId;
          return {
            id: mediaId,
            position: photo.position,
          };
        });
      if (data.media?.length > 0) {
        let photos = [...uploaded, ...data.media, ...newUploadedPhotos].sort(
          (a, b) => a.position - b.position
        );
        data.media = lodash.uniqBy(photos, "id");
        this.setState({ uploadedCount: data.media.length });
        this.setState({
          imageUploadedOutOfTotalImage: `${newUploadedPhotos.length}/${notUploaded.length}`,
        });
      } else {
        let photos = [...uploaded, ...newUploadedPhotos].sort(
          (a, b) => a.position - b.position
        );
        data.media = lodash.uniqBy(photos, "id");
        this.setState({ uploadedCount: data.media.length });
        this.setState({
          imageUploadedOutOfTotalImage: `${newUploadedPhotos.length}/${notUploaded.length}`,
        });
      }
    } else {
      let photos = [...uploaded].sort((a, b) => a.position - b.position);
      data.media = lodash.uniqBy(photos, "id");
      this.setState({ uploadedCount: data.media.length });
    }
    let notUploadedCount = data.photos
      .filter((d) => d.path === undefined)
      .filter((image) => {
        return uploadedMediaIds.indexOf(image.id) == -1;
      });
    if (notUploadedCount.length == 0) {
      this.context.hideLoader();
      this.setState({ currentStep: 2, stepOneForm: data });
      this.setState({ notUploadedCount: 0 });
      window.scroll(0, 0);
    }
    if (notUploadedCount.length > 0) {
      this.context.hideLoader();
      this.setState({ stepOneForm: data });
      this.setState({ notUploadedCount: notUploadedCount.length });
    }
  }

  async submitAddLiving() {
    const { stepOneForm, stepTwoForm, editMode, livingId } = this.state;

    const data = { ...stepOneForm, ...stepTwoForm };

    // upload photos
    if (editMode) {
      this.context.showLoader("Updating your sober living");
      this.context.hideLoader();
    } else {
      this.context.showLoader("Adding your sober living");
      this.context.hideLoader();
      // this.addsoberData(data)
    }

    if (this.state.different_hours_per_day) {
      data.houseRules.curfewTime.daily = "Different hours per day";
    }

    if (data.paymentType === "fixed") {
      delete data.rentRange;
    }
    if (data.paymentType === "range") {
      delete data.rentCost;
    }
    delete data.geocodingResult;
    if (editMode) {
      if (!this.props.isEditingLiving) {
        this.props
          .editLiving(livingId, data)
          .then((res) => {
            this.props.history.goBack();
          })
          .catch((err) => {
            err.data = err.data ? err.data : ["Internal server error"];
            this.setState({ errors: err.data, showError: true });
          })
          .finally(() => {
            this.context.setSober("addSubscriptionPage");
            this.context.hideLoader();
          });
      }
      return;
    } else {
      const updatedData = JSON.parse(JSON.stringify(data));

      if (updatedData.aptSuite === "") {
        delete updatedData.aptSuite;
      }
      if (updatedData.rentCost === "") {
        updatedData.rentCost = 0;
      }
      if (!updatedData.houseRules.mustBeOutOfHouse) {
        updatedData.houseRules.mustBeOutOfHouseDateRange = {
          from: null,
          to: null,
        };
      }
      if (!updatedData.houseRules.haveGuestVisitingHours) {
        updatedData.houseRules.guestVisitingHours = {
          from: null,
          to: null,
        };
      }
      if (!updatedData.houseRules.haveQuietHours) {
        updatedData.houseRules.quietHours = {
          from: null,
          to: null,
        };
      }

      if (!this.props.isAddingLiving) {
        this.context.showLoader("Adding your sober living");
        if (this.props.Soberliving.id != undefined) {
          this.props
            .editLiving(this.props.Soberliving.id, data)
            .then((res) => {
              this.context.showLoader("updating your sober living");
              this.setState(
                {
                  currentStep: 3,
                },
                () => {
                  this.setPlanstate();
                  this.context.hideLoader();
                }
              );
              window.scrollTo(0, 0);
            })
            .catch((err) => {
              err.data = err.data ? err.data : ["Internal server error"];
              this.setState({ errors: err.data, showError: true });
            })
            .finally(() => {
              this.context.setSober("addSubscriptionPage");
              this.context.hideLoader();
            });
        } else {
          this.context.showLoader("Adding your sober living");
          this.props
            .addLiving(updatedData)
            .then((res) => {
              this.context.showLoader("Adding your sober living");
              this.setState(
                {
                  currentStep: 3,
                },
                () => {
                  this.setPlanstate();
                  this.context.hideLoader();
                }
              );
            })
            .catch((err) => {
              err.data = err.data ? err.data : ["Internal server error"];
              this.setState({ errors: err.data, showError: true });
            })
            .finally(() => {
              this.context.setSober("addSubscriptionPage");
              this.setPlanstate();
              this.setState(
                {
                  currentStep: 3,
                },
                () => {
                  this.setPlanstate();
                  this.context.hideLoader();
                }
              );
              window.scrollTo(0, 0);
            });
          this.context.showLoader("Adding your sober living");
          this.setState(
            {
              currentStep: 3,
            },
            () => {
              this.setPlanstate();
              this.context.hideLoader();
            }
          );
          window.scrollTo(0, 0);
        }
      }
    }
  }

  renderInOneRow = (comp1, comp2, wrapComp2 = true) => {
    return (
      <div className="row">
        <div className="col-md-6">{comp1}</div>
        {wrapComp2 ? <div className="col-md-6">{comp2}</div> : <>{comp2}</>}
      </div>
    );
  };

  choosenAmenities = (amenities) => {
    const displayNames = {
      internet: "Internet",
      fridge: "Fridge",
      tv: "TV",
      "a/c": "AC",
      washer: "Washer",
      dryer: "Dryer",
      towels: "Towels",
      bedding: "Bedding",
      parking: "Parking",
      heating: "Heating",
      pool: "Pool",
      jacuzzi: "Jacuzzi",
      chef: "Chef",
      noSmoking: "No Smoking",
    };
    amenities = amenities.map((a) => {
      return displayNames[a];
    });
    return amenities.join(", ");
  };

  errors = {};
  moveErrorsToParent = (errObj) => {
    Object.keys(errObj).forEach((e) => {
      if (typeof errObj[e] === "object") {
        this.moveErrorsToParent(errObj[e]);
      } else {
        this.errors[e] = errObj[e];
      }
    });
    return this.errors;
  };

  stepOneForm = ({
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  }) => {
    const { showHouseAndTours, houseAndToursData, showRecommended } =
      this.state;
    const handleSubmitLocal = (e, contiue) => {
      if (!isEmpty(errors)) {
        this.setState({
          showErrorPopup: true,
          formErrors: this.moveErrorsToParent(errors),
        });
        this.errors = {};
        setTimeout(() => {
          this.setState({ showErrorPopup: false });
        }, 2500);
      }
      if (contiue && isEmpty(errors)) {
        this.setState({ currentStep: 2 });
        window.scroll(0, 0);
      } else {
        handleSubmit(e);
      }
    };
    if (showHouseAndTours) {
      return (
        <div>
          <a
            onClick={() => {
              this.setState(
                {
                  showHouseAndTours: false,
                  houseAndToursData: {
                    ...houseAndToursData,
                    isAvailableCurrently: values.isAvailableCurrently,
                    isAvailableForTours: values.isAvailableForTours,
                    availableForToursDates: values.availableForToursDates,
                  },
                },
                () => {
                  this.houseAndToursContainer =
                    this.houseAndToursContainer?.current?.scrollIntoView();
                }
              );
            }}
          >
            <img className="mt-0 mt-md-5" src={backImgBlack} alt="" />
            <h2 className="mt-3">Availability</h2>
          </a>
          {this.renderInOneRow(
            <div className="form-group dflex-form no-border">
              <label>Do you have available beds currently?</label>
              <FormCheck custom type="switch">
                <FormCheck.Input
                  isValid={houseAndToursData.isAvailableCurrently}
                  checked={houseAndToursData.isAvailableCurrently}
                  onChange={() => {}}
                />
                <FormCheck.Label
                  onClick={() => {
                    this.setState({
                      houseAndToursData: {
                        ...houseAndToursData,
                        isAvailableCurrently:
                          !houseAndToursData.isAvailableCurrently,
                      },
                    });
                  }}
                ></FormCheck.Label>
              </FormCheck>
            </div>,
            <div className="form-group dflex-form no-border">
              <label>
                Would you like{" "}
                {values.name ? <strong>{values.name}</strong> : "your living"}{" "}
                to be available for tours?
              </label>
              <FormCheck custom type="switch">
                <FormCheck.Input
                  isValid={houseAndToursData.isAvailableForTours}
                  checked={houseAndToursData.isAvailableForTours}
                  onChange={() => {}}
                />
                <FormCheck.Label
                  onClick={() => {
                    this.setState({
                      houseAndToursData: {
                        ...houseAndToursData,
                        isAvailableForTours:
                          !houseAndToursData.isAvailableForTours,
                      },
                    });
                  }}
                ></FormCheck.Label>
              </FormCheck>
            </div>
          )}
          {houseAndToursData.isAvailableForTours && (
            <Fragment>
              <label>
                Choose dates and times when you can receive tours at{" "}
                {values.name ? <strong>{values.name}</strong> : "your living"}
              </label>
              <TourDatePicker
                enabled={houseAndToursData.isAvailableForTours}
                mobileView={true}
                handleChange={(values) => {
                  this.setState({
                    houseAndToursData: {
                      ...houseAndToursData,
                      availableForToursDates: values,
                    },
                  });
                }}
                values={houseAndToursData.availableForToursDates}
              />
            </Fragment>
          )}
          <div className="d-flex flex-column flex-md-row justify-content-center mt-4">
            <button
              type="button"
              onClick={(e) => {
                setFieldValue(
                  "isAvailableCurrently",
                  houseAndToursData.isAvailableCurrently,
                  true
                );
                setFieldValue(
                  "isAvailableForTours",
                  houseAndToursData.isAvailableForTours,
                  true
                );
                setFieldValue(
                  "availableForToursDates",
                  houseAndToursData.availableForToursDates
                );
                this.setState({ showHouseAndTours: false });
              }}
              className="btn btn-primary radius-20 btn-min-width"
            >
              Save
            </button>
          </div>
        </div>
      );
    }

    return (
      <>
        <Form onSubmit={handleSubmit}>
          <div className="taste-recovery-form">
            {/* name field */}
            <div className="form-group mt-3">
              <label>
                Name<span className="mandatory">*</span>
              </label>
              <Field
                component="input"
                value={values.name}
                name="name"
                className="form-control"
                placeholder="Name"
              />
              {/* {touched.name && errors.name && <ErrorMessage component="div" component="div" className="error-message" name="name" />} */}
            </div>

            {/* upload photos */}
            <div className="form-group">
              <label>
                Photos<span className="mandatory">*</span>
              </label>
              <UploadPhotosComponent
                name="photos"
                selectedPhotos={values.photos}
                deleteImage={(data) => {
                  setFieldValue("photos", data, true);
                  this.setState({ notUploadedCount: 0 });
                  this.setState({ uploadedCount: 0 });
                }}
                addImage={(data) => {
                  setFieldValue("photos", data, true);
                  this.setState({ notUploadedCount: 0 });
                  this.setState({ uploadedCount: 0 });
                }}
                draggable={true}
              />
              <p>
                <span className="mandatory">*</span> Maximum 10 photos
              </p>
              {/* {touched.photos && errors.photos && <ErrorMessage component="div" className="error-message" name="photos" />} */}
            </div>

            {/* Input field - ABOUT */}
            <div className="form-group mt-3">
              <label>
                About<span className="mandatory">*</span>
              </label>
              <Field
                component="textarea"
                value={values.about}
                maxLength={process.env.REACT_APP_ABOUT_MAX_LENGTH}
                rows="5"
                name="about"
                className="form-control"
                placeholder="About"
              ></Field>
              {values.about.length}/{process.env.REACT_APP_ABOUT_MAX_LENGTH}
              {/* {touched.about && errors.about && <ErrorMessage component="div" component="div" className="error-message" name="about" />} */}
            </div>

            <div className="form-group mt-3">
              <label>
                Website Link{" "}
                <span style={{ fontSize: "12px", color: "red" }}>
                  (*Pro Feature)
                </span>
              </label>
              <Field
                component="input"
                type={"url"}
                value={values.webUrl || ""}
                onChange={(e) => {
                  let newUrl = e.target.value;
              
                  // Avoid appending 'https://www.' if it's already present
                  if (newUrl.startsWith("https://www.") ||newUrl.startsWith("https://www.") ) {
                    // Strip any existing protocol or 'www.' and prepend 'https://www.'
                    const strippedUrl = newUrl.replace(/^https?:\/\/(www\.)?/, "");
                  
                    setFieldValue("webUrl", `https://www.${strippedUrl}`);
                  }}}
                name="webUrl"
                className="form-control"
                placeholder="https://www.tasterecovery.com/"
                maxLength={process.env.REACT_APP_WEBSITE_MAX_LENGTH}
              />
              {values.webUrl === null ? 0 : values.webUrl.length}/
              {process.env.REACT_APP_WEBSITE_MAX_LENGTH}
            </div>
            {/* Input field - PHONE NUMBER */}
            <div className="form-group mt-3">
              <label>
                Phone Number{" "}
                <span style={{ fontSize: "12px", color: "red" }}>
                  (*Pro Feature)
                </span>
              </label>
              <Field
                component="input"
                type={"number"}
                value={values.phoneNumber}
                onWheel={(e) => e.target.blur()}
                name="phoneNumber"
                className="form-control"
              />
            </div>

            {/* Available options container */}
            <div className="form-group">
              <label>Amenities</label>
              <ul className="available-options">
                {convertEnumToArray(PropertyOptions).map((p_option, index) => (
                  <div key={index}>
                    <AvailableOptionCard
                      selectedOptions={values.options || []}
                      currentOption={p_option.value}
                      selectHandler={(option) => {
                        const data = this.availableOptionSelectHandler(
                          values.options,
                          option
                        );
                        setFieldValue("options", data, true);
                      }}
                    />
                  </div>
                ))}
              </ul>
              <p className="selected-amenities">
                ( You chose: {this.choosenAmenities(values.options)} )
              </p>
            </div>

            {this.renderInOneRow(
              <div className="form-group">
                <CustomDropDown
                  name="type"
                  value={values.type}
                  label="Type of Property"
                  placeholder="Type of property"
                  options={convertEnumToArray(PropertyType)}
                  handleChange={handleChange}
                  mandatory="true"
                  // error={(touched.type && errors.type) ? errors.type : ''}
                />
              </div>,
              <div className="form-group">
                <CustomDropDown
                  name={"paymentPeriod"}
                  value={values.paymentPeriod}
                  label="Weekly or monthly payment"
                  placeholder="Weekly or monthly payment"
                  options={convertEnumToArray(PaymentPeriod)}
                  handleChange={handleChange}
                  mandatory="true"
                  // error={(touched.paymentPeriod && errors.paymentPeriod) ? errors.paymentPeriod : ''}
                />
              </div>
            )}

            {this.renderInOneRow(
              <div className="form-group">
                <CustomDropDown
                  name={"paymentType"}
                  value={values.paymentType}
                  label="Fixed price or range?"
                  mandatory="true"
                  options={convertEnumToArray(PaymentType)}
                  handleChange={handleChange}
                  // error={(touched.paymentType && errors.paymentType) ? errors.paymentType : ''}
                />
              </div>,
              <>
                {values.paymentType === "fixed" ? (
                  <div className="form-group">
                    <label>
                      What is your payment($)?
                      <span className="mandatory">*</span>
                    </label>
                    <Field
                      component="input"
                      type={"number"}
                      value={values.rentCost}
                      name="rentCost"
                      className="form-control"
                      placeholder="$350"
                    />

                    {/* {touched.rentCost && errors.rentCost && <ErrorMessage component="div" className="error-message" name="rentCost" />} */}
                  </div>
                ) : (
                  <div className="form-group">
                    <label>
                      What is your payment($)?
                      <span className="mandatory">*</span>
                    </label>{" "}
                    {/* <p className="rent-range-text">${values?.rentRange?.from} - ${values?.rentRange?.to}</p> */}
                    {/* <Range
                                        min={parseInt(process.env.REACT_APP_MIN_PRICE_RANGE)}
                                        max={parseInt(process.env.REACT_APP_MAX_PRICE_RANGE)}
                                        defaultValue={[0, 0]}
                                        value={
                                            [values?.rentRange?.from || parseInt(process.env.REACT_APP_MIN_PRICE_RANGE),
                                            values?.rentRange?.to || parseInt(process.env.REACT_APP_MAX_PRICE_RANGE)]
                                        }
                                        onChange={(value) => {
                                            setFieldValue('rentRange.from', value[0]);
                                            setFieldValue('rentRange.to', value[1])
                                        }}
                                        tipFormatter={(value) => ` ${value}$ `}
                                        step={5}
                                    /> */}
                    {this.renderInOneRow(
                      <div className="form-group mt-n2">
                        <Field
                          component="input"
                          type={"number"}
                          value={values?.rentRange?.from}
                          name="rentRange.from"
                          className="form-control"
                          placeholder="Min.Price $0"
                        />{" "}
                      </div>,
                      <div className="form-group mt-n2">
                        <Field
                          component="input"
                          type={"number"}
                          value={values?.rentRange?.to}
                          name="rentRange.to"
                          className="form-control"
                          placeholder="Max.Price $30000"
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            {this.renderInOneRow(
              <CustomCheckBox
                name="offersScholarships"
                value={values.offersScholarships}
                className="form-group dflex-form"
                label={"Offers scholarships"}
                id="offersScholarships"
              />,
              <CustomCheckBox
                name="offersPaymentPlans"
                value={values.offersPaymentPlans}
                className="form-group dflex-form"
                label={"Offers payment plans"}
                id="offersPaymentPlans"
              />
            )}

            {this.renderInOneRow(
              <div className="form-group">
                <CustomDropDown
                  name={"serving"}
                  value={values.serving}
                  label="This house is serving"
                  mandatory="true"
                  placeholder="This house is serving"
                  options={convertEnumToArray(PropertyServing)}
                  handleChange={handleChange}
                  // error={(touched.serving && errors.serving) ? errors.serving : ''}
                />
              </div>,
              <></>
            )}

            {/* {this.renderInOneRow()} */}
            {this.renderInOneRow(
              <div className="form-group">
                <label>
                  Street Address<span className="mandatory">*</span>
                </label>
                <p className="secondary-label">
                  (not visible in sober living profile details)
                </p>
                <Field
                  component="input"
                  name="address"
                  className="form-control"
                  placeholder="Street Address"
                />
                {/* {touched.address && errors.address && <ErrorMessage component="div" className="error-message" name="address" />} */}
              </div>,
              <div className="form-group">
                <label>Apt Suite</label>
                <p className="secondary-label">
                  (not visible in sober living profile details)
                </p>
                <Field
                  component="input"
                  name="aptSuite"
                  className="form-control"
                  placeholder="Apt Suite"
                />
              </div>
            )}

            {this.renderInOneRow(
              <div className="form-group">
                <label>
                  City & State<span className="mandatory">*</span>
                </label>
                <LocationSearchInput
                  placeholder="City & State"
                  dropdownStyle="city-dropdown"
                  fetchStateZip={true}
                  value={
                    values.geocodingResult.cityName
                      ? `${values.geocodingResult.cityName} ${
                          values.geocodingResult.stateLong
                            ? `& ${values.geocodingResult.stateLong}`
                            : ""
                        }`
                      : ""
                  }
                  onCitySelect={(res) => {
                    setFieldValue("coordinates.lat", res.lat, true);
                    setFieldValue("coordinates.lng", res.lng, true);
                    setFieldValue(
                      "geocodingResult.cityName",
                      res.cityName,
                      true
                    );
                    if (res.state) {
                      setFieldValue("state", res.state, true);
                      setFieldValue("zipCode", res.zipCode, true);
                    }
                  }}
                />
              </div>,
              <></>
            )}

            <div className="map-view mb-5">
              <p className="map-label">Location (drop a pin on the map)</p>
              <GoogleMapComponent
                className="details-map"
                markers={[values.coordinates || { lat: 0, lng: 0 }]}
                newCenter={{
                  lat: values.coordinates.lat,
                  lng: values.coordinates.lng,
                }}
                handleClick={(e) => {
                  setFieldValue("coordinates.lat", e.lat, true);
                  setFieldValue("coordinates.lng", e.lng, true);
                }}
              />
            </div>

            {this.renderInOneRow(
              <div className="form-group">
                <CustomDropDown
                  name="bedroomQuantity"
                  value={values.bedroomQuantity}
                  handleChange={handleChange}
                  label="How many bedrooms?"
                  mandatory="true"
                  placeholder="How many bedrooms?"
                  options={Array.from(
                    { length: BedroomRange.end },
                    (v, k) => k + 1
                  )}
                />
              </div>,
              <CustomCheckBox
                name="isOfferPrivateBedrooms"
                value={values.isOfferPrivateBedrooms}
                className="form-group dflex-form"
                label={"Do you offer private bedrooms?"}
                id="isOfferPrivateBedrooms"
              />
            )}

            {this.renderInOneRow(
              <div className="form-group">
                <CustomDropDown
                  name="bathroomQuantity"
                  value={values.bathroomQuantity}
                  handleChange={handleChange}
                  label="How many bathrooms?"
                  mandatory="true"
                  placeholder="How many bathrooms?"
                  options={Array.from(
                    { length: BedroomRange.end },
                    (v, k) => k + 1
                  )}
                />
              </div>,
              <CustomCheckBox
                name="isOfferPrivateBathrooms"
                value={values.isOfferPrivateBathrooms}
                className="form-group dflex-form"
                label={"Do you offer private bathrooms?"}
                id="isOfferPrivateBathrooms"
              />
            )}

            {this.renderInOneRow(
              <div className="form-group">
                <CustomDropDown
                  name="maximumOccupancy"
                  value={values.maximumOccupancy}
                  handleChange={handleChange}
                  label="Maximum Occupancy"
                  mandatory="true"
                  placeholder="Maximum Occupancy"
                  options={MaxOccupancy}
                />
              </div>,
              <CustomCheckBox
                className="form-group dflex-form"
                label={
                  "Does your home offer parents to bring their children with them?"
                }
                id="isAllowedWithChildren"
                name="isAllowedWithChildren"
                value={values.isAllowedWithChildren}
              />
            )}

            {this.renderInOneRow(
              <CustomCheckBox
                value={values.isHandicapFriendly}
                name="isHandicapFriendly"
                className="form-group dflex-form"
                label={"Handicap Friendly"}
                id="isHandicapFriendly"
              />,
              <CustomCheckBox
                value={values.isProvideTransportation}
                name="isProvideTransportation"
                className="form-group dflex-form"
                label={"Do you provide transportation?"}
                id="isProvideTransportation"
              />
            )}

            {this.renderInOneRow(
              <CustomCheckBox
                value={values.isSmokeFriendly}
                name="isSmokeFriendly"
                className="form-group dflex-form"
                label={"Smoke friendly"}
                id="isSmokeFriendly"
              />,
              <CustomCheckBox
                value={values.haveTrainedSupportStaff}
                name="haveTrainedSupportStaff"
                className="form-group dflex-form"
                label={"24 hour trained support staff"}
                id="haveTrainedSupportStaff"
              />
            )}

            {this.renderInOneRow(
              <CustomCheckBox
                value={values.staffRequiredHaveAYearOfSobriety}
                name="staffRequiredHaveAYearOfSobriety"
                className="form-group dflex-form"
                label={"Staff is required to have a year of sobriety or more"}
                id="staffRequiredHaveAYearOfSobriety"
              />,
              <CustomCheckBox
                value={values.offersCaseManagement}
                name="offersCaseManagement"
                className="form-group dflex-form"
                label={"Offers case management"}
                id="offersCaseManagement"
              />
            )}

            {this.renderInOneRow(
              <CustomCheckBox
                value={values.havePrivateChef}
                name="havePrivateChef"
                className="form-group dflex-form"
                label={"Private Chef"}
                id="havePrivateChef"
              />,
              <></>
            )}
            <MediaQuery minResolution="2dppx">
              {(matches) => (
                <div
                  id="house-and-tours-div"
                  ref={this.houseAndToursContainer}
                  className="form-group"
                  onClick={() => {
                    if (matches) {
                      this.setState({ showHouseAndTours: true });
                    }
                  }}
                >
                  <div className="house-tours-section-header">
                    <label>
                      <strong>House and tours availability?</strong>
                    </label>
                    {matches && (
                      <span>
                        <img src={rightArrowImg} alt="" />
                      </span>
                    )}
                  </div>
                  {matches && <div className="border-btm" />}
                  <p>Please update "House and tours availability" daily</p>
                </div>
              )}
            </MediaQuery>

            <MediaQuery minResolution="2dppx">
              {(matches) =>
                matches ? null : (
                  <>
                    {this.renderInOneRow(
                      <div className="form-group dflex-form">
                        <label>Do you have available beds currently?</label>
                        <FormCheck custom type="switch">
                          <FormCheck.Input
                            isValid={values.isAvailableCurrently}
                            checked={values.isAvailableCurrently}
                            onChange={() => {}}
                          />
                          <FormCheck.Label
                            onClick={() => {
                              setFieldValue(
                                "isAvailableCurrently",
                                !values.isAvailableCurrently,
                                true
                              );
                            }}
                          ></FormCheck.Label>
                        </FormCheck>
                      </div>,
                      <div className="form-group dflex-form">
                        <label>
                          Would you like sober living to be available for tours?
                          <span style={{ fontSize: "12px", color: "red" }}>
                            (*Pro Feature)
                          </span>
                        </label>
                        <FormCheck custom type="switch">
                          <FormCheck.Input
                            isValid={values.isAvailableForTours}
                            checked={values.isAvailableForTours}
                            onChange={() => {}}
                          />
                          <FormCheck.Label
                            onClick={() => {
                              setFieldValue(
                                "isAvailableForTours",
                                !values.isAvailableForTours,
                                true
                              );
                            }}
                          ></FormCheck.Label>
                        </FormCheck>
                      </div>
                    )}

                    {this.renderInOneRow(
                      values.isAvailableForTours ? (
                        <TourDatePicker
                          handleChange={(values) => {
                            setFieldValue("availableForToursDates", values);
                          }}
                          values={values.availableForToursDates}
                        />
                      ) : (
                        <></>
                      ),
                      <></>
                    )}
                  </>
                )
              }
            </MediaQuery>

            <MediaQuery minResolution="2dppx">
              {(matches) => (
                <div
                  id="house-and-tours-div"
                  ref={this.recommendedContainer}
                  className="form-group"
                  onClick={() => {
                    if (matches) {
                      this.setState({ showRecommended: true });
                    }
                  }}
                >
                  {
                    <div className="form-group">
                      <p className="map-label">Recommend Facilities</p>

                      <div className="house-tours-section-header">
                        <label>
                          <strong>
                            Do You Want To Recommend Facilities ?{" "}
                          </strong>
                        </label>
                      </div>
                      <p>
                        These can be other programs that you directly work with
                        or know they're doing good work and would like to
                        highlight them.{" "}
                      </p>
                      <p>
                        They will show up on your profile as Recommended
                        facilities.
                      </p>
                      <div className="house-tours-section-header">
                        <label>
                          <strong>Listed Facility </strong>
                        </label>
                      </div>
                      <Select
                        value={this.state.selectedRecommendedID}
                        name="recommendId"
                        placeholder="Search by Location or Facility Name"
                        closeMenuOnSelect={false}
                        isMulti
                        options={this.props.recommendedDropDownList?.data?.map(
                          (ele) => ({
                            label: `${ele?.name},${ele?.geocodingResult?.cityName},${ele?.geocodingResult?.stateLong}`,
                            value: ele?.id,
                          })
                        )}
                        onChange={(selectedOptions) =>
                          this.handleRecommendedFacility(
                            selectedOptions,
                            setFieldValue
                          )
                        }
                        formatOptionLabel={(option) =>
                          this.state.selectedRecommendedID.find(
                            (item) => item.value === option.value
                          )
                            ? this.formatOptionLabelSelected(option)
                            : this.formatOptionLabelDropdown(option)
                        }
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "red"
                              : state.isFocused
                              ? "rgba(255, 95, 95, .0784313725490196)"
                              : "white",
                          }),
                        }}
                      />
                    </div>
                  }
                </div>
              )}
            </MediaQuery>
            <Toast
              onClose={() => this.setState({ showError: false })}
              show={this.state.showError}
              className="list-group-item-warning"
            >
              <Toast.Body>
                <ul>
                  {this.state.showError &&
                    this.state.errors?.map(function (item) {
                      return <li key={item}>{item.split(":")[1] || item}</li>;
                    })}
                </ul>
              </Toast.Body>
            </Toast>
            <PromiseModal ref={myRef} />
            <div className="d-flex justify-content-center align-items-center flex-column mt-4">
              {this.state.imageUploadErrors.length > 0 &&
                this.state.imageUploadErrors.map((err, index) => {
                  return (
                    <li key={index}>
                      Image at position number {err.position + 1} failed due to{" "}
                      {err.message}
                    </li>
                  );
                })}
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-center mt-4">
              {/* {Need to display conitnue and retry button only atleast one image uploaded to server and atleast one image failed to upload} */}
              {this.state.notUploadedCount != 0 &&
              this.state.uploadedCount > 0 ? (
                <Fragment>
                  <button
                    type="submit"
                    onClick={(e) => {
                      handleSubmitLocal(e, true);
                    }}
                    className="btn btn-primary radius-20 btn-min-width"
                  >
                    Continue
                  </button>
                  <button
                    type="submit"
                    onClick={(e) => {
                      handleSubmitLocal(e, false);
                    }}
                    className="btn btn-primary radius-20 btn-min-width mt-2 mt-md-0 ml-md-2"
                  >
                    Retry
                  </button>
                </Fragment>
              ) : (
                <button
                  type="submit"
                  onClick={(e) => {
                    handleSubmitLocal(e, false);
                  }}
                  className="btn btn-primary radius-20 btn-min-width"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </Form>
      </>
    );
  };

  // step-2 form

  stepTwoForm = ({
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  }) => {
    const handleSubmitLocalNext = (e, contiue) => {
      if (!isEmpty(errors)) {
        this.setState({
          showErrorPopup: true,
          formErrors: this.moveErrorsToParent(errors),
        });
        this.errors = {};
        setTimeout(() => {
          this.setState({ showErrorPopup: false });
        }, 2500);
      }
      if (contiue && isEmpty(errors)) {
        this.setState({ currentStep: 3 });
        window.scroll(0, 0);
      } else {
        handleSubmit(e);
      }
    };
    const { different_hours_per_day } = this.state;
    return (
      <Form onSubmit={handleSubmit}>
        <div className="taste-recovery-form add-property-from">
          {this.renderInOneRow(
            <CustomCheckBox
              name="mustClimbStairs"
              value={values.mustClimbStairs}
              className="form-group dflex-form"
              label={"Must climb stairs"}
              id="mustClimbStairs"
            />,
            <CustomCheckBox
              name="isPetLiveInProperty"
              value={values.isPetLiveInProperty}
              className="form-group dflex-form"
              label={"Pet(s) live on property"}
              id="isPetLiveInProperty"
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"No parking on property"}
              id="isNoParking"
              name="isNoParking"
              value={values.isNoParking}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Street parking only"}
              id="isStreetParkingOnly"
              name="isStreetParkingOnly"
              value={values.isStreetParkingOnly}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Surveillance or recording devices"}
              id="haveSurveillance"
              name="haveSurveillance"
              value={values.haveSurveillance}
            />,
            <CustomCheckBox
              className="form-group dflex-form position-relative"
              label={"Only accepts cash or checks"}
              id="isOnlyAcceptsCashOrChecks"
              name="isOnlyAcceptsCashOrChecks"
              value={values.isOnlyAcceptsCashOrChecks}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Accepts PayPal"}
              id="isAcceptsPaypal"
              name="isAcceptsPaypal"
              value={values.isAcceptsPaypal}
            />,
            <></>
          )}

          {/* Sober living requirements section */}
          <div className="form-group">
            <label>
              <strong> Sober Living Requirements</strong>
            </label>
            <p>Clickable that will appear on home page</p>
          </div>

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Sobriety Requirement"}
              id="isSobrietyRequirement"
              name="requirements.isSobrietyRequirement"
              value={values.requirements.isSobrietyRequirement}
            />,
            <CustomDropDown
              name="requirements.sobrietyRequirement"
              value={values.requirements.sobrietyRequirement}
              handleChange={handleChange}
              placeholder="Sobriety Requirement"
              mandatory={
                values.requirements.isSobrietyRequirement ? "true" : "false"
              }
              label="Sobriety Requirement"
              options={sobrietyRequirement}
              disabled={!values.requirements.isSobrietyRequirement}
              // error={(touched.requirements?.sobrietyRequirement && errors.requirements?.sobrietyRequirement) ? errors.requirements.sobrietyRequirement : ''}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Gone through a program prior to sober living"}
              id="isGoneThroughAPriorProgram"
              name="requirements.isGoneThroughAPriorProgram"
              value={values.requirements.isGoneThroughAPriorProgram}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Completed detox"}
              id="isCompletedDetox"
              name="requirements.isCompletedDetox"
              value={values.requirements.isCompletedDetox}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Administer meds"}
              id="isAdministerMeds"
              name="requirements.isAdministerMeds"
              value={values.requirements.isAdministerMeds}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Does your house accept MAT"}
              id="isYourHouseAcceptMAT"
              name="requirements.isYourHouseAcceptMAT"
              value={values.requirements.isYourHouseAcceptMAT}
            />
          )}
          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Are you certified by the Sober Living Network"}
              id="isYouCertifiedByTheSoberLivingNetwork"
              name="requirements.isYouCertifiedByTheSoberLivingNetwork"
              value={values.requirements.isYouCertifiedByTheSoberLivingNetwork}
            />,
            <></>
          )}
          {/* House rules section */}
          <div className="form-group">
            <label>
              <strong>House Rules</strong>
            </label>
            <p>Clickable that will appear on home page</p>
          </div>

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Daily Chores"}
              id="dailyChores"
              name="houseRules.dailyChores"
              value={values.houseRules.dailyChores}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Weekly Chores"}
              id="weeklyChores"
              name="houseRules.weeklyChores"
              value={values.houseRules.weeklyChores}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Random drug testing"}
              id="randomDrugTesting"
              name="houseRules.randomDrugTesting"
              value={values.houseRules.randomDrugTesting}
            />,
            <CustomCheckBox
              className="form-group dflex-form position-relative"
              label={"Zero Tolerance Policy if drug test comes back positive"}
              id="zeroTolerancePolicy"
              name="houseRules.zeroTolerancePolicy"
              value={values.houseRules.zeroTolerancePolicy}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Curfew"}
              id="curfew"
              name="houseRules.curfew"
              value={values.houseRules.curfew}
              placeholder="Time"
              handleChange={true}
              onChangeHandler={({ target: { checked } }) => {
                if (checked) {
                  // this.setState({ curfew: true });
                  setFieldValue("houseRules.curfew", true);
                } else {
                  // this.setState({ curfew: false });
                  this.setState({ different_hours_per_day: false });
                  setFieldValue("houseRules.curfew", false);
                  setFieldValue("houseRules.curfewTime.daily", "10 PM");
                }
              }}
            />,
            values.houseRules.curfew && (
              <CustomCheckBox
                className="form-group dflex-form"
                label={"Custom Daily Curfew"}
                id="custom-curfew"
                name="customCurfew"
                value={this.state.different_hours_per_day}
                handleChange={true}
                onChangeHandler={({ target: { checked } }) => {
                  if (checked) {
                    this.setState({ different_hours_per_day: true });
                    // setFieldValue('houseRules.curfewTime.daily', 'Different hours per day', true)
                  } else {
                    this.setState({ different_hours_per_day: false });
                    setFieldValue("houseRules.curfewTime.sun", "", true);
                    setFieldValue("houseRules.curfewTime.mon", "", true);
                    setFieldValue("houseRules.curfewTime.tue", "", true);
                    setFieldValue("houseRules.curfewTime.wed", "", true);
                    setFieldValue("houseRules.curfewTime.thu", "", true);
                    setFieldValue("houseRules.curfewTime.fri", "", true);
                    setFieldValue("houseRules.curfewTime.sat", "", true);
                    setFieldValue("houseRules.curfewTime.daily", "10 PM");
                  }
                }}
              />
            )
          )}

          {!this.state.different_hours_per_day && (
            <>
              {this.renderInOneRow(
                <CustomDropDown
                  disabled={
                    different_hours_per_day || !values.houseRules.curfew
                  }
                  handleChange={handleChange}
                  name="houseRules.curfewTime.daily"
                  value={values.houseRules.curfewTime.daily}
                  label="Curfew Time:"
                  options={convertEnumToArray(CurfewOptions).slice(1)}
                />,
                <></>
              )}
            </>
          )}

          {/* START - show below only if curfew timing is differet hours per each day */}
          {this.state.different_hours_per_day && values.houseRules.curfew && (
            <>
              {this.renderInOneRow(
                <CustomDropDown
                  disabled={!different_hours_per_day}
                  handleChange={handleChange}
                  name="houseRules.curfewTime.mon"
                  value={values.houseRules.curfewTime.mon}
                  label="Monday:"
                  options={convertEnumToArray(CurfewOptions).slice(1)}
                  layout="horizontal"
                />,
                <CustomDropDown
                  disabled={!different_hours_per_day}
                  handleChange={handleChange}
                  name="houseRules.curfewTime.tue"
                  value={values.houseRules.curfewTime.tue}
                  label="Tuesday:"
                  options={convertEnumToArray(CurfewOptions).slice(1)}
                  layout="horizontal"
                />
              )}

              {this.renderInOneRow(
                <CustomDropDown
                  disabled={!different_hours_per_day}
                  handleChange={handleChange}
                  name="houseRules.curfewTime.wed"
                  value={values.houseRules.curfewTime.wed}
                  label="Wednesday:"
                  options={convertEnumToArray(CurfewOptions).slice(1)}
                  layout="horizontal"
                />,
                <CustomDropDown
                  disabled={!different_hours_per_day}
                  handleChange={handleChange}
                  name="houseRules.curfewTime.thu"
                  value={values.houseRules.curfewTime.thu}
                  label="Thursday:"
                  options={convertEnumToArray(CurfewOptions).slice(1)}
                  layout="horizontal"
                />
              )}

              {this.renderInOneRow(
                <CustomDropDown
                  disabled={!different_hours_per_day}
                  handleChange={handleChange}
                  name="houseRules.curfewTime.fri"
                  value={values.houseRules.curfewTime.fri}
                  label="Friday:"
                  options={convertEnumToArray(CurfewOptions).slice(1)}
                  layout="horizontal"
                />,
                <CustomDropDown
                  disabled={!different_hours_per_day}
                  handleChange={handleChange}
                  name="houseRules.curfewTime.sat"
                  value={values.houseRules.curfewTime.sat}
                  label="Saturday:"
                  options={convertEnumToArray(CurfewOptions).slice(1)}
                  layout="horizontal"
                />
              )}

              {this.renderInOneRow(
                <CustomDropDown
                  disabled={!different_hours_per_day}
                  handleChange={handleChange}
                  name="houseRules.curfewTime.sun"
                  value={values.houseRules.curfewTime.sun}
                  label="Sunday:"
                  options={convertEnumToArray(CurfewOptions).slice(1)}
                  layout="horizontal"
                />,
                <></>
              )}
            </>
          )}
          {/* END - curfew timing */}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Mandatory House Meetings"}
              id="mandatoryHouseMeetings"
              name="houseRules.mandatoryHouseMeetings"
              value={values.houseRules.mandatoryHouseMeetings}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"12 Step Meetings"}
              id="twelveStepMeetings"
              name="houseRules.twelveStepMeetings"
              value={values.houseRules.twelveStepMeetings}
            />
          )}

          {this.renderInOneRow(
            <CustomDropDown
              name="houseRules.currentStep"
              value={values.houseRules.currentStep}
              handleChange={handleChange}
              label="12 step meeting options"
              disabled={!values.houseRules.twelveStepMeetings}
              options={convertEnumToArray(TwelveStepMeetingOptions)}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Weekly Check Ins"}
              id="weeklyCheckIns"
              name="houseRules.weeklyCheckIns"
              value={values.houseRules.weeklyCheckIns}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Personal goals"}
              id="personalGoals"
              name="houseRules.personalGoals"
              value={values.houseRules.personalGoals}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Must be out of the house"}
              subLabel="(during the week)"
              id="mustBeOutOfHouse"
              name="houseRules.mustBeOutOfHouse"
              value={values.houseRules.mustBeOutOfHouse}
            />,
            <FromToTimeline
              disabled={!values.houseRules.mustBeOutOfHouse}
              fromName="houseRules.mustBeOutOfHouseDateRange.from"
              toName="houseRules.mustBeOutOfHouseDateRange.to"
              fromValue={values.houseRules.mustBeOutOfHouseDateRange.from}
              toValue={values.houseRules.mustBeOutOfHouseDateRange.to}
              fromHandleChange={handleChange}
              toHandleChange={handleChange}
            />,
            false
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Guest visiting hours"}
              subLabel="(during the week)"
              id="haveGuestVisitingHours"
              name="houseRules.haveGuestVisitingHours"
              value={values.houseRules.haveGuestVisitingHours}
            />,
            <FromToTimeline
              disabled={!values.houseRules.haveGuestVisitingHours}
              fromName="houseRules.guestVisitingHours.from"
              toName="houseRules.guestVisitingHours.to"
              fromValue={values.houseRules.guestVisitingHours.from}
              toValue={values.houseRules.guestVisitingHours.to}
              fromHandleChange={handleChange}
              toHandleChange={handleChange}
            />,
            false
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Quiet hours from"}
              subLabel="(during the week)"
              id="haveQuietHours"
              name="houseRules.haveQuietHours"
              value={values.houseRules.haveQuietHours}
            />,
            <FromToTimeline
              disabled={!values.houseRules.haveQuietHours}
              fromName="houseRules.quietHours.from"
              toName="houseRules.quietHours.to"
              fromValue={values.houseRules.quietHours.from}
              toValue={values.houseRules.quietHours.to}
              fromHandleChange={handleChange}
              toHandleChange={handleChange}
            />,
            false
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Overnight passes need to be approved"}
              id="isOvernightPassesApprovedInAdvance"
              name="houseRules.isOvernightPassesApprovedInAdvance"
              value={values.houseRules.isOvernightPassesApprovedInAdvance}
              handleChange={true}
              onChangeHandler={(e) => {
                setFieldValue(
                  "houseRules.isOvernightPassesApprovedInAdvance",
                  e.target.checked
                );
                if (!e.target.checked) {
                  setFieldValue(
                    "houseRules.overnightPassesApprovedInAdvance",
                    0
                  );
                }
              }}
            />,
            <CustomDropDown
              disabled={!values.houseRules.isOvernightPassesApprovedInAdvance}
              placeholder="0"
              name="houseRules.overnightPassesApprovedInAdvance"
              value={values.houseRules.overnightPassesApprovedInAdvance || ""}
              handleChange={handleChange}
              label="day(s) in advance"
              options={Array.from({ length: 10 }, (v, k) => k + 1)}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Resident restriction for the first"}
              id="isClientRestrictionFor"
              name="houseRules.isClientRestrictionFor"
              value={values.houseRules.isClientRestrictionFor}
              handleChange={true}
              onChangeHandler={(e) => {
                setFieldValue(
                  "houseRules.isClientRestrictionFor",
                  e.target.checked
                );
                if (!e.target.checked) {
                  setFieldValue("houseRules.clientRestrictionFor", "NONE");
                }
              }}
            />,
            <CustomDropDown
              disabled={!values.houseRules.isClientRestrictionFor}
              handleChange={handleChange}
              name="houseRules.clientRestrictionFor"
              value={values.houseRules.clientRestrictionFor}
              label="Days"
              options={CLIENT_RESTRICTION_OPTIONS}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Zero tolerance for stealing"}
              id="zeroToleranceForStealing"
              name="houseRules.zeroToleranceForStealing"
              value={values.houseRules.zeroToleranceForStealing}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={
                "Zero tolerance for destroying property that does not belong to you"
              }
              id="zeroToleranceForDestroyingProperty"
              name="houseRules.zeroToleranceForDestroyingProperty"
              value={values.houseRules.zeroToleranceForDestroyingProperty}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Zero tolerance for sexual activity between residents"}
              id="zeroToleranceForSexualActivity"
              name="houseRules.zeroToleranceForSexualActivity"
              value={values.houseRules.zeroToleranceForSexualActivity}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={
                "Zero tolerance for physical or verbal confrontation with staff or residents"
              }
              id="zeroToleranceForConfrontation"
              name="houseRules.zeroToleranceForConfrontation"
              value={values.houseRules.zeroToleranceForConfrontation}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={
                "Any resident who is on prescribed medications must immediately notify house manager and all meds must be approved."
              }
              id="mustNotifyOfPrescribedMeds"
              name="houseRules.mustNotifyOfPrescribedMeds"
              value={values.houseRules.mustNotifyOfPrescribedMeds}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"All medications taken must be taken as prescribed"}
              id="allMedsMustBeTakenAsPrescribed"
              name="houseRules.allMedsMustBeTakenAsPrescribed"
              value={values.houseRules.allMedsMustBeTakenAsPrescribed}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={
                "Working an honest program, we ask our residents to ensure a safe and sober environment by reporting any rule violations directly to staff"
              }
              id="isReportingAllRulesViolations"
              name="houseRules.isReportingAllRulesViolations"
              value={values.houseRules.isReportingAllRulesViolations}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Meeting verification cards are due at house meetings"}
              id="meetingCardsAreDueAtHouseMeetings"
              name="houseRules.meetingCardsAreDueAtHouseMeetings"
              value={values.houseRules.meetingCardsAreDueAtHouseMeetings}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={
                "Residents must have a sponsor while actively working the 12 steps"
              }
              id="mustHaveASponsorFor12Steps"
              name="houseRules.mustHaveASponsorFor12Steps"
              value={values.houseRules.mustHaveASponsorFor12Steps}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Residents rooms must be clean at all times"}
              id="residentsRoomsMustBeClean"
              name="houseRules.residentsRoomsMustBeClean"
              value={values.houseRules.residentsRoomsMustBeClean}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"No loitering in front of the property"}
              id="noLiteringInFrontOfTheProperty"
              name="houseRules.noLiteringInFrontOfTheProperty"
              value={values.houseRules.noLiteringInFrontOfTheProperty}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={
                "Residents that have unusual job schedules must be approved by staff"
              }
              id="unusualJobSchedulesMustBeApproved"
              name="houseRules.unusualJobSchedulesMustBeApproved"
              value={values.houseRules.unusualJobSchedulesMustBeApproved}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Make payment on the first of each month"}
              id="payRentOnTheFirstOfEachMonth"
              name="houseRules.payRentOnTheFirstOfEachMonth"
              value={values.houseRules.payRentOnTheFirstOfEachMonth}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Keep all shared spaces clean at all times"}
              id="allSharedSpacesMustBeClean"
              name="houseRules.allSharedSpacesMustBeClean"
              value={values.houseRules.allSharedSpacesMustBeClean}
            />
          )}

          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"No illegal activity may occur on property"}
              id="noIllegalActivity"
              name="houseRules.noIllegalActivity"
              value={values.houseRules.noIllegalActivity}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Do not disturb other residents"}
              id="dndOtherResidents"
              name="houseRules.dndOtherResidents"
              value={values.houseRules.dndOtherResidents}
            />
          )}
          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Be accountable and check in with house manger"}
              id="beAccountable"
              name="houseRules.beAccountable"
              value={values.houseRules.beAccountable}
            />,
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Abide by local rules and regulations for Covid 19"}
              id="abidingLocalCovidRules"
              name="houseRules.abidingLocalCovidRules"
              value={values.houseRules.abidingLocalCovidRules}
            />
          )}
          {this.renderInOneRow(
            <CustomCheckBox
              className="form-group dflex-form"
              label={"Breathalyzed Nightly"}
              id="breathalyzedNightly"
              name="houseRules.breathalyzedNightly"
              value={values.houseRules.breathalyzedNightly}
            />,
            <></>
          )}
        </div>

        {/* Erros */}
        <Toast
          onClose={() => this.setState({ showError: false })}
          show={this.state.showError}
          // delay={3000}
          className="list-group-item-warning"
        >
          <Toast.Body>
            <ul>
              {this.state.showError &&
                this.state.errors?.map(function (item) {
                  return <li key={item}>{item.split(":")[1] || item}</li>;
                })}
            </ul>
          </Toast.Body>
        </Toast>
        {/* submit the whole form */}
        <div className="d-flex flex-column flex-md-row justify-content-center mt-4">
          {this.state.livingId ? (
            <button
              type="submit"
              onClick={(e) => {
                handleSubmitLocalNext(e);
              }}
              className="btn btn-primary radius-20 btn-min-width"
            >
              Save
            </button>
          ) : (
            <button
              type="submit"
              onClick={(e) => {
                handleSubmitLocalNext(e, false);
              }}
              className="btn btn-primary radius-20 btn-min-width"
            >
              Next
            </button>
          )}
        </div>
      </Form>
    );
  };

  stepThreeForm = () => {
    return (
      <>
        <div className="subscription-wrp pt-md-5">
          <div className="subscription-title">
            <h3>CHOOSE A SUBSCRIPTION PLAN</h3>
            <span className="addingform">
              Select a plan that will be the best fit for your marketing and
              operational needs.
            </span>
          </div>

          <div className="togglediv d-flex flex-wrap align-items-center justify-content-center">
            <div className="pricing-toggle">
              {this.props.subscribePlans.map((data, index) => {
                return (
                  <div className="toggle-button-container d-flex align-items-center">
                    <input
                      type="radio"
                      id={data?.id}
                      name="pricing"
                      value={data?.recurring.interval}
                      checked={this.state.planPrice === data?.id}
                    />
                    <label
                      className="radio-button"
                      for={
                        this.state.interval === "monthly"
                          ? "pricing-toggle-monthly"
                          : "pricing-toggle-annually"
                      }
                      onClick={() => {
                        if (this.state.isSubscribed === false) {
                          this.setState({
                            planPrice: data?.id,
                            interval: data?.recurring.interval,
                            amount: data?.unit_amount,
                          });
                        } else {
                          if (
                            this.state.isSubscribed === true &&
                            this.state.interval === "monthly"
                          ) {
                            this.setState({
                              showUpgradeModel: true,
                              planPrice: data?.id,
                              interval: data?.recurring.interval,
                              amount: data?.unit_amount,
                            });
                          }
                        }
                      }}
                    >
                      {data?.recurring.interval.charAt(0).toUpperCase() +
                        data?.recurring.interval.slice(1)}{" "}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="offer">Save up to 15%</div>
            <div id="out"></div>
          </div>

          <div className="plan-list justify-content-center align-items-start">
            <div
              className={
                this.state.isSubscribed === false
                  ? "plan-card activecard"
                  : "plan-card"
              }
              onClick={() => {
                if (this.state.isSubscribed === true) {
                  this.setState({
                    isShowCancel: true,
                    subscriptionId: this.state.subscriptionId,
                    livingIdForSubscribe: this.livingId,
                  });
                }
              }}
            >
              <span className="save-para"></span>
              <h3>Basic</h3>
              <h4>
                <b>Free</b>
              </h4>
              <button
                type="submit"
                className="btn btn-primary radius-20 mb-20 basicbtn"
                onClick={() => {
                  this.setState({
                    isShowBasic: true,
                  });
                }}
              >
                Select
              </button>
              {/* {this.state.isSubscribed === false ? <p className="circle-paragraph">Activated</p> : <p className="inactivate-paragraph">Inactivated</p>} */}
              <div className="plan-info">
                <ul>
                  <li>House Rules</li>
                  <li>Curfew</li>
                  <li>Amenities</li>
                  <li>Requirements</li>
                </ul>
              </div>
            </div>
            <div
              className={
                this.state.isSubscribed === true
                  ? "plan-card activecard"
                  : "plan-card"
              }
            >
              <span className="save-para">
                {" "}
                {this.state.interval != "monthly" ? "$23.50 savings/year" : ""}
              </span>
              <h3>Pro</h3>
              {this.props.subscribePlans ? (
                <h4>
                  <b>
                    $ {this.state.amount}/home/{this.state.interval}
                  </b>
                </h4>
              ) : (
                ""
              )}
              {this.state.isSubscribed === false ||
              this.state.isShowButton === true ? (
                <button
                  type="submit"
                  onClick={() => {
                    this.context.setsubscribePlanId(this.state.planPrice);
                    this.context.setLivingId(
                      this.state.livingId != undefined
                        ? this.state.livingId
                        : this.props.Soberliving.id
                    );
                    this.context.setselectedInterval(
                      this.props.Soberliving.name
                    );
                    this.context.setselectedAmount(this.state.amount);
                    this.props.history.push("/payment-options");
                  }}
                  className="btn btn-primary radius-20 mb-20"
                >
                  Buy Now
                </button>
              ) : (
                <p className="circle-paragraph d-flex align-items-center">
                  Activated
                </p>
              )}
              <div className="plan-info">
                <ul>
                  <li>House Rules</li>
                  <li>Curfew</li>
                  <li>Amenities</li>
                  <li>Requirements</li>
                  <li>Customer Reviews</li>
                  <li>Website Listed</li>
                  <li>Phone Number Listed</li>
                  <li>Chat with potential clients through the platform</li>
                  <li>Scheduling House Tours</li>
                  <li>Recommend more facilities</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center mt-4">
            {/* <button
                            type="submit"
                            onClick={() => {
                                this.setState({
                                    isShowBasic: true
                                })
                            }}
                            className="btn btn-primary radius-20 btn-min-width"
                        >
                            Submit
                        </button> */}
          </div>
        </div>

        {/* =====on submit model */}

        <Modal
          centered
          show={this.state.isShowBasic}
          onHide={() => {}}
          className="upgrade-plan basic-to-pro"
        >
          {/* <div className="upgrade-plan-content">
                        <h3 style={{ textTransform: 'inherit' }}>
                            Create your free basic account.</h3>
                        <p className="mb-0">Our subscription pro plan includes the usage of the chat feature, customer reviews, a website link on your home page and a phone number listing for each house that has been upgraded as Pro.</p>
                        <p className="mb-0">
                            Subscriptions are automatically renewed and billed on a monthly or annual basis until you cancel. Annual subscriptions are paid up front and are based on a one-year commitment. You may change or cancel your subscription at any time. All subscription payments are non-refundable. Taste Recovery terms apply.
                            For questions on plans, pricing, or billing, please visit our Contact Us page.</p>
                    </div> */}

          <div className="upgrade-plan-content">
            <h3 style={{ textTransform: "inherit" }}>
              Create your free basic account.
            </h3>
            <p className="mb-0">
              By proceeding, I agree to Taste Recovery’s Privacy Statement and
              Terms of Service.
            </p>
          </div>

          <div className="modal-footer">
            <button
              type="submit"
              className="modal-btn"
              onClick={() => {
                this.setState({
                  isShowBasic: false,
                });
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="modal-btn confirm-btn"
              onClick={() => {
                this.context.showLoader("Adding your basic account");
                this.props.history.push("/profile");
              }}
            >
              Confirm
            </button>
          </div>
        </Modal>

        {this.state.showSuccessPopup && (
          <Toast
            show={true}
            autohide
            className="subscription-toast upgrade-plan"
          >
            <Toast.Body>
              <p>
                <img
                  src={require("../../assets/images/tick_check_mark.svg")}
                  className="tick_mark-icon"
                  alt=""
                />{" "}
                {this.state.popUpMessage}
                <img
                  src={require("../../assets/images/cancle.svg")}
                  alt=""
                  onClick={() => {
                    this.setState({
                      showSuccessPopup: false,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                />
              </p>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="modal-btn"
                  onClick={() => {
                    this.setState({
                      showSuccessPopup: false,
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="modal-btn confirm-btn"
                  onClick={() => {
                    this.setState({
                      showSuccessPopup: false,
                    });
                  }}
                >
                  Ok
                </button>
              </div>
            </Toast.Body>
          </Toast>
        )}
      </>
    );
  };

  formatOptionLabelSelected = ({ label }) => (
    <div className="search-result">
      <ul className="search-result-list d-flex flex-column">
        <li className="d-flex align-items-center">
          <span>{label}</span>
        </li>
      </ul>
    </div>
  );

  formatOptionLabelDropdown = ({ label }) => (
    <div className="search-result">
      <ul className="search-result-list d-flex flex-column">
        <li className="d-flex align-items-center">
          <img src={facilityIcon} alt="Facility" className="img-fluid mr-2" />
          <span>{label}</span>
        </li>
      </ul>
    </div>
  );

  render() {
    const {
      currentStep,
      livingId,
      showErrorPopup,
      formErrors,
      imageUploadErrors,
      uploadedCount,
      notUploadedCount,
    } = this.state;

    return (
      <div>
        {/* BACK Button */}
        {showErrorPopup && formErrors !== null && (
          <Modal
            centered
            show={showErrorPopup && formErrors !== null}
            style={this.errorPopUpStyle}
            className="spinner-taste"
            onHide={() => {}}
          >
            <img
              src={require("../../assets/images/cross-cancel.png")}
              width="50"
              heigth="50"
              alt="Error!"
            />
            <span className="loading-msg">
              {formErrors[Object.keys(formErrors)[0]] ||
                "Please fill in the form"}
            </span>
          </Modal>
        )}
        {!this.state.showHouseAndTours && (
          <section className="back">
            {currentStep === 1 ? (
              <BackButtonComponent className="container" isBlack={true} />
            ) : currentStep === 3 ? (
              <div className={"container"}>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.setState({ currentStep: 2, showErrorPopup: false });
                  }}
                >
                  <img src={backImgBlack} alt="" />
                </a>
              </div>
            ) : (
              <div className={"container"}>
                <a
                  onClick={() => {
                    this.setState({ currentStep: 1, showErrorPopup: false });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={backImgBlack} alt="" />
                </a>
              </div>
            )}
          </section>
        )}

        <section className="listing pt-4">
          <div className="container">
            {/* HEADER */}
            {!this.state.showHouseAndTours && (
              <div className="listing-header test">
                <h3 className="main-title mb-0">
                  {livingId ? "Edit sober living" : "ADD YOUR SOBER LIVING"}
                  <span className="step-badge">
                    {currentStep === 1
                      ? "STEP 1"
                      : currentStep === 2
                      ? "STEP 2"
                      : "STEP 3"}
                  </span>
                </h3>
              </div>
            )}

            {/* step 1 - formik intgration */}
            {currentStep === 1 && (
              <Formik
                enableReinitialize
                validateOnMount={true}
                initialValues={this.state.stepOneForm || DEFAULT_VALUES_STEP1}
                onSubmit={(values, { setSubmitting }) => {
                  this.uploadPhotos(values);
                }}
                validationSchema={addLivingSchemaStep1}
                validator={() => {
                  console.log("in vlaidator");
                }}
              >
                {(props) => this.stepOneForm(props)}
              </Formik>
            )}

            {currentStep === 2 && (
              <Formik
                enableReinitialize
                validateOnMount={true}
                initialValues={this.state.stepTwoForm || DEFAULT_VALUES_STEP2}
                onSubmit={async (values, { setSubmitting }) => {
                  this.setState({ stepTwoForm: values });
                  const isInternetAvailable = await checkInternet();
                  if (isInternetAvailable) {
                    this.submitAddLiving();
                  } else {
                    this.setState({
                      showErrorPopup: true,
                      formErrors: {
                        "Please check your internet connection":
                          "Please check your internet connection",
                      },
                      ...this.state.formErrors,
                    });
                    setTimeout(() => {
                      this.setState({ showErrorPopup: false });
                    }, 2000);
                  }
                }}
                validationSchema={addLivingSchemaStep2}
              >
                {(props) => this.stepTwoForm(props)}
              </Formik>
            )}

            {currentStep === 3 && (
              <Formik
                enableReinitialize
                validateOnMount={true}
                initialValues={this.state.stepThreeForm || DEFAULT_VALUES_STEP3}
              >
                {(props) => this.stepThreeForm(props)}
              </Formik>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default connect(
  ({ living, home, auth }) => ({
    stateList: home.states,
    isAddingLiving: living.addingLiving,
    hostSelectedLiving: living.selectedLiving,
    isEditingLiving: living.editingLiving,
    properties: living.properties,
    isFetchingLiving: living.fetchingLiving,
    subscribePlans: home.subscribePlan,
    Soberliving: living.Soberliving,
    user: auth.user,
    recommendedDropDownList: home.recommendedDropDownList,
  }),
  (dispatch) => {
    return {
      ...bindActionCreators({ ...livingAction, ...homeAction }, dispatch),
    };
  }
)(AddLiving);
