import React, { Component } from 'react';
import { connect } from 'react-redux';
import BackButtonComponent from "../../components/other/back-button.component";
import SoberLivingCard from '../../components/cards/sober-living.card'
import EditProfile from "../../assets/images/edit-profile-dark.svg";

import { checkHasNavigationPermission, getGeoLocation } from '../../utils';
import { homeAction } from '../../actions';

class ProfileUser extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

        getGeoLocation().then((position) => {
            const { latitude, longitude } = position.coords;
            let data = {};
            if (latitude && longitude) {
                data = {
                    coordinates: {
                        lat: latitude,
                        lng: longitude
                    }
                };
            }
           
            this.props.fetchLivingByRecommended(data);
        });

     

    }


    render() {
        return (
            <div>
                <section className="back responsive-back">
                    <BackButtonComponent className="container" isBlack={true} />
                </section>
                <section className="pt-4 listing">
                    <div className="container">
                        <div className="listing-header profile-listing-header">
                            <h3 className="main-title mb-0">{this.props.user.name || ''}</h3>
                            <span className="edit-profile-btn" onClick={() => {
                                this.props.history.push('/settings/profile');
                            }}>
                                <img src={EditProfile} alt="edit" />
                            </span>
                        </div>
                        <div className="listing-header">
                            <h3 className="main-title mb-0 ">Recommended sober livings </h3>
                        </div>
                        <div className='recommended'>
                            <div className="row">
                                {this.props.recommendedLiving?.map((item, index) => (
                                    <div className="col-md-6 col-lg-4 mb-3 md-mb-0" key={index}>
                                        <SoberLivingCard item={item} ratingOutside={true} isRecommended={true} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

export default connect(
    ({ auth, home }) => ({
        isHost: auth.isHost,
        recommendedLiving: home.livingByRecommended,
        user: auth.user,
    }), homeAction
)(ProfileUser);