import React, { Component } from "react";
import * as auth from "../../actions/index";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

class RemovedAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }
  componentDidMount() {
    //this.props.logout();
  }

  handleClose = () => {
    this.setState({ showModal: true });
    this.props.history.goBack();
  };

  handeYes = () => {
    this.setState({ showModal: true });
    this.props.removeUser();
  };

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove account?</Modal.Title>
        </Modal.Header>
        <Modal.Body> Are you sure you want to delete account?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handeYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.authAction
)(RemovedAccount);
