import React, { Component } from "react";

// Missing assets - fridge, pool- dark ,
class AvailableOptionCard extends Component {
    // props will be selectHandler, allSelectedItems
    constructor(props) {
        super(props);
        this.state = {
            assets: {
                internet: {
                    light: 'internet-light-gray',
                    dark: 'internet-dark-gray'
                },
                fridge: {
                    light: 'fridge-light-gray',
                    dark: 'fridge-dark-gray'
                },
                tv: {
                    light: 'tv-light-gray',
                    dark: 'tv-dark-gray'
                },
                "a/c": {
                    light: 'ac-light-gray',
                    dark: 'ac-dark-gray'
                },
                washer: {
                    light: 'washer-light-gray',
                    dark: 'washer-dark-gray'
                },
                dryer: {
                    light: 'dryer-light-gray',
                    dark: 'dryer-dark-gray'
                },
                towels: {
                    light: 'towels-light-gray',
                    dark: 'towels-dark-gray'
                },
                bedding: {
                    light: 'bedding-light-gray',
                    dark: 'bedding-dark-gray'
                },
                parking: {
                    light: 'parking-light-gray',
                    dark: 'parking-dark-gray'
                },
                heating: {
                    light: 'heating-light-gray',
                    dark: 'heating-dark-gray'
                },
                pool: {
                    light: 'pool-light-gray',
                    dark: 'pool-dark-gray'
                },
                jacuzzi: {
                    light: 'jacuzzi-light-gray',
                    dark: 'jacuzzi-dark-gray'
                },
                chef: {
                    light: 'chef-light-gray',
                    dark: 'chef-dark-gray'
                },
                noSmoking: {
                    light: 'no-smoking-light-gray',
                    dark: 'no-smoking-dark-gray'
                }
            },
        }
    }

    getImage = (optionName = 'internet') => {
        const { assets } = this.state;
        const { selectedOptions } = this.props;
        const { light, dark } = assets[optionName]

        // check if selected
        const fileName = selectedOptions.includes(optionName) ? dark : light;

        return require(`../../assets/images/${fileName}.svg`)
    }

    render() {
        const { currentOption, selectHandler = () => { } } = this.props;
        return (
            <li onClick={() => selectHandler(currentOption)}>
                <img src={this.getImage(currentOption)} alt="NO IMG" />
            </li>
        )
    }
}

export default AvailableOptionCard;