import { httpClient } from "../utils";
import { SERVER_APIS } from "../constants";

export const DashboardActionType = {
  fetch_Dashboard_ActionType: "[Get_Dashboard] Action",
  fetch_Dashboard_Success: "[Get_Dashboard_Sucess] Action",
  fetch_Dashboard_Failure: "[Get_Dashboard_Failure] Action",
  fetch_DashboarDetail_Success: "[Get_DashboardDetail_Sucess] Action",
  fetch_DashboardDetail_Failure: "[Get_DashboardDetail_Failure] Action",
  set_Data_Success: "[set_Data_Success] Action",
  set_Data_Failure: "[set_Data_Failure] Action",
  
  Create_click_Success: "[Create_click_Success] Action",
  Create_click_Failure: "[Create_click_Failure] Action",
};

const fetchDashboard = (data) => {
  return (dispatch) => {
    dispatch({ type: DashboardActionType.fetch_Dashboard_ActionType });
    return httpClient
      .post(`${SERVER_APIS.dashboard_Get}`,data)
      .then((res) => {
        dispatch({
          type: DashboardActionType.fetch_Dashboard_Success,
          payload: { dashboard: res.data },
        });
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch({
          type: DashboardActionType.fetch_Dashboard_Failure,
          payload: { error },
        });
        return Promise.reject(error);
      });
  };
};



const fetchDashboardDetail = () => {
  return (dispatch) => {
    dispatch({ type: DashboardActionType.fetch_Dashboard_ActionType });
    return httpClient
      .get(`${SERVER_APIS.Dashbaord_Detail}`)
      .then((res) => {
        dispatch({
          type: DashboardActionType.fetch_DashboarDetail_Success,
          payload: { dashboard_detail: res.data },
        });
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch({
          type: DashboardActionType.fetch_DashboardDetail_Failure,
          payload: { error },
        });
        return Promise.reject(error);
      });
  };
};

const CreateClick = (data) => {
  return (dispatch) => {
    dispatch({ type: DashboardActionType.fetch_Dashboard_ActionType });
    return httpClient
      .post(`${SERVER_APIS.dashboard_CreateClick}`,data)
      .then((res) => {
        dispatch({
          type: DashboardActionType.Create_click_Success,
          payload: { dashboard: res.data },
        });
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch({
          type: DashboardActionType.Create_click_Failure,
          payload: { error },
        });
        return Promise.reject(error);
      });
  };
};



const setDataforRedirection = (data) => {

  return (dispatch) => {

    dispatch({

      type: DashboardActionType.set_Data_Success,

      payload: data,

    });

  };

};


export const DashboardAction = {
  getDashboardDetail: fetchDashboard,
  CreteClikforDashbaord: CreateClick,
  fetchDashboardDetail: fetchDashboardDetail,
  setDataforRedirection : setDataforRedirection
};
