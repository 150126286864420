import React, { Component } from "react";

import { Modal } from "react-bootstrap";
class PrivacyPolicy extends Component {
  render() {
    return (
      <section
        className={`container savehome-wrp setting-list ${
          this.props.location?.pathname !== "/privacy-policy" ? "p-0" : ""
        }`}
        id="eight"
      >
        {this.props.location?.pathname === undefined ? (
          <Modal.Header closeButton>
            <Modal.Title>Privacy Policy </Modal.Title>
          </Modal.Header>
        ) : (
          <h3 className="main-title title-decoration">Privacy Policy </h3>
        )}

        <div className="saved-home-wrappper mt-4">
          <div className="cms-wrp text-justify">
            <p>
              Taste Recovery, LLC is concerned with the{" "}
              <span className="il">privacy</span> of the users of this
              app.&nbsp; It is important to understand however that there are
              certain things we cannot control.&nbsp; These include anything
              posted in a public form on the app, as well as emails sent to
              Taste Recovery, or other third parties associated with the
              app.&nbsp; As to what we can control, our{" "}
              <span className="il">policy</span> on{" "}
              <span className="il">privacy</span> is set forth below.
            </p>
            <span className="heading">Use of personal information</span>
            <p>
              ‍We do not share your personal information with anyone.&nbsp; We
              generally do not obtain this information of this nature, which
              makes it easy not to share.&nbsp; If this changes in the future,
              we will update our <span className="il">policy</span> and make you
              aware of the change.
            </p>
            <span className="heading">Cookies</span>
            <p>
              As most internet users today know, your browser stores information
              about websites you visit.&nbsp; This is useful technology for
              purposes of providing users with a more customized
              experience.&nbsp; There are also third party applications, such as
              Google Analytics that utilize these cookies to generate targeted
              advertisements.&nbsp; Taste Recovery may use cookies to assist in
              better understanding the usage of our visitors, so as to provide a
              better overall experience.&nbsp; If you wish to restrict access to
              cookies, or similar technology on your computer or device while
              visiting Taste Recovery, or any other site, please contact your
              computer administrator, or review your browser settings.&nbsp; You
              may also consider using a an “opt-out browser add-on” for Google
              Analytics, which can be found at{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noopener"
                data-saferedirecturl="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&source=gmail&ust=1602371383128000&usg=AFQjCNE1JPCgg9ukT2gnYWcaASaXceXYIQ"
              >
                https://tools.google.com/
                <wbr />
                dlpage/gaoptout
              </a>
              .
            </p>
            <span className="heading">Security</span>
            <p>
              Taste Recovery implements reasonable measures to ensure the
              security of our visitors and if need be, is prepared to take
              necessary measures to prevent any data intrusions.
            </p>
            <span className="heading">
              <span className="il">Policy</span> Changes
            </span>
            <p>
              All changes to our <span className="il">privacy</span>{" "}
              <span className="il">policy</span> will be reflected on this
              page.&nbsp; If you wish to contact us regarding our{" "}
              <span className="il">policy</span>, please do so using the
              following:
            </p>
            <p>
              <strong>Taste Recovery, LLC</strong>
              <br />
              <strong>208 E Jeanette Lane,</strong>
              <br />
              <strong>Santa Ana, CA 92705</strong>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default PrivacyPolicy;
