import { SERVER_APIS } from "../constants";
import { httpClient } from "../utils";

export const preSaleActionTypes = {
  PreSalesPlan_Fetching: "[ Fetching_PreSalePlan] Action",
  PreSalesPlan_Fetched: "[ Fetched_PreSalePlan] Action",
  PreSaleBasicPlan_Set:"[Set_PreSaleBasicPlan] Action",
  PreSaleProPlan_Set:"[Set_PreSaleProPlan] Action",
  PreSaleFacilityType_Set:"[Set_PreSaleFacilityType] Action",
};



const onFetchPreSalePlan = () => {
  return (dispatch) => {
    dispatch({ type: preSaleActionTypes.PreSalesPlan_Fetching });
    return httpClient
      .get(`${SERVER_APIS.PreSale_Plan}`)
      .then((response) => {
        dispatch({
          type: preSaleActionTypes.PreSalesPlan_Fetched,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: preSaleActionTypes.PreSalesPlan_Fetched,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

const setPreSaleBasicPlan=(data)=>{
  return(dispatch)=>{
    dispatch({
        type:preSaleActionTypes.PreSaleBasicPlan_Set,

        payload:{id: data?.id, price:data?.unit_amount}
    })
}
}

const setPreSaleProPlan=(data)=>{
  
  return(dispatch)=>{
    dispatch({
        type:preSaleActionTypes.PreSaleProPlan_Set,

        payload:{id: data?.id, price:data?.unit_amount}
    })
}
}

const setPreSaleFacilityType=(data)=>{
  return(dispatch)=>{
    dispatch({
        type:preSaleActionTypes.PreSaleFacilityType_Set,
        payload:{
          facilitytype:data?.facilityType,
          id:data?.id,
          name:data?.name
        }
    })
}
}

export const preSaleActions = {
  getPreSalePlan : onFetchPreSalePlan,
  setPreSaleBasicPlan,
  setPreSaleProPlan,
  setPreSaleFacilityType
};
