import { persistReducer } from "redux-persist";
import { STORAGE } from "../constants";
import { tourActionType } from "../actions/tour.action";
import storage from "redux-persist/lib/storage";

const initialTourState = {
  tours: undefined,
  bookingATours: false,
  fetchingCalendar: false,
  fetchingScheduledTours: false,
  calendar: [],
  page: 1,
};

export const tourReducer = persistReducer(
  {
    storage,
    key: STORAGE.TOUR_KEY,
    keyPrefix: STORAGE.PREFIX,
    whitelist: ["tours"],
  },
  (state = initialTourState, action) => {
    switch (action.type) {
      case tourActionType.Tours_Booking:
        return { ...state, bookingATours: true };
      case tourActionType.Tours_Booked:
        // const {tours} = action.payload;
        return { ...state, bookingATours: false };

      case tourActionType.Tours_Calendar_Fetching:
        return { ...state, fetchingCalendar: true };
      case tourActionType.Tours_Calendar_Fetched:
        const { calendar } = action.payload;
        return { ...state, fetchingCalendar: false, calendar };

      case tourActionType.Tours_Fetching:
        return { ...state, fetchingScheduledTours: true };
      case tourActionType.Tours_Fetched:
        const { tours, page } = action.payload;
        return { ...state, fetchingScheduledTours: false, tours };

      default:
        return state;
    }
  }
);
