import Compressor from 'compressorjs';
export const compressImage = (file) => {
    return new Promise((resolve) => {
      new Compressor(file, {
        quality: 0.4,
        success: (result) => {
          resolve(result);
        },
        error: (err) => {
          //console.error("Compression error:", err);
          resolve(file);
        },
      });
    });
  };