import React from 'react';
import { Line } from "react-chartjs-2";

const chartOptions = {
    title: {
        // position: 'top',
        // display: true,
        // text: `${livingName}`
    },
    legend: {
        display: false,
        position: 'bottom',
        align: 'start',
        fullWidth: true,
        labels: {
            boxWidth: 20
        }
    },
    scales: {
        xAxes: [{
            ticks: {
                autoSkip: true,
                maxTicksLimit: 15
            },
            gridLines: {
                display: false
            }
        }],
        yAxes: [{
            ticks: {
                suggestedMin: 0,
                suggestedMax: 10,
                maxTicksLimit: 10,
                stepSize: 1
            }
        }],
        beginAtZero: true
    }
}

class ActivityLineGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Line
                ref="chart"
                data={this.props.data}
                options={chartOptions}
            />
        )
    }
}
export default ActivityLineGraph;